<template>
  <div
    class="tab-pane show active"
  >
    <div class="form-group form-group--cards">
      <div
        class="check-text-item filedownload-box"
        ref="formItem"
        v-for="(item, index) in filedownloadItems"
        :key="index"
      >
        <div class="row">
          <div class="col-12">
            <div
              class="form-group"
            >
              <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
              <label
                :for="'title' + index"
                class="form-label"
              >{{ $t('title').toUpperCase() }}</label>
              <div class="d-flex justify-content-between align-items-center">
                <input
                  :class="{ 'is-danger': errors.has(`filedownload.title${index}`) }"
                  type="text"
                  :disabled="disabled"
                  class="form-control"
                  v-model="item.title"
                  :name="'title' + index"
                  :placeholder="$t('type_your_title')"
                  data-vv-scope="filedownload"
                  :data-vv-name="`title${index}`"
                  :data-vv-as="$t('title')"
                  v-validate.disable="'required|max:300|min:3'"
                  @keypress="addFiledownloadItem(index + 1)"
                  @paste="addFiledownloadItem(index + 1)"
                >
              </div>
              <span
                v-if="errors.has(`filedownload.title${index}`)"
                class="help is-danger"
              >{{ errors.first(`filedownload.title${index}`) }}</span>
            </div>
          </div>
          <div class="col-12">
            <div
              class="form-group"
            >
              <label
                :for="'description' + index"
                class="form-label"
              >{{ $t('description') }}</label>
              <textarea
                :class="{ 'is-danger': errors.has(`filedownload.desc${index}`) }"
                rows="1"
                v-resizable
                :disabled="disabled"
                class="form-control"
                :placeholder="$t('type_your_desciption')"
                v-model="item.desc"
                data-vv-scope="filedownload"
                :data-vv-name="`desc${index}`"
                :data-vv-as="$t('description')"
                v-validate.disable="{
                  max: 400,
                }"
              />
              <span
                v-if="errors.has(`filedownload.desc${index}`)"
                class="help is-danger"
              >{{ errors.first(`filedownload.desc${index}`) }}</span>
            </div>
          </div>
          <div class="col-12">
            <div
              class="form-group"
            >
              <fileupload-container
                :class="{ 'is-danger': errors.has(`filedownload.file${index}`) }"
                :file-url="item.url"
                :disabled="disabled"
                :file-index="index"
                :is-multiple="false"
                vee-rule="required|size:60000"
                vee-scoop-name="filedownload"
                :vee-name="`file${index}`"
                :vee-as="$t('file')"
                @update="fileAdd"
                class="card cardfile--upload"
              />
              <span
                v-if="errors.has(`filedownload.file${index}`)"
                class="help is-danger"
              >{{ errors.first(`filedownload.file${index}`) }}</span>
            </div>
          </div>
          <div class="col-12">
            <div class="d-flex justify-content-end align-items-center">
              <button
                :disabled="disabled"
                @click="removeFiledownloadItem(index)"
                v-if="filedownloadItems.length > 1"
              >
                <icon-container
                  name="trash"
                  color="#727d92"
                  :width="18"
                  :height="18"
                  view-box="0 0 25 25"
                >
                  <icon-trash />
                </icon-container>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="form-group"
      :class="{ 'is-danger': errors.has('filedownload.title') }"
    >
      <label
        for="contentTitle"
        class="form-label"
      >{{ $t('title').toUpperCase() }}</label>
      <input
        type="text"
        class="form-control"
        id="contentTitle"
        v-model="title"
        :disabled="disabled && !canEditOnlyItem"
        data-vv-scope="filedownload"
        data-vv-name="title"
        :data-vv-as="$t('title')"
        v-validate="'required|max:100'"
        @input="handleTitle"
      >
      <span
        v-show="errors.has('filedownload.title')"
        class="help is-danger"
      >{{ errors.first('filedownload.title') }}</span>
    </div>
    <div class="modal-footer">
      <button
        v-if="filedownloadItems.length < 1"
        :disabled="disabled"
        type="button"
        class="button button--alternative button--icon u-full"
        @click.prevent="addFiledownloadItem()"
      >
        <icon-container
          name="circleplusfull"
          view-box="0 0 512 512"
          color="#fff"
          class="icon-svg"
          :is-icon-class="false"
        >
          <icon-circleplusfull />
        </icon-container>
        {{ $t('add_item') }}
      </button>
    </div>
    <div
      v-show="errors.items.length > 0"
      class="form-group"
    >
      <div class="alert alert--card alert--error">
        <p>{{ $t('error_msg_title') }}</p>
        <ul>
          <li
            v-for="(error, index) in errors"
            :key="index"
          >
            {{ error.msg }}
          </li>
        </ul>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="button button--primary"
        @click="editMode ? updateContent() : createContent()"
        :disabled="isPending || (disabled && !canEditOnlyItem)"
      >
        <span
          v-if="!isPending"
        >
          {{ editMode ? $t('update_button') : $t('create_button') }}
        </span>
        <div
          class="lds-ellipsis"
          v-if="isPending"
        >
          <div />
          <div />
          <div />
          <div />
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import FileUploadContainer from '@/components/FileUpload.vue';
import IconTrash from '@/components/icons/Trash.vue';
import IconContainer from '@/components/elements/Icon.vue';
import IconCirclePlusFull from '@/components/icons/CirclePlusFull.vue';

export default {
  name: 'FileDownloadContent',

  inject: ['$validator'],

  components: {
    'icon-container': IconContainer,
    'icon-trash': IconTrash,
    'icon-circleplusfull': IconCirclePlusFull,
    'fileupload-container': FileUploadContainer,
  },

  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    canEditOnlyItem: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      title: '',
      isPending: false,
      filedownloadItems: [],
    };
  },

  directives: {
    resizable: {
      inserted(el) {
        el.addEventListener('input', (e) => {
          e.target.style.height = 'auto';
          e.target.style.height = `${e.target.scrollHeight}px`;
        });
      },
    },
  },
  computed: {
    modalData: {
      get() {
        return this.$store.state.modal.modalData;
      },
      set(newValue) {
        this.$store.state.modal.modalData = newValue;
      },
    },
    formError: {
      get() {
        return this.$store.state.modal.formError;
      },
      set(newValue) {
        this.$store.state.modal.formError = newValue;
      },
    },
  },

  watch: {
    modalData: {
      handler(newVal) {
        const $newVal = newVal;
        if ($newVal) {
          if (this.editMode && $newVal.file_download_content) {
            this.filedownloadItems = $newVal.file_download_content.files;
            this.filedownloadItems.push({
              title: '',
              desc: '',
              url: '',
              file: null,
            });
          }
        }
      },
      immediate: true,
    },

    formError: {
      handler(newVal) {
        if (newVal) {
          this.isPending = false;
        }
      },
      immediate: true,
    },

    name: {
      handler(newVal) {
        this.title = newVal;
      },
      immediate: true,
    },

    filedownloadItems: {
      handler(newVal) {
        this.$emit('setFiledownloadItems', newVal);
      },
      immediate: true,
    },
  },

  mounted() {
    if (!this.editMode) {
      this.addFiledownloadItem();
    }
  },

  methods: {

    fileAdd(data) {
      // eslint-disable-next-line no-unused-vars
      let preview = null;
      let file = null;
      let index = null;
      [preview, file, index] = data;
      if (index != null || index !== undefined) {
        if (file) {
          this.filedownloadItems[index].file = file;
          this.filedownloadItems[index].url = file.name;
        } else {
          this.filedownloadItems[index].file = null;
          this.filedownloadItems[index].url = null;
          // if (this.filedownloadItems[index].id) {
          //   this.$store.dispatch('modal/DELETE_FILEDOWNLOAD_ITEM', this.filedownloadItems[index].id);
          // }
        }
      }
    },

    addFiledownloadItem(index = 0) {
      if (index === this.filedownloadItems.length) {
        this.filedownloadItems.push({
          title: '',
          desc: '',
          url: '',
          file: null,
        });
      }
    },

    removeFiledownloadItem(index) {
      const itemToremoveId = this.filedownloadItems[index].id;
      if (itemToremoveId) {
        this.$store.dispatch('modal/DELETE_FILEDOWNLOAD_ITEM', itemToremoveId).then(() => {
          if (!this.formError) {
            this.filedownloadItems.splice(index, 1);
          } else {
            this.formError = false;
          }
        });
      } else {
        this.filedownloadItems.splice(index, 1);
      }
    },

    handleTitle() {
      this.$emit('setTitle', this.title);
    },

    clearErrorMsg() {
      this.$validator.errors.clear();
    },

    async checkValidation() {
      let allIsValid = false;
      let filedownloadIsValid = false;
      let customCheck = true;
      this.clearErrorMsg();

      this.filedownloadItems.forEach((item, index) => { // delete empty cheklist before validation
        if (!item.title) {
          this.filedownloadItems.splice(index, 1);
        }
      });
      if (this.filedownloadItems.length < 1) { // check at least one question exist
        this.$validator.errors.add({
          field: 'file0',
          msg: this.$t('minimum_filedownload_item'),
          scope: 'filedownload',
        });
        customCheck = false;
      }

      await this.$validator.validateAll('all').then((res) => {
        allIsValid = res;
      });
      await this.$validator.validateAll('filedownload').then((res) => {
        filedownloadIsValid = res;
      });
      if (allIsValid && filedownloadIsValid && customCheck) {
        return true;
      }
      return false;
    },

    createContent() {
      this.checkValidation().then((res) => {
        if (res) {
          this.$emit('createContent');
          this.isPending = true;
        }
      });
    },

    updateContent() {
      this.checkValidation().then((res) => {
        if (res) {
          this.$emit('updateContent');
          this.isPending = true;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.is-danger {
  border-color: #f27299;
}
.filedownload-box {
  width: 100%;
  padding: 25px 50px;
  border-radius: 14px;
  box-shadow: 0 1px 6px 0 rgba(114, 125, 146, 0.2);
  border: solid 2px rgba(114, 125, 146, 0.2);
  background-color: rgba(255, 255, 255, 0.09);
  margin-bottom: 30px;
  position: relative;
}

::v-deep textarea.form-control {
  min-height: 44px;
  height: 100%;
  overflow: hidden;
  resize:vertical;
}
</style>
