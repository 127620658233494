<!-- eslint-disable vue-i18n/no-raw-text -->
<template>
  <div
    class="modal-container"
  >
    <div
      class="modal"
      :class="{'is-loading': formSending }"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div
          class="modal-content"
        >
          <header class="modal-header">
            <h5
              v-if="editMode"
              class="modal-title"
            >
              {{ $t('edit_content') }}
            </h5>
            <h5
              v-else
              class="modal-title"
            >
              {{ $t('add_new_content') }}
            </h5>
            <button
              type="button"
              class="close"
              @click="closeModal"
              v-html="'&times;'"
            />
          </header>
          <nav
            class="navigation navigation--secondary"
          >
            <div class="container">
              <ul
                class="navigation__list nav"
                id="contentCreateTab"
                role="tablist"
              >
                <li class="navigation__item">
                  <button
                    class="navigation__link"
                    :class="{ 'active': tabPane === 'mainTab'}"
                    @click="tabNavigation('mainTab')"
                  >
                    {{ $t('main_tab') }}
                  </button>
                </li>
                <li
                  class="navigation__item"
                  style="display:none;"
                >
                  <button
                    class="navigation__link"
                    :class="{ 'active': tabPane === 'addFlowTab'}"
                    @click="tabNavigation('addFlowTab')"
                  >
                    {{ $t('add_flow') }}
                  </button>
                </li>
                <li
                  class="navigation__item"
                  v-show="contentCreateValue === 'quiz'"
                >
                  <button
                    class="navigation__link"
                    :class="{ 'active': tabPane === 'quizSettingsTab'}"
                    @click="tabNavigation('quizSettingsTab')"
                  >
                    {{ $t('quiz_setting_tab') }}
                  </button>
                </li>
                <li
                  class="navigation__item"
                >
                  <button
                    class="navigation__link"
                    :class="{ 'active': tabPane === 'advancedSettingsTab'}"
                    @click="tabNavigation('advancedSettingsTab')"
                  >
                    {{ $t('advanced_tab') }}
                  </button>
                </li>
                <li
                  class="navigation__item"
                  v-if="isVibonsAdmin"
                >
                  <button
                    class="navigation__link"
                    :class="{ 'active': tabPane === 'adminSettingsTab'}"
                    @click="tabNavigation('adminSettingsTab')"
                  >
                    {{ $t('admin_settings') }}
                  </button>
                </li>
                <li
                  class="navigation__item"
                  v-if="editMode"
                >
                  <button
                    class="navigation__link"
                    :class="{ 'active': tabPane === 'journeyusedTab'}"
                    @click="tabNavigation('journeyusedTab')"
                  >
                    {{ $t('journey_used') }}
                  </button>
                </li>
              </ul>
            </div>
          </nav>

          <div class="modal-body">
            <div
              v-if="pageIsLoading"
              class="page-loader"
            >
              <div class="page-is-loading" />
            </div>
            <div class="tab-content">
              <div
                class="tab-pane"
                v-show="tabPane === 'mainTab'"
              >
                <div
                  class="form-group"
                  v-if="$store.state.modal.launchedFrom !== 'Contents'"
                >
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <div class="form-group u-zIndex-3">
                        <label
                          for="beginDate"
                          class="form-label"
                        >{{ $t('begin_day') }}</label>
                        <input
                          class="form-control"
                          :class="{ 'is-danger': errors.has('assign.day') }"
                          type="number"
                          id="dayinput"
                          v-model="day"
                          min="1"
                          data-vv-scope="assign"
                          data-vv-name="day"
                          :data-vv-as="$t('begin_day')"
                          v-validate="'required'"
                        >
                        <span class="daytext">{{ $t('day') }}</span>
                        <span
                          v-if="errors.has('assign.day')"
                          class="help is-danger"
                        >{{ errors.first('assign.day') }}</span>
                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <div class="form-group u-zIndex-3">
                        <label
                          for="beginTime"
                          class="form-label"
                        >{{ $t('begin_time') }}</label>
                        <date-picker
                          :class="{ 'is-danger': errors.has('assign.beginTime') }"
                          v-model="beginActivationDateTime"
                          :format="userLang === 'en' ? 'hh:mm A' : 'HH:mm'"
                          type="time"
                          value-type="date"
                          data-vv-scope="assign"
                          data-vv-name="beginTime"
                          :data-vv-as="$t('begin_time')"
                          v-validate="'required'"
                          :lang="userLang"
                          :time-picker-options="{ start: '08:00', step: '00:15', end: '23:45', format: userLang === 'en' ? 'hh:mm A' : 'HH:mm' }"
                          @change="adjustTime('time')"
                        >
                          <template slot="icon-calendar">
                            <icon-container
                              name="time"
                              view-box="0 0 512 512"
                            >
                              <icon-time />
                            </icon-container>
                          </template>
                        </date-picker>
                        <span
                          v-if="errors.has('assign.beginTime')"
                          class="help is-danger"
                        >{{ errors.first('assign.beginTime') }}</span>
                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <label
                        for="roleType"
                        class="form-label"
                      >{{ $t('roleType') }}</label>
                      <multi-select
                        :class="{ 'is-danger': errors.has('all.roleType') }"
                        track-by="value"
                        name="roleType"
                        label="label"
                        :placeholder="$t('select_placeholder')"
                        :tag-placeholder="$t('tag_placeholder')"
                        :deselect-label="$t('deselect_placeholder')"
                        :select-label="$t('enter_select_placeholder')"
                        :selected-label="$t('selected_label')"
                        clear-on-select
                        v-model="roleType"
                        :disabled="editMode && journeyHaveUsers && $store.state.modal.launchedFrom === 'journeyitemsv2'"
                        data-vv-scope="all"
                        data-vv-name="roleType"
                        :options="roleTypeOptions"
                        class="selectbox selectbox--secondary"
                        v-validate="'required'"
                        :data-vv-as="$t('roleType')"
                      />
                      <span
                        v-if="errors.has('all.roleType')"
                        class="help is-danger"
                      >{{ errors.first('all.roleType') }}</span>
                    </div>
                  </div>
                </div>
                <div
                  v-if="!editMode"
                  class="form-group form-group--cards"
                >
                  <vue-agile :options="slideOptions">
                    <div
                      class="row slide"
                    >
                      <div class="col-6 col-sm-4">
                        <button
                          type="button"
                          class="card card--media"
                          :class="{ 'has-active' : contentCreateValue === 'video' }"
                          :disabled="contentCreateValue === 'video'"
                          @click="contentCreateSelector('video')"
                        >
                          <span class="media-object media-object--row">
                            <span class="media-object__media">
                              <icon-container
                                name="video"
                                :height="21"
                                :width="26"
                                view-box="0 0 576 512"
                                :is-icon-class="true"
                              >
                                <icon-video />
                              </icon-container>
                            </span>
                            <span class="card__title media-object__text h4">{{ $t('video') }}</span>
                          </span>
                        </button>
                      </div>
                      <div class="col-6 col-sm-4">
                        <button
                          type="button"
                          class="card card--media"
                          :class="{ 'has-active' : contentCreateValue === 'infographics' }"
                          :disabled="contentCreateValue === 'infographics'"
                          @click="contentCreateSelector('infographics')"
                        >
                          <span class="media-object media-object--row">
                            <span class="media-object__media">
                              <icon-container
                                name="cogs"
                                :height="21"
                                :width="26"
                                view-box="0 0 640 512"
                                :is-icon-class="true"
                              >
                                <icon-cogs />
                              </icon-container>
                            </span>
                            <span class="card__title media-object__text h4">{{ $t('infographics') }}</span>
                          </span>
                        </button>
                      </div>
                      <div class="col-6 col-sm-4">
                        <button
                          type="button"
                          class="card card--media"
                          :class="{ 'has-active' : contentCreateValue === 'external-link' || contentCreateValue === 'link'}"
                          :disabled="contentCreateValue === 'external-link' || contentCreateValue === 'link'"
                          @click="contentCreateSelector('external-link')"
                        >
                          <span class="media-object media-object--row">
                            <span class="media-object__media">
                              <icon-container
                                name="share"
                                :height="21"
                                :width="19"
                                view-box="0 0 448 512"
                                :is-icon-class="true"
                              >
                                <icon-share />
                              </icon-container>
                            </span>
                            <span class="card__title media-object__text h4">{{ $t('external_link') }}</span>
                          </span>
                        </button>
                      </div>
                      <div class="col-6 col-sm-4">
                        <button
                          type="button"
                          class="card card--media"
                          :class="{ 'has-active' : contentCreateValue === 'flipbook' }"
                          :disabled="contentCreateValue === 'flipbook'"
                          @click="contentCreateSelector('flipbook')"
                        >
                          <span class="media-object media-object--row">
                            <span class="media-object__media">
                              <icon-container
                                name="copy"
                                :height="21"
                                :width="19"
                                view-box="0 0 448 512"
                                :is-icon-class="true"
                              >
                                <icon-copy />
                              </icon-container>
                            </span>
                            <span class="card__title media-object__text h4">{{ $t('flipbook_') }}</span>
                          </span>
                        </button>
                      </div>
                      <div class="col-6 col-sm-4">
                        <button
                          type="button"
                          class="card card--media"
                          :class="{ 'has-active' : contentCreateValue === 'quiz' }"
                          :disabled="contentCreateValue === 'quiz'"
                          @click="contentCreateSelector('quiz')"
                        >
                          <span class="media-object media-object--row">
                            <span class="media-object__media">
                              <icon-container
                                name="file"
                                :height="21"
                                :width="19"
                                view-box="0 0 384 512"
                                :is-icon-class="true"
                              >
                                <icon-file />
                              </icon-container>
                            </span>
                            <span class="card__title media-object__text h4">{{ $t('quiz_') }}</span>
                          </span>
                        </button>
                      </div>
                      <div class="col-6 col-sm-4">
                        <button
                          type="button"
                          class="card card--media js-content-tab"
                          :class="{ 'has-active' : contentCreateValue === 'survey' }"
                          :disabled="contentCreateValue === 'survey'"
                          @click="contentCreateSelector('survey')"
                        >
                          <span class="media-object media-object--row">
                            <span class="media-object__media">
                              <icon-container
                                name="questioncircle"
                                :height="21"
                                :width="21"
                                view-box="0 0 512 512"
                                :is-icon-class="true"
                              >
                                <icon-questioncircle />
                              </icon-container>
                            </span>
                            <span class="card__title media-object__text h4">{{ $t('survey_') }}</span>
                          </span>
                        </button>
                      </div>
                    </div>

                    <div
                      class="row slide"
                    >
                      <div class="col-6 col-sm-4">
                        <button
                          type="button"
                          class="card card--media"
                          :class="{ 'has-active' : contentCreateValue === 'checklist' }"
                          :disabled="contentCreateValue === '.mobile-content__checklistt'"
                          @click="contentCreateSelector('checklist')"
                        >
                          <span class="media-object media-object--row">
                            <span class="media-object__media">
                              <icon-container
                                name="tasks"
                                :height="21"
                                :width="19"
                                view-box="0 0 512 512"
                                :is-icon-class="true"
                              >
                                <icon-tasks />
                              </icon-container>
                            </span>
                            <span class="card__title media-object__text h4">{{ $t('checklist_') }}</span>
                          </span>
                        </button>
                      </div>
                      <div class="col-6 col-sm-4">
                        <button
                          type="button"
                          class="card card--media js-content-tab"
                          :class="{ 'has-active' : contentCreateValue === 'scorm' }"
                          :disabled="contentCreateValue === 'scorm'"
                          @click="contentCreateSelector('scorm')"
                        >
                          <span class="media-object media-object--row">
                            <span class="media-object__media">
                              <icon-container
                                name="scorm"
                                :height="24"
                                :width="24"
                                view-box="0 0 32 32"
                                :is-icon-class="true"
                              >
                                <icon-scorm />
                              </icon-container>
                            </span>
                            <span class="card__title media-object__text h4">{{ $t('scorm') }}</span>
                          </span>
                        </button>
                      </div>
                      <div class="col-6 col-sm-4">
                        <button
                          type="button"
                          class="card card--media js-content-tab"
                          :class="{ 'has-active' : contentCreateValue === 'podcast' }"
                          :disabled="contentCreateValue === 'podcast'"
                          @click="contentCreateSelector('podcast')"
                        >
                          <span
                            class="media-object media-object--row"
                          >
                            <span class="media-object__media">
                              <icon-container
                                name="podcast"
                                :height="30"
                                :width="26"
                                view-box="0 0 19 22"
                                :is-icon-class="true"
                              >
                                <icon-podcast />
                              </icon-container>
                            </span>
                            <span class="card__title media-object__text h4">{{ $t('podcast').toUpperCase() }}</span>
                          </span>
                        </button>
                      </div>
                      <div class="col-6 col-sm-4">
                        <button
                          type="button"
                          class="card card--media js-content-tab"
                          :class="{ 'has-active' : contentCreateValue === 'request' }"
                          :disabled="contentCreateValue === 'request'"
                          @click="contentCreateSelector('request')"
                        >
                          <span class="media-object media-object--row">
                            <span class="media-object__media">
                              <icon-container
                                name="request"
                                :height="36"
                                :width="36"
                                view-box="0 0 64 64"
                                :is-icon-class="true"
                              >
                                <icon-request />
                              </icon-container>
                            </span>
                            <span class="card__title media-object__text h4">{{ $t('request_') }}</span>
                          </span>
                        </button>
                      </div>
                      <div class="col-6 col-sm-4">
                        <button
                          type="button"
                          class="card card--media"
                          :class="{ 'has-active' : contentCreateValue === 'flipbookeditable' }"
                          :disabled="contentCreateValue === 'flipbookeditable'"
                          @click="contentCreateSelector('flipbookeditable')"
                        >
                          <span class="media-object media-object--row">
                            <span class="media-object__media">
                              <icon-container
                                name="copy"
                                :height="21"
                                :width="19"
                                view-box="0 0 448 512"
                                :is-icon-class="true"
                              >
                                <icon-copy />
                              </icon-container>
                            </span>
                            <span class="card__title media-object__text h4">{{ $t('flipbook_editor') }}</span>
                          </span>
                        </button>
                      </div>
                      <div class="col-6 col-sm-4">
                        <button
                          type="button"
                          class="card card--media js-content-tab"
                          :class="{ 'has-active' : contentCreateValue === 'file_download' }"
                          :disabled="contentCreateValue === 'file_download'"
                          @click="contentCreateSelector('file_download')"
                        >
                          <span
                            class="media-object media-object--row"
                          >
                            <span class="media-object__media">
                              <icon-container
                                name="file_download"
                                :height="23"
                                :width="23"
                                view-box="0 0 14 14"
                                :is-icon-class="true"
                              >
                                <icon-download />
                              </icon-container>
                            </span>
                            <span class="card__title media-object__text h4">{{ $t('file_download') }}</span>
                          </span>
                        </button>
                      </div>
                    </div>
                    <div class="row slide">
                      <div class="col-6 col-sm-4">
                        <button
                          type="button"
                          class="card card--media js-content-tab"
                          :class="{ 'has-active' : contentCreateValue === 'pdf_upload' }"
                          :disabled="contentCreateValue === 'pdf_upload'"
                          @click="contentCreateSelector('pdf_upload')"
                        >
                          <span class="media-object media-object--row">
                            <span class="media-object__media">
                              <icon-container
                                name="pdf"
                                :height="24"
                                :width="24"
                                view-box="0 0 24 24"
                                :is-icon-class="true"
                              >
                                <icon-pdf />
                              </icon-container>
                            </span>
                            <span class="card__title media-object__text h4">{{ $t('pdf') }}</span>
                          </span>
                        </button>
                      </div>
                      <div
                        class="col-6 col-sm-4"
                        v-if="$store.state.modal.launchedFrom === 'journeyitemsv2'"
                      >
                        <button
                          type="button"
                          class="card card--media js-content-tab"
                          :class="{ 'has-active' : contentCreateValue === 'chapter' }"
                          :disabled="contentCreateValue === 'chapter'"
                          @click="contentCreateSelector('chapter')"
                        >
                          <span
                            class="media-object media-object--row"
                          >
                            <span class="media-object__media">
                              <icon-container
                                name="chapter"
                                :height="21"
                                :width="28"
                                view-box="0 0 21 28"
                                :is-icon-class="true"
                              >
                                <icon-chapter />
                              </icon-container>
                            </span>
                            <span class="card__title media-object__text h4">{{ $t('chapter') }}</span>
                          </span>
                        </button>
                      </div>
                      <div
                        class="col-6 col-sm-4"
                        v-if="isVibonsAdmin"
                      >
                        <button
                          v-if="$store.state.modal.launchedFrom === 'Contents'"
                          type="button"
                          class="card card--media js-content-tab"
                          :class="{ 'has-active' : contentCreateValue === 'external-youtbe-link' }"
                          :disabled="contentCreateValue === 'external-youtbe-link'"
                          @click="contentCreateSelector('external-youtbe-link')"
                        >
                          <span
                            class="media-object media-object--row"
                          >
                            <span class="media-object__media">
                              <svg-icon
                                type="mdi"
                                width="21"
                                height="23"
                                :path="getImgUrl('mdiYoutubeTv')"
                              />
                            </span>
                            <span class="card__title media-object__text h4">{{ $t('youtube_channel_videos') }}</span>
                          </span>
                        </button>
                      </div>
                    </div>
                    <template slot="prevButton">
                      <icon-container
                        name="contenttoggle"
                        view-box="0 0 32 32"
                        :width="32"
                        :height="32"
                        color="#727D92"
                      >
                        <icon-contenttoggle />
                      </icon-container>
                    </template>
                    <template slot="nextButton">
                      <icon-container
                        name="contenttoggle"
                        class="slide-navbutton"
                        view-box="0 0 32 32"
                        :width="32"
                        :height="32"
                        color="#727D92"
                      >
                        <icon-contenttoggle />
                      </icon-container>
                    </template>
                  </vue-agile>
                </div>
                <div class="tab-content">
                  <keep-alive>
                    <video-content
                      :edit-mode="editMode"
                      :disabled="canNotEdit"
                      :can-edit-only-item="canEditOnlyItem"
                      :name="name"
                      :video-url="videoPreview"
                      :video-type="videoType"
                      @setTitle="setTitle"
                      @setVideoType="(type) => videoType=type"
                      @setVideoFileUrl="(file) => videoPreview=file"
                      @videoDuration="setVideoDuration"
                      @file="fileEvent"
                      @video="videoEvent"
                      @updateContent="createContent('UPDATE_VIDEO_CONTENT')"
                      @createContent="createContent('CREATE_VIDEO_CONTENT')"
                      v-if="contentCreateValue === 'video' && tabPane === 'mainTab'"
                    />
                    <podcast-content
                      :edit-mode="editMode"
                      :disabled="canNotEdit"
                      :can-edit-only-item="canEditOnlyItem"
                      :name="name"
                      :podcast-url="podcastPreview"
                      @setTitle="setTitle"
                      @file="fileEvent"
                      @podcast="podcastEvent"
                      @updateContent="createContent('UPDATE_PODCAST_CONTENT')"
                      @createContent="createContent('CREATE_PODCAST_CONTENT')"
                      v-if="contentCreateValue === 'podcast' && tabPane === 'mainTab'"
                    />
                    <infographics-content
                      :edit-mode="editMode"
                      :disabled="canNotEdit"
                      :can-edit-only-item="canEditOnlyItem"
                      :name="name"
                      :info-graph-url="infographPreview"
                      @setTitle="setTitle"
                      @infograph="infographEvent"
                      @updateContent="createContent('UPDATE_INFOGRAPHICS_CONTENT')"
                      @createContent="createContent('CREATE_INFOGRAPHICS_CONTENT')"
                      v-if="contentCreateValue === 'infographics' && tabPane === 'mainTab'"
                    />
                    <scorm-content
                      :edit-mode="editMode"
                      :disabled="canNotEdit"
                      :can-edit-only-item="canEditOnlyItem"
                      :name="name"
                      :external-url="externalUrl"
                      :index-file-path="indexFilePath"
                      @setTitle="setTitle"
                      @setPackage="(file) => package_zip = file"
                      @setIndexFilePath="(val) => indexFilePath = val"
                      @scorm="(value) => externalUrl = value"
                      @updateContent="createContent('UPDATE_SCORM_CONTENT')"
                      @createContent="createContent('CREATE_SCORM_CONTENT')"
                      v-if="contentCreateValue === 'scorm' && tabPane === 'mainTab'"
                    />
                    <article-content
                      :edit-mode="editMode"
                      :disabled="canNotEdit"
                      :can-edit-only-item="canEditOnlyItem"
                      :name="name"
                      :article-html="injectedArticleHtml"
                      @setTitle="setTitle"
                      @file="fileEvent"
                      @article="articleEvent"
                      @updateContent="createContent('UPDATE_ARTICLE_CONTENT')"
                      @createContent="createContent('CREATE_ARTICLE_CONTENT')"
                      v-if="contentCreateValue === 'article' && tabPane === 'mainTab'"
                    />
                    <flipbook-content
                      :edit-mode="editMode"
                      :disabled="canNotEdit"
                      :can-edit-only-item="canEditOnlyItem"
                      :thumbnail-urls="flipImages"
                      :name="name"
                      @setTitle="setTitle"
                      @setSpinner="setSpinner"
                      @flipEvent="flipEvent"
                      @flipSort="flipSort"
                      @updateContent="createContent('UPDATE_FLIPBOOK_CONTENT')"
                      @createContent="createContent('CREATE_FLIPBOOK_CONTENT')"
                      @removePreview="removePreviewItem"
                      v-if="contentCreateValue === 'flipbook' && tabPane === 'mainTab'"
                    />
                    <quiz-content
                      :edit-mode="editMode"
                      :disabled="canNotEdit"
                      :can-edit-only-item="canEditOnlyItem"
                      :name="name"
                      :root-questions="questions"
                      @setTitle="setTitle"
                      @updateContent="createContent('UPDATE_QUIZ_CONTENT')"
                      @createContent="createContent('CREATE_QUIZ_CONTENT')"
                      v-if="contentCreateValue === 'quiz' && tabPane === 'mainTab'"
                    >
                      <quiz-component
                        :edit-mode="editMode"
                        :disabled="canNotEdit"
                        :can-edit-only-item="canEditOnlyItem"
                        :quiz-type="quizType"
                        as-scope="quizquiz"
                        :repetitive_max_question_per_day="repetitiveMaxQuestionPerDay"
                        :repetitive_correct_answer_count="repetitiveCorrectAnswerCount"
                        @setQuestions="setQuestions"
                        @triggerPreviewNextQuestion="previewQuestionNext"
                      />
                    </quiz-content>
                    <survey-content
                      :edit-mode="editMode"
                      :disabled="canNotEdit"
                      :can-edit-only-item="canEditOnlyItem"
                      :name="name"
                      :survey-content-type="surveyContentType"
                      @setTitle="setTitle"
                      @setSurveyContentType="(type) => surveyContentType=type"
                      @setQuestions="setSurveyQuestions"
                      @updateContent="createContent('UPDATE_SURVEY_CONTENT')"
                      @createContent="createContent('CREATE_SURVEY_CONTENT')"
                      @triggerPreviewNextQuestion="previewQuestionNext"
                      v-if="contentCreateValue === 'survey' && tabPane === 'mainTab'"
                    />
                    <request-content
                      :edit-mode="editMode"
                      :disabled="canNotEdit"
                      :can-edit-only-item="canEditOnlyItem"
                      :name="name"
                      @setTitle="setTitle"
                      @setQuestions="setRequestQuestions"
                      @updateContent="createContent('UPDATE_REQUEST_CONTENT')"
                      @createContent="createContent('CREATE_REQUEST_CONTENT')"
                      @triggerPreviewNextQuestion="previewQuestionNext"
                      v-if="contentCreateValue === 'request' && tabPane === 'mainTab'"
                    />
                    <external-link-content
                      :edit-mode="editMode"
                      :disabled="canNotEdit"
                      :can-edit-only-item="canEditOnlyItem"
                      :name="name"
                      :external-url="externalUrl"
                      @setTitle="setTitle"
                      @setDesc="setDesc"
                      @setDuration="setDuration"
                      @setProvider="(provider) => content_provider = provider"
                      @setTags="setTags"
                      @setInfo="setInfo"
                      @setThumbnail="setThumbnail"
                      @externalUrl="handlerExternalUrl"
                      @updateContent="createContent('UPDATE_EXTERNAL_LINK_CONTENT')"
                      @createContent="createContent('CREATE_EXTERNAL_LINK_CONTENT')"
                      v-if="(contentCreateValue === 'external-link' || contentCreateValue === 'link') && tabPane === 'mainTab'"
                    />
                    <external-youtube-link-content
                      :edit-mode="editMode"
                      :disabled="canNotEdit"
                      :can-edit-only-item="canEditOnlyItem"
                      :name="name"
                      :external-url="externalUrl"
                      :content-language="contentLanguage"
                      :is_public="is_public"
                      :is_notifications_enabled="is_notifications_enabled"
                      :is_email_enabled="is_email_enabled"
                      :push_notification_title="push_notification_title"
                      :push_notification_body="push_notification_body"
                      :is_reminder_enabled="is_reminder_enabled"
                      v-if="(contentCreateValue === 'external-youtbe-link') && tabPane === 'mainTab'"
                    />
                    <checklist-content
                      :edit-mode="editMode"
                      :disabled="canNotEdit"
                      :can-edit-only-item="canEditOnlyItem"
                      :name="name"
                      @setTitle="setTitle"
                      @setChecklistItems="setChecklistItems"
                      @updateContent="createContent('UPDATE_CHECKLIST_CONTENT')"
                      @createContent="createContent('CREATE_CHECKLIST_CONTENT')"
                      v-if="contentCreateValue === 'checklist' && tabPane === 'mainTab'"
                    />
                    <filedownload-content
                      :edit-mode="editMode"
                      :disabled="canNotEdit"
                      :can-edit-only-item="canEditOnlyItem"
                      :name="name"
                      @setTitle="setTitle"
                      @setFiledownloadItems="setFiledownloadItems"
                      @updateContent="createContent('UPDATE_FILEDOWNLOAD_CONTENT')"
                      @createContent="createContent('CREATE_FILEDOWNLOAD_CONTENT')"
                      v-if="contentCreateValue === 'file_download' && tabPane === 'mainTab'"
                    />
                    <pdfupload-content
                      :edit-mode="editMode"
                      :disabled="canNotEdit"
                      :can-edit-only-item="canEditOnlyItem"
                      :name="name"
                      :external-url="externalUrl"
                      @setTitle="setTitle"
                      @setPdfFile="setPdfFile"
                      @updateContent="createContent('UPDATE_PDFUPLOAD_CONTENT')"
                      @createContent="createContent('CREATE_PDFUPLOAD_CONTENT')"
                      v-if="contentCreateValue === 'pdf_upload' && tabPane === 'mainTab'"
                    />
                    <notification-content
                      :edit-mode="editMode"
                      :disabled="canNotEdit"
                      :n-body="notification_body"
                      :n-title="notification_title"
                      :n-journey-item-id="notification_journey_item_id"
                      @setNBody="setNotificationBody"
                      @setNTitle="setNotificationTitle"
                      @setNJourneyItemId="setNotificationJourneyId"
                      @updateContent="createContent('UPDATE_SCHEDULED_NOTIFICATION_CONTENT')"
                      @createContent="createContent('CREATE_SCHEDULED_NOTIFICATION_CONTENT')"
                      v-if="contentCreateValue === 'notification' && tabPane === 'mainTab'"
                    />
                    <chapter-content
                      :edit-mode="editMode"
                      :disabled="canNotEdit"
                      :name="name"
                      :desc="desc"
                      @setTitle="setTitle"
                      @setDesc="setDesc"
                      @updateContent="createContent('UPDATE_CHAPTER_CONTENT')"
                      @createContent="createContent('CREATE_CHAPTER_CONTENT')"
                      v-if="contentCreateValue === 'chapter' && tabPane === 'mainTab'"
                    />
                  </keep-alive>
                </div>
                <div
                  class="modal-footer"
                  v-if="isOnlyDatesEditable && !pageIsLoading && contentCreateValue !== 'chapter' && contentCreateValue !== 'file_download'"
                >
                  <button
                    type="button"
                    :disabled="canNotEdit && !canEditOnlyItem"
                    class="button button--primary"
                    @click="updateOnlyDatesEditableItems"
                  >
                    <span
                      v-if="!isPending"
                    >
                      {{ $t('update_button') }}
                    </span>
                    <div
                      class="lds-ellipsis"
                      v-if="isPending"
                    >
                      <div />
                      <div />
                      <div />
                      <div />
                    </div>
                  </button>
                </div>
              </div>

              <div
                class="tab-pane"
                v-if="tabPane === 'quizSettingsTab' && contentCreateValue === 'quiz'"
              >
                <div class="form">
                  <div class="form-group">
                    <div class="swicth-checkbox">
                      <input
                        type="checkbox"
                        id="quizType"
                        v-model="quizType"
                        :disabled="canNotEdit && !canEditOnlyItem"
                        :true-value="'repetitive'"
                        :false-value="'standard'"
                      >
                      <label
                        class="swicth-label"
                        for="quizType"
                      >
                        {{ $t('quiz_type') }}
                      </label>
                    </div>
                    <collapse-transition
                      :duration="500"
                      dimension="height"
                    >
                      <div
                        class="row"
                        v-show="quizType === 'repetitive'"
                      >
                        <div
                          class="col-6"
                        >
                          <div class="form-group form-group--light u-mB-0">
                            <label
                              for="journeyLanguage"
                              class="form-label"
                            >{{ $t('repetitive_max_question_per_day') }}</label>
                            <input
                              v-model="repetitiveMaxQuestionPerDay"
                              class="mx-input"
                              id="repetitive_max_question_per_day"
                              type="number"
                              max="50"
                              min="1"
                              step="1"
                            >
                          </div>
                        </div>
                        <div
                          class="col-6"
                        >
                          <div class="form-group form-group--light u-mB-0">
                            <label
                              for="journeyLanguage"
                              class="form-label"
                            >{{ $t('repetitive_correct_answer_count') }}</label>
                            <input
                              v-model="repetitiveCorrectAnswerCount"
                              class="mx-input"
                              type="number"
                              id="repetitive_correct_answer_count"
                              max="50"
                              min="1"
                              step="1"
                            >
                          </div>
                        </div>
                      </div>
                    </collapse-transition>
                  </div>
                  <div class="form-group">
                    <div class="swicth-checkbox">
                      <input
                        type="checkbox"
                        id="is_retake_enabled"
                        v-model="is_retake_enabled"
                        :disabled="canNotEdit && !canEditOnlyItem"
                        :true-value="1"
                        :false-value="0"
                      >
                      <label
                        class="swicth-label"
                        for="is_retake_enabled"
                      >
                        {{ $t('is_retake_enabled') }}
                      </label>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="swicth-checkbox">
                      <input
                        type="checkbox"
                        id="is_show_correct_answers_enabled"
                        v-model="is_show_correct_answers_enabled"
                        :disabled="canNotEdit && !canEditOnlyItem"
                        :true-value="0"
                        :false-value="1"
                      >
                      <label
                        class="swicth-label"
                        for="is_show_correct_answers_enabled"
                      >
                        {{ $t('is_show_correct_answers_enabled') }}
                      </label>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="swicth-checkbox">
                      <input
                        type="checkbox"
                        id="is_show_score"
                        v-model="is_show_score"
                        :disabled="canNotEdit && !canEditOnlyItem"
                        :true-value="1"
                        :false-value="0"
                      >
                      <label
                        class="swicth-label"
                        for="is_show_score"
                      >
                        {{ $t('is_show_score') }}
                      </label>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="swicth-checkbox">
                      <input
                        type="checkbox"
                        id="is_randomize_questions_enabled"
                        v-model="is_randomize_questions_enabled"
                        :disabled="canNotEdit && !canEditOnlyItem"
                        :true-value="1"
                        :false-value="0"
                      >
                      <label
                        class="swicth-label"
                        for="is_randomize_questions_enabled"
                      >
                        {{ $t('is_randomize_questions_enabled') }}
                      </label>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="swicth-checkbox">
                      <input
                        type="checkbox"
                        id="is_randomize_choices_enabled"
                        v-model="is_randomize_choices_enabled"
                        :disabled="canNotEdit && !canEditOnlyItem"
                        :true-value="1"
                        :false-value="0"
                      >
                      <label
                        class="swicth-label"
                        for="is_randomize_choices_enabled"
                      >
                        {{ $t('is_randomize_choices_enabled') }}
                      </label>
                    </div>
                  </div>
                  <div class="form-group col-4">
                    <label
                      for="max_questions_count"
                      class="form-label"
                    >{{ $t('max_questions_count') }}</label>
                    <input
                      class="form-control"
                      type="number"
                      v-model="max_questions_count"
                      min="0"
                      :max="this.questions ? this.questions.length : 0"
                    >
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    :disabled="canNotEdit && !canEditOnlyItem"
                    class="button button--primary"
                    @click="tabNavigation('mainTab')"
                  >
                    {{ $t('save') }}
                  </button>
                </div>
              </div>
              <div
                class="tab-pane"
                v-if="contentCreateValue !== 'notification' && contentCreateValue !== 'chapter' && !isOnlyDatesEditable"
                v-show="tabPane === 'advancedSettingsTab'"
              >
                <div class="form form--horizontal">
                  <div
                    class="form-group form-group--cards"
                  >
                    <div class="row">
                      <div
                        class="col-6"
                      >
                        <label
                          for="uploadThumbnail"
                          class="form-label"
                        >{{ $t('thumbnail') }}</label>
                        <thumbnail-container
                          :class="{ 'is-danger': errors.has('all.thumbnail') }"
                          :thumbnail-image="thumbnailPreview"
                          :vee-scoop-name="'all'"
                          :is-disable="canNotEdit"
                          @update="thumbnailAdded"
                          class="card card--upload"
                        />
                        <span
                          v-show="errors.has('all.thumbnail')"
                          class="help is-danger"
                        >{{ errors.first('all.thumbnail') }}</span>
                      </div>
                      <div class="col-6">
                        <div class="row">
                          <div class="col-12 mb-2">
                            <label
                              for="contentLang"
                              class="form-label"
                            >{{ $t('content_lang_label') }}</label>
                            <multi-select
                              :class="{ 'is-danger': errors.has('all.language') }"
                              track-by="value"
                              name="contentLang"
                              label="value"
                              :disabled="canNotEdit"
                              clear-on-select
                              :placeholder="$t('select_placeholder')"
                              :tag-placeholder="$t('tag_placeholder')"
                              :deselect-label="$t('deselect_placeholder')"
                              :select-label="$t('enter_select_placeholder')"
                              :selected-label="$t('selected_label')"
                              v-model="contentLanguage"
                              data-vv-scope="all"
                              data-vv-name="language"
                              :options="contentLanguageOptions"
                              class="selectbox selectbox--secondary"
                              v-validate="'required'"
                              :data-vv-as="$t('content_lang_label')"
                            />
                            <span
                              v-if="errors.has('all.language')"
                              class="help is-danger"
                            >{{ errors.first('all.language') }}</span>
                          </div>
                          <div class="col-12 mb-2">
                            <label
                              class="form-label"
                              for="cprovider"
                            >{{ $t('content_provider').toUpperCase() }}</label>
                            <input
                              class="form-control"
                              :class="{ 'is-danger': errors.has('all.content_provider') }"
                              type="text"
                              id="cprovider"
                              :disabled="canNotEdit"
                              data-vv-scope="all"
                              data-vv-name="content_provider"
                              v-validate="'max:200'"
                              v-model="content_provider"
                            >
                            <span
                              v-if="errors.has('all.content_provider')"
                              class="help is-danger"
                            >{{ errors.first('all.content_provider') }}</span>
                          </div>

                          <div class="col-12 mb-2">
                            <label
                              class="form-label"
                              for="info"
                            >{{ $t('info_label') }}</label>
                            <input
                              class="form-control"
                              :class="{ 'is-danger': errors.has('all.info') }"
                              type="text"
                              id="info"
                              :disabled="canNotEdit"
                              data-vv-scope="all"
                              data-vv-name="info"
                              v-validate="'max:30'"
                              v-model="info"
                            >
                            <span
                              v-if="errors.has('all.info')"
                              class="help is-danger"
                            >{{ errors.first('all.info') }}</span>
                          </div>

                          <div class="col-12">
                            <label
                              for="desc"
                              class="form-label"
                            >{{ $t('description') }}</label>
                            <textarea
                              :class="{ 'is-danger': errors.has('all.description') }"
                              class="form-control"
                              v-model="desc"
                              :disabled="canNotEdit && !canEditOnlyItem"
                              data-vv-scope="all"
                              data-vv-name="description"
                              :data-vv-as="$t('description')"
                              v-validate="'max:1000'"
                            />
                            <span
                              v-show="errors.has('all.description')"
                              class="help is-danger"
                            >{{ errors.first('all.description') }}</span>
                          </div>
                          <div class="col-12 mb-2">
                            <label
                              for="duration"
                              class="form-label"
                            >{{ $t('duration').toUpperCase() }}</label>
                            <input
                              class="form-control"
                              :class="{ 'is-danger': errors.has('all.duration') }"
                              type="number"
                              id="duration"
                              :disabled="canNotEdit"
                              data-vv-scope="all"
                              data-vv-name="duration"
                              v-validate="'max:10'"
                              v-model="duration"
                            >
                            <span
                              v-show="errors.has('all.duration')"
                              class="help is-danger"
                            >{{ errors.first('all.duration') }}</span>
                          </div>
                          <div class="col-12">
                            <label
                              for="contentTags"
                              class="form-label"
                            >{{ $t('tags_detail') }}</label>
                            <vue-tags-input
                              v-model="tag"
                              :disabled="canNotEdit"
                              :tags="tagsTemp"
                              :add-on-key="[13, ':', ';', ',']"
                              @tags-changed="tagsEvent"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group d-flex justify-content-center noborder-bottom">
                    <div class="col-6">
                      <div class="swicth-checkbox">
                        <input
                          type="checkbox"
                          id="sendPushNotification"
                          v-model="is_push_enabled"
                          :disabled="canNotEdit && !canEditOnlyItem"
                          :true-value="1"
                          :false-value="0"
                        >
                        <label
                          class="swicth-label"
                          for="sendPushNotification"
                        >
                          {{ $t('send_push_notif') }}
                        </label>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="swicth-checkbox">
                        <input
                          type="checkbox"
                          id="sendEmail"
                          :disabled="canNotEdit && !canEditOnlyItem"
                          v-model="is_email_enabled"
                          :true-value="1"
                          :false-value="0"
                        >
                        <label
                          class="swicth-label"
                          for="sendEmail"
                        >{{ $t('send_email') }}</label>
                      </div>
                    </div>
                  </div>
                  <collapse-transition
                    :duration="500"
                    dimension="height"
                  >
                    <div
                      v-show="is_push_enabled"
                      class="row pl-2 bglight"
                    >
                      <div class="form-group row">
                        <label
                          for="pushNotificationHeading"
                          class="col-md-5 form-label"
                        >
                          {{ $t('push_noti_head') }}
                        </label>
                        <div class="col-md-7">
                          <input
                            :class="{ 'is-danger': errors.has('all.push_notification_title') }"
                            type="text"
                            class="form-control"
                            :disabled="canNotEdit && !canEditOnlyItem"
                            id="pushNotificationHeading"
                            v-model="push_notification_title"
                            data-vv-name="push_notification_title"
                            data-vv-scope="all"
                            :data-vv-as="$t('push_noti_head')"
                            v-validate="'max:40'"
                            @keypress="clearNotifMsg()"
                          ><!-- eslint-disable-next-line vue-i18n/no-raw-text -->
                          <button
                            class="emoji-button"
                            @click="dropdownToggle('title')"
                            v-html="'☺'"
                          />
                          <div
                            class="dropdown"
                            style="z-index: 1000001"
                            v-if="dropdownIndex === 'title'"
                            v-on-clickaway="hideDropdown"
                          >
                            <div
                              class="dropdown__menu emoji-dropdown-title"
                              ref="dropdownBoxtitle"
                            >
                              <VEmojiPicker
                                class="emoji"
                                :pack="emojiPack"
                                :label-search="$t('search_placeholder')"
                                @select="SelectEmojiForTitle"
                              />
                            </div>
                          </div>
                          <span
                            v-if="errors.has('all.push_notification_title')"
                            class="help is-danger"
                          >{{ errors.first('all.push_notification_title') }}</span>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label
                          for="pushNotificationBody"
                          class="col-md-5 form-label"
                        >
                          {{ $t('push_noti_body') }}
                        </label>
                        <div class="col-md-7">
                          <textarea
                            :class="{ 'is-danger': errors.has('all.push_notification_body') }"
                            id="pushNotificationBody"
                            class="form-control"
                            :disabled="canNotEdit && !canEditOnlyItem"
                            v-model="push_notification_body"
                            data-vv-name="push_notification_body"
                            data-vv-scope="all"
                            :data-vv-as="$t('push_noti_body')"
                            v-validate="'max:110'"
                            @keypress="clearNotifMsg()"
                          /><!-- eslint-disable-next-line vue-i18n/no-raw-text -->
                          <button
                            class="emoji-button"
                            @click="dropdownToggle('body')"
                            v-html="'☺'"
                          />
                          <div
                            class="dropdown"
                            style="z-index: 1000001"
                            v-if="dropdownIndex === 'body'"
                            v-on-clickaway="hideDropdown"
                          >
                            <div
                              class="dropdown__menu emoji-dropdown-body"
                              ref="dropdownBoxbody"
                            >
                              <VEmojiPicker
                                class="emoji"
                                :pack="emojiPack"
                                :label-search="$t('search_placeholder')"
                                @select="SelectEmojiForBody"
                              />
                            </div>
                          </div>
                          <span
                            v-if="errors.has('all.push_notification_body')"
                            class="help is-danger"
                          >{{ errors.first('all.push_notification_body') }}</span>
                        </div>
                      </div>
                    </div>
                  </collapse-transition>

                  <div
                    class="form-group d-flex justify-content-start noborder-bottom"
                  >
                    <div
                      v-if="this.$store.state.modal.launchedFrom === 'journeyitems'"
                      class="col-md-4"
                    >
                      <div class="swicth-checkbox">
                        <input
                          type="checkbox"
                          id="hideLabel"
                          v-model="is_info_labels_hidden"
                          :disabled="canNotEdit && !canEditOnlyItem"
                          @change="hideTitle($event)"
                          :true-value="1"
                          :false-value="0"
                        >
                        <label
                          class="swicth-label"
                          for="hideLabel"
                        >{{ $t('hide_title') }}</label><span
                          class="vtooltip"
                          v-tooltip="$t('hide_title_tooltip_msg')"
                        ><em class="fa fa-question-circle" /></span>
                      </div>
                    </div>
                    <div
                      v-if="this.$store.state.modal.launchedFrom === 'journeyitems'"
                      class="col-md-4"
                    >
                      <div
                        v-if="isVibonsAdmin"
                        class="swicth-checkbox"
                      >
                        <input
                          type="checkbox"
                          id="setStatic"
                          v-model="is_static"
                          :disabled="canNotEdit && !canEditOnlyItem"
                          :true-value="1"
                          :false-value="0"
                        >
                        <label
                          class="swicth-label"
                          for="setStatic"
                        >
                          {{ $t('static_content') }}
                        </label><span
                          class="vtooltip"
                          v-tooltip="$t('static_content_tooltip_msg')"
                        ><li class="fa fa-question-circle" /></span>
                      </div>
                    </div>
                    <div
                      class="col-6"
                      v-if="showHideFromLxp"
                    >
                      <div class="swicth-checkbox">
                        <input
                          type="checkbox"
                          id="hideFromLxp"
                          v-model="hide_from_lxp"
                          :true-value="1"
                          :false-value="0"
                        >
                        <label
                          class="swicth-label"
                          for="hideFromLxp"
                        >
                          {{ $t('hidefromlxp') }}
                        </label>
                      </div>
                    </div>
                    <div
                      class="col-6"
                      v-if="contentCreateValue === 'request'"
                    >
                      <div class="swicth-checkbox">
                        <input
                          type="checkbox"
                          id="is_file_upload_required"
                          v-model="is_file_upload_required"
                          :true-value="1"
                          :false-value="0"
                        >
                        <label
                          class="swicth-label"
                          for="is_file_upload_required"
                        >
                          {{ $t('file_upload_required') }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div
                    class="form-group"
                    style="display:none;"
                  >
                    <div class="col-md-12">
                      <div class="swicth-checkbox">
                        <input
                          type="checkbox"
                          id="openedDays"
                          v-model="is_reminder_enabled"
                          :disabled="canNotEdit"
                          :true-value="1"
                          :false-value="0"
                        >
                        <label
                          class="swicth-label"
                          for="openedDays"
                        >
                          {{ $t('send_reminder') }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="modal-footer">
                  <button
                    type="button"
                    :disabled="canNotEdit && !canEditOnlyItem"
                    class="button button--primary"
                    @click="tabNavigation('mainTab')"
                  >
                    {{ $t('save') }}
                  </button>
                </div>
              </div>
              <div
                class="tab-pane"
                v-show="tabPane === 'adminSettingsTab'"
              >
                <div class="form form--horizontal">
                  <div class="row">
                    <div class="col-6">
                      <label
                        for="customer"
                        class="form-label"
                      >{{ $t('customer').toUpperCase() }}</label>
                      <multi-select
                        :class="{ 'is-danger': errors.has('all.customer') }"
                        track-by="id"
                        label="name"
                        clear-on-select
                        :placeholder="$t('select_placeholder')"
                        :tag-placeholder="$t('tag_placeholder')"
                        :deselect-label="$t('deselect_placeholder')"
                        :select-label="$t('enter_select_placeholder')"
                        :selected-label="$t('selected_label')"
                        v-model="selectCustomer"
                        :options="customerOptions"
                        class="selectbox selectbox--secondary"
                        data-vv-scope="all"
                        data-vv-name="customer"
                      />
                      <span
                        v-if="errors.has('all.customer')"
                        class="help is-danger"
                      >{{ errors.first('all.customer') }}</span>
                    </div>
                    <div class="col-6">
                      <label
                        class="form-label"
                        for="thumbnail_filename"
                      >{{ $t('thumbnail_filename') }}</label>
                      <input
                        class="form-control"
                        :class="{ 'is-danger': errors.has('all.thumbnail_filename') }"
                        type="text"
                        id="thumbnail_filename"
                        :disabled="canNotEdit"
                        data-vv-scope="all"
                        data-vv-name="thumbnail_filename"
                        v-validate="'max:400'"
                        v-model="thumbnail_filename"
                      >
                      <span
                        v-if="errors.has('all.thumbnail_filename')"
                        class="help is-danger"
                      >{{ errors.first('all.thumbnail_filename') }}</span>
                    </div>
                    <div class="col-12 mt-4">
                      <label
                        for="options"
                        class="form-label"
                      >{{ $t('options') }}</label>
                      <textarea
                        :class="{ 'is-danger': errors.has('all.options') }"
                        class="form-control"
                        v-model="aoptions"
                        :disabled="canNotEdit && !canEditOnlyItem"
                        data-vv-scope="all"
                        data-vv-name="options"
                        :data-vv-as="$t('options')"
                        v-validate="'max:1000'"
                      />
                      <span
                        v-show="errors.has('all.options')"
                        class="help is-danger"
                      >{{ errors.first('all.options') }}</span>
                    </div>
                    <div class="col-12">
                      <div class="swicth-checkbox">
                        <input
                          type="checkbox"
                          id="setPublic"
                          :disabled="canNotEdit"
                          v-model="is_public"
                          :true-value="1"
                          :false-value="0"
                        >
                        <label
                          class="swicth-label"
                          for="setPublic"
                        >
                          {{ $t('is_public_content') }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    :disabled="canNotEdit && !canEditOnlyItem"
                    class="button button--primary"
                    @click="tabNavigation('mainTab')"
                  >
                    {{ $t('save') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        @click.stop
        v-if="(!isOnlyDatesEditable || contentCreateValue === 'file_download' || contentCreateValue === 'chapter')"
        class="modal__mobile-preview"
        :class="{ 'rotatepreview' : showVideo }"
      >
        <div class="flag">
          <icon-container
            name="flag"
            :width="224"
            :height="21"
            view-box="0 0 224 21"
            :is-icon-class="false"
          >
            <icon-flag />
          </icon-container>
        </div>
        <div
          class="mobile-content"
          :class="{ 'bluered-bg': (
            contentCreateValue === 'podcast'
            || contentCreateValue === 'file_download'
            || contentCreateValue === 'checklist'
          ), 'bgBlack': (contentCreateValue === 'infographics' || contentCreateValue === 'scorm' || contentCreateValue === 'flipbook')
          }"
          :style="injectedArticleHtml ? 'background:#ffffff;': ''"
        >
          <div
            v-show="showVideo && showControl"
            class="videopausecontrol"
          >
            <button
              @click="exitVideo"
              @mouseenter="showControl = true"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style="fill:#fff;fill-opacity:0.5;"
                viewBox="0 0 24 24"
              ><path
                d="M0 0h24v24H0z"
                fill="none"
              /><path d="M12 10.6l5-4.9 1.4 1.4-4.9 5 5 5-1.4 1.4-4.9-4.9-4.9 5-1.4-1.4 5-4.9-4.9-4.9L7.1 5.6z" /></svg>
            </button>
          </div>
          <flip-book
            :images="flipImages"
            :key="flipImages.length"
            v-if="contentCreateValue === 'flipbook' && flipImages.length > 0"
          />
          <div
            v-if="infographPreview && contentCreateValue === 'infographics'"
            class="infograph-content"
          >
            <img
              :src="infographPreview"
              alt="infographic"
            >
          </div>
          <div
            v-if="videoPreview && showVideo && contentCreateValue === 'video'"
            class="videocontent"
          >
            <video
              id="videoElement"
              @mouseover="showControl = true"
              @mouseleave="showControl = false"
              @canplay="updatePaused"
              @playing="updatePaused"
              @pause="updatePaused"
              @ended="exitVideo"
              controls
              playsinline
              webkit-playsinline
              autoplay
              volume="0.5"
            >
              <source
                :src="videoPreview"
                type="video/mp4"
              >
              {{ $t('not_support_html5') }}
            </video>
          </div>
          <div
            v-if="podcastPreview && contentCreateValue === 'podcast'"
            class="podcastcontent"
          >
            <div class="contentBox">
              <div class="podcast-icon">
                <img

                  src="@/assets/img/podcast-mic.png"
                  alt="podcast"
                >
              </div>
              <div class="podcast-title">
                {{ name }}
              </div>
              <div
                class="podcast-desc"
                v-if="desc"
              >
                {{ desc }}
              </div>
            </div>
            <div class="controlBox">
              <div
                class="currentProgressBar"
                ref="progressBar"
                @mousedown="podcastSeek"
              >
                <div
                  class="currentProgress"
                  :style="{ width: currentPodcastProgressBar + '%' }"
                />
              </div>
              <div class="currentTimeContainer">
                <span class="currentTime">{{ currentPodcastTime | timeFormat }}</span>
                <span class="totalTime"> {{ podcastDuration | timeFormat }}</span>
              </div>
              <div class="controlButtonsContainer">
                <img
                  @click="backwardPodcast()"
                  src="@/assets/img/podcast-backward.png"
                  alt="Backward"
                >
                <transition
                  name="slide-fade"
                  mode="out-in"
                >
                  <img
                    key="play"
                    v-if="!podcastIsPlaying"
                    @click="togglePodcast()"
                    src="@/assets/img/podcast-play.png"
                    alt="Play"
                  >
                  <img
                    key="pause"
                    v-if="podcastIsPlaying"
                    @click="togglePodcast()"
                    src="@/assets/img/podcast-pause.png"
                    alt="Pause"
                  >
                </transition>
                <img
                  @click="forwardPodcast()"
                  src="@/assets/img/podcast-forward.png"
                  alt="Forward"
                >
              </div>
            </div>
          </div>
          <div
            v-if="contentCreateValue === 'quiz' && displayquestions && displayquestions.length > 0"
            class="mobile-content__quiz"
          >
            <div class="quiz-preview--container">
              <div
                class="quiz-preview--question"
                v-for="(questionPreview, index) in displayquestions"
                :key="index"
              >
                <div class="quiz-preview--progress">
                  <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
                  <span>{{ (displayquestions) ? parseInt(((100 / displayquestions.length) * (index + 1) ), 10) + '% ' + $t('completed_') : '' }}</span>
                  <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
                  <div class="quiz-progress-bar">
                    <span :style="{ width: (100 / displayquestions.length) * (index + 1) + '%' }" />
                  </div>
                </div>
                <h5>{{ questionPreview.question }}</h5>
                <ul v-if="questionPreview.type ==='standard' && !(questionPreview.choices[0].image || questionPreview.choices[0].imagePreview)">
                  <ul v-if="questionPreview.type ==='standard' && !(questionPreview.choices[0].image || questionPreview.choices[0].imagePreview)">
                    <li
                      v-for="(choice, scindex) in questionPreview.choices"
                      :key="scindex"
                    >
                      <span
                        v-if="choice.choice_text"
                        class="d-flex p-2"
                        :class="{ 'is-correct' : choice.is_correct == 1 }"
                      >
                        <span
                          class="optiontick"
                        >
                          {{ $helpers.choiceLetter(scindex) }}
                        </span>
                        {{ choice.choice_text }}
                      </span>
                    </li>
                  </ul>
                  <ul
                    v-if="questionPreview.type ==='standard' && (questionPreview.choices[0].image || questionPreview.choices[0].imagePreview)"
                    class="row"
                  >
                    <li
                      v-for="(choice, ccindex) in questionPreview.choices"
                      :key="ccindex"
                      class="col-6 px-1"
                    >
                      <picture
                        class="d-flex flex-column"
                        v-if="choice.imagePreview || choice.image"
                        :class="{ 'is-correct' : choice.is_correct == 1 }"
                      >
                        <img
                          :src="choice.imagePreview || choice.image"
                          alt="Choice Image"
                        >
                        <div
                          v-if="choice.choice_text"
                          class="d-flex p-2"
                          style="mix-blend-mode: difference; font-size: 11px"
                        >
                          {{ choice.choice_text }}
                        </div>
                      </picture>
                    </li>
                  </ul>
                  <ul
                    v-if="questionPreview.type ==='pairs'"
                    id="pair-choices"
                    class="row"
                  >
                    <li
                      v-for="(choice, cindex) in questionPreview.choices"
                      :key="cindex"
                    >
                      <span v-if="choice.choice_text">
                        {{ choice.choice_text }}
                      </span>
                    </li>
                  </ul>
                  <div class="quiz-preview--button">
                    <button
                      type="button"
                      class="button button--secondary size-sm"
                      @click="previewQuestionNext(index)"
                    >
                      {{ $t('next') }}
                    </button>
                  </div>
                </ul>
              </div>
              <div class="quiz-preview--question">
                <div class="quiz-finalpage">
                  <div class="d-flex final-score flex-column justify-content-center">
                    <span class="score-point">{{ $t('score_point') }}</span>
                    <div class="your-score">
                      {{ $t('your_score') }}
                    </div>
                  </div>
                  <div class="completed-text text-center mt-4">
                    {{ $t('quiz_completed') }}
                  </div>
                  <div
                    class="d-flex justify-content-center text-center mt-4"
                  >
                    {{ $t('thanks_feedback') }}
                  </div>
                </div>
                <div class="quiz-preview--button">
                  <button
                    type="button"
                    class="button button--secondary size-sm"
                    @click="previewQuestionNext(-1)"
                  >
                    {{ $t('close') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="contentCreateValue === 'checklist'"
            class="mobile-content__checklist"
          >
            <div class="title">
              {{ name }}
            </div>
            <ul>
              <template v-for="(checklistItem, index) in checklistItems">
                <li
                  :key="index"
                  v-if="checklistItem.item_text"
                >
                  <span v-if="checklistItem.item_text">{{ checklistItem.item_text }}</span>
                </li>
              </template>
            </ul>
          </div>
          <div
            v-if="contentCreateValue === 'file_download'"
            class="mobile-content__filedownload"
          >
            <div class="title">
              {{ desc }}
            </div>
            <ul class="mt-4">
              <template v-for="(filedownloaditem, index) in fileDownloadItems">
                <li

                  v-if="filedownloaditem.title"
                  :key="index"
                  class="filedownload-item"
                  @click="openFileDownloadItem(filedownloaditem)"
                >
                  <div class="filedownload-item-content">
                    <div
                      class="filedownload-item--title"
                      v-if="filedownloaditem.title"
                    >
                      {{ filedownloaditem.title }}
                    </div>
                    <div
                      class="filedownload-item--desc"
                      v-if="filedownloaditem.desc"
                    >
                      {{ filedownloaditem.desc }}
                    </div>
                  </div>
                  <div class="filedownload-item--icon">
                    <icon-container
                      name="downloadfull"
                      class="icon-svg"
                      width="13"
                      height="14"
                      :is-icon-class="false"
                    >
                      <icon-download />
                    </icon-container>
                  </div>
                </li>
              </template>
            </ul>
          </div>
          <div
            v-if="contentCreateValue === 'survey' && surveyQuestions && surveyQuestions.length > 0"
            class="mobile-content__survey"
          >
            <div class="survey-preview--container">
              <div
                class="survey-preview--question"
                v-for="(surveyQuestion, index) in surveyQuestions"
                :key="index"
              >
                <div class="question-content">
                  <h5>{{ surveyQuestion.question }}</h5>
                  <p class="subfont">
                    {{ surveyQuestion.sub_text }}
                  </p>
                  <ul
                    v-if="surveyQuestion.type === 'multiple-choice' || surveyQuestion.type === 'single-choice'"
                    :class="{'haveImages': $helpers.haveSurveyQuestionImages(surveyQuestion.choices)}"
                  >
                    <li
                      v-for="(choice, ssindex) in surveyQuestion.choices"
                      :key="ssindex"
                      class="survey"
                    >
                      <picture
                        class="d-flex flex-column"
                        v-if="choice.imagePreview || choice.image"
                      >
                        <span class="d-flex flex-row">
                          <span
                            class="optiontick"
                          >
                            {{ $helpers.choiceLetter(ssindex) }}
                          </span>
                          <img
                            :src="choice.imagePreview || choice.image"
                            alt="Choice Image"
                          >
                        </span>
                      </picture>
                      <span
                        v-else
                        class="d-flex p-2"
                      >
                        <span
                          class="optiontick"
                        >
                          {{ $helpers.choiceLetter(ssindex) }}
                        </span>
                        {{ choice.choice_text }}
                      </span>
                    </li>
                    <li
                      v-if="surveyQuestion && surveyQuestion.options && surveyQuestion.options.has_other_option"
                      class="survey"
                      :key="`other${surveyQuestion.id}`"
                    >
                      <span class="d-flex p-2">
                        <span
                          class="optiontick"
                        >{{ $helpers.choiceLetter(surveyQuestion.choices.length) }}
                        </span>
                        {{ $t('other') }}
                      </span>
                    </li>
                  </ul>
                  <div
                    v-if="surveyQuestion.type === 'text'"
                    class="survey-textarea"
                  >
                    <textarea
                      name=""
                      id=""
                      :placeholder="$t('type_here')"
                    />
                  </div>
                  <div v-if="surveyQuestion.type === 'rating'">
                    <template v-if="surveyQuestion.options.shape === 'star'">
                      <span
                        class="rating-box"
                      >
                        <template
                          v-for="sindex in Number(surveyQuestion.options.step)"
                        >
                          <img
                            v-if="true"
                            :key="`star${index}${sindex}`"
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA5CAYAAABj2ui7AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAM3SURBVHgB3VqBdeIwDBX3bgA2uIzABucNSidoNigbwE1Ab4LABu0EoRPQDdIN6AaqUuQXxYTEEMsJ/Pf8BLZjWfa3ZSsBGACIOIVI+AXDYAH3Cpq9OaUDRMIQM/hAaUpGziAChjDQOPJ+QLNmsEIO9wYyKsM61HfT2BQ1LL+c/2qIZiBvKgmlT0r/OdvAvYAMfGFaZmItRnMX6iBj9myU4f8H/h/FXaiCjEjcGaPfG85TPdXEWoNzlq8ib8fyAW4dpc/j2ZqLvGlMd6EGQc8TQ5oMD40YFDUs3yaTyZdT9ubUuT2IWUobymY37S6cdZacqaPqLrQpatfWjuj5eabOlqUBBWgb+JfltqXOjuXtuQtBv6Slzm26C3He3HvUzbXcxW9WkJJYQljY2dh61C3dhaGUUV/WoIHSSEoFhkfn7oiVuwiJcnnMJ46ihEQZSkg4a0PpH/RAy+4JDbr7oDy0P/PvD0qPZ3WTspUYiQxHvPjxuEllor8vvg+mWO2CRYARDg6mdiEomV7yvD0oF2J0RhORpr48iwnY95oArMINg1O2iZJB+jMGyjqMupySVyiIRlmHknoDzBSRlF1rUpb1rYNT0kPxQntEh2TMuQ6kEAjU1hOOwU01UDaFnsDjO0SLDY7hoCFG20BPYHUTKWAMwIbAboA27YD9gR4IdaM3LN8hHGzE7RF6IJSBTyxfIRx2LIcPZYgNIYFAwCqUcRh0k8ELQhNXtJ333bhCUNTS02v9sQvIPGfbtqkW2u+EcPTGo648chVdRg7uLoR7KDzq5Y5xdn0tOp4N4i6uAh6vUCWyljoG60cuw/m1A3vL8xuuE/+yjS0vVrh8KYzI8fT12UKUN97KxSDG/65GdC5x8l1KLlrakAf2Us6c8mHcBVYbQN6QX7iU7GjLHZClU27LOtsKBqziIiuRt8Q6JRO4AHgmXCny/UKCISBmyTCNctG5FVwJ5M8tBQMSjO0uULiHayjp2b5t8+cijTHdBVY72wF7ULJDR8mKjWjf6tJ3F1ino+rawPq6/BlI0IYzqgaUgachej13IRZ8HpKSHnrLdVn73k1LUYYxt+tT/StV/TiCrwPxglfd33vRYRyPRxhZAAAAAElFTkSuQmCC"
                            alt="star"
                          >
                        </template>

                      </span>
                    </template>
                    <template v-if="surveyQuestion.options.shape === 'thumb' || surveyQuestion.options.shape === 'thumbsup'">
                      <span
                        class="rating-box"
                      >
                        <template v-for="tindex in Number(surveyQuestion.options.step)">
                          <img
                            v-if="true"
                            :key="`thumb${index}${tindex}`"
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALtSURBVHgB1VrbddswDIV6+h+PwA3qDaJu4E5gd4K6E0SdoO4EdiaIO4G1gesJ7A2cDVBCAiMI1suJHuQ9hw5JUQouCQIgJICJgYhzWw6Y42rL1hYDIYEEZuE1zkGREStBODIBSWYGvoNXw2Ev+l9EfwK+wwq5FgIvRP9MqNsZfIdSq5m6thPX5l2e9wkmAAseczONouhVDUlF3V8iFgtR/1tx/Z+oG+iAqYg8ifoeesDoRKxaEQnDzZ1Vq0vFMLlnXsE3WBJLsYmvdU6vzqJ5AUWCsG4YK32JAV9A6qRIJA1jpaM8gi+4hwSP34qxK/ABVpDfisSqZbxcDT+8uloJ2tjzlvEUmpy9Wg28Dc9bvbNSqS34ACVU0mF8LFUKfbBUVojFvXquiMfwDkTQM1h4w81v1nO3hiD2nhco4q+0ZTh5eorP0pqo4ONQG7yzntPGxvtxxiEMApZNZ2340ULGHXebisYT9An1TxIYCDxhiSITQx/AskqN4siwrI4HeA94VlxZqtkxMBJQ5MM+i84Y8mOlTsGQldgrC1E3678GsyTVcGeVXGa8DeywaVPVXN/ByJArEmFuwrbM7hluT2TGliXXf9oZ39Cd3P5jy4YqI68EsAo7zUip48isVg03LeSmEquQwEQQcueyu1rLTTM5zhMiDldqd0o+VOSdfEDKf2mSV1Olg/rAd1FfBEuEjcuFm19CXhGC83mXYImwX3NETp2sFt/ojdXC/HWdhAl1RWT2MQuLQiUis/WZegVJhI/PzrcZ+gnZarmN/kA/QRJRceHJdQZltbCcbsosFvWHuCI/RP3tIBcikZOov+XMQiRS+aKUiGRmDBs+l8AioaDD+QcYH4+iXsiDxbn3wK7fqEJ97jS25XskzjhS5oRlqfwqImIh6AhrWp5zseUrba4aK5dCEVYPgTmUPx6gjZ64RpbEZjLU+VjzEEoab5yFwCKvG0PHF/o949nKsoK+gPlZfo3lRMBQIJU6YE2S5D/iNRDHqdhCtAAAAABJRU5ErkJggg=="
                            alt="thumb"
                          >
                        </template>

                      </span>
                    </template>
                    <template v-if="surveyQuestion.options.shape === 'number'">
                      <div class="d-block">
                        <span class="rating-number-box">
                          <span
                            v-for="(notusedindex, nindex) in Number(surveyQuestion.options.step) + 1"
                            :key="`mum${nindex}`"
                            class="number-cell"
                          >
                            {{ nindex }}
                          </span>
                        </span>
                        <div class="rating-number-info">
                          <span>{{ surveyQuestion.options.min_value }}</span>
                          <span>{{ surveyQuestion.options.max_value }}</span>
                        </div>
                      </div>
                    </template>
                  </div>
                  <div class="survey-preview--button">
                    <button
                      type="button"
                      class="button button--secondary size-sm"
                      @click="previewQuestionNext(index)"
                    >
                      {{ $t('next') }}
                    </button>
                  </div>
                </div>
                <div class="survey-preview--progress">
                  <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
                  <span>{{ (surveyQuestions) ? parseInt(((100 / surveyQuestions.length) * (index + 1) ), 10) + '% ' + $t('completed_') : '' }}</span>
                  <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
                  <div class="survey-progress-bar">
                    <span :style="{ width: (surveyQuestions) ? parseInt(((100 / surveyQuestions.length) * (index + 1) ), 10) + '%':'100%' }" />
                  </div>
                </div>
              </div>
              <div class="survey-preview--question">
                <div class="survey-finalpage">
                  <div
                    class="d-flex justify-content-center text-center mt-4"
                  >
                    {{ $t('thanks_feedback') }}
                  </div>
                  <div class="survey-preview--button">
                    <button
                      type="button"
                      class="button button--secondary size-sm"
                      @click="previewQuestionNext(-1)"
                    >
                      {{ $t('close') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="contentCreateValue === 'request' && requestQuestions && requestQuestions.length > 0"
            class="mobile-content__request"
          >
            <div class="request-preview--container">
              <div
                class="request-preview--question"
                v-for="(requestQuestion, index) in requestQuestions"
                :key="index"
              >
                <div class="question-content">
                  <h5>{{ `${(index+1)}. `+ requestQuestion.question }}</h5>
                  <p class="subfont ml-3">
                    {{ requestQuestion.sub_text }}
                  </p>
                  <div
                    v-if="requestQuestion.type === 'get_info'"
                    class="request-info-input ml-2"
                  >
                    <span>{{ convertFormat(requestQuestion.options.format) }}</span>
                  </div>
                  <div
                    v-if="requestQuestion.type === 'image' || requestQuestion.type === 'file'"
                    class="imagefile-box"
                  >
                    <span
                      v-html="'+'"
                    />
                  </div>
                  <div class="request-preview--button">
                    <button
                      type="button"
                      class="button button--secondary size-sm"
                      @click="previewQuestionNext(index)"
                    >
                      {{ $t('next') }}
                    </button>
                  </div>
                </div>
                <div class="request-preview--progress">
                  <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
                  <span>{{ (requestQuestions) ? parseInt(((100 / requestQuestions.length) * (index + 1) ), 10) + '% ' + $t('completed_') : '' }}</span>
                  <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
                  <div class="request-progress-bar">
                    <span :style="{ width: (requestQuestions) ? parseInt(((100 / requestQuestions.length) * (index + 1) ), 10) + '%':'100%' }" />
                  </div>
                </div>
              </div>
              <div class="request-preview--question">
                <div class="request-finalpage">
                  <div
                    class="d-flex justify-content-center text-center mt-4"
                  >
                    {{ $t('thanks_feedback_info') }}
                  </div>
                  <div class="request-preview--button">
                    <button
                      type="button"
                      class="button button--secondary size-sm"
                      @click="previewQuestionNext(-1)"
                    >
                      {{ $t('close') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="mobile-context"
            v-if="contentCreateValue !== 'podcast'
              && contentCreateValue !== 'file_download'
              && contentCreateValue !== 'checklist'
              && contentCreateValue !== 'chapter'
              && contentCreateValue !== 'flipbook'
            "
            :style="showVideo ? 'z-index: -2': ''"
          >
            <div
              class="mobile-content__media"
              v-show="!showVideo"
              v-if="thumbnailPreview && contentCreateValue !== 'infographics' && contentCreateValue !== 'podcast' && contentCreateValue !== 'flipbook' && contentCreateValue !== 'notification' && contentCreateValue !== 'chapter'"
              :style="{'background-image': 'url(' + thumbnailPreview + ')'}"
            >
              <div
                v-if="videoPreview"
                class="videocontrols"
              >
                <button @click="play">
                  <icon-container
                    name="playfull"
                    class="pt-3 pl-3"
                    view-box="0 0 538 615"
                    color="rgba(255, 255, 255, 0.5)"
                    :is-icon-class="false"
                  >
                    <icon-playfull />
                  </icon-container>
                </button>
              </div>
              <small
                v-if="contentCreateValue !== 'infographics' && contentCreateValue !== 'flipbook' && !is_info_labels_hidden"
                class="mobile-content__info"
                v-show="!showVideo"
              >
                {{ info }}
              </small>
              <button
                v-if="contentCreateValue === 'external-link' || contentCreateValue === 'link' || contentCreateValue === 'pdf_upload'"
                class="mobile-content__externallink"
                @click="openExternalLink()"
              >
                {{ $t('open_button') }}
              </button>
            </div>
            <small
              class="mobile-content__type"
              v-show="flipImages.length === 0"
            ><!-- eslint-disable-next-line vue-i18n/no-raw-text -->
              {{ $helpers.getSubTypeName(contentCreateValue) }}
            </small>
            <h2
              class="mobile-content__title"
              v-show="!showVideo"
              v-if="contentCreateValue !== 'infographics'
                && contentCreateValue !== 'flipbook'
                && contentCreateValue !== 'chapter'
                && !is_info_labels_hidden
              "
            >
              {{ name }}
            </h2>
          </div>
          <div
            v-show="!showVideo"
            class="mobile-content__main"
            :style="showVideo ? 'z-index: -2': ''"
            v-if="contentCreateValue !== 'infographics'
              && contentCreateValue !== 'article'
              && contentCreateValue !== 'podcast'
              && contentCreateValue !== 'chapter'
              && contentCreateValue !== 'checklist'
              && contentCreateValue !== 'file_download'
              && contentCreateValue !== 'flipbook'
              && contentCreateValue !== 'scorm'
            "
          >
            <p>{{ desc }}</p>
          </div>
          <div
            class="mobile-content__main"
            v-if="contentCreateValue === 'article'"
          >
            <iframe
              v-if="injectedArticleHtml"
              title="article"
              id="articleIframe"
              width="270"
              :srcdoc="injectedArticleHtml"
              v-resize
            />
          </div>
          <div
            class="chapter-content-preview"
            v-if="contentCreateValue === 'chapter'"
          >
            <div class="chapter-content-preview__title">
              {{ name }} {{ chapterIndex === 1 ? '🏁':'🚀' }}
            </div>
            <div class="chapter-content-preview__description">
              {{ desc }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import iFrameResize from 'iframe-resizer/js/iframeResizer';
import validDataUrl from 'valid-data-url';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/en';
import 'vue2-datepicker/locale/tr';
import 'vue2-datepicker/index.css';
import VEmojiPicker from 'v-emoji-picker';
import packData from 'v-emoji-picker/data/emojis';
import { directive as onClickaway } from 'vue-clickaway';
import vueTagsInput from '@johmun/vue-tags-input';
import CollapseTransition from '@ivanv/vue-collapse-transition';
import { VueAgile } from 'vue-agile';
import { mapGetters } from 'vuex';
import API from '@/services/';
import {
  API_GET_CONTENT_INFO,
  CORS_PROXY_URL,
  API_SERVER_TIME_FORMAT,
  API_GET_CUSTOMER_LIST_LIGHT,
} from '@/helpers/config';
import QuizComponent from '@/components/QuizComponent.vue';
import VideoContent from '@/container/modal/createContent/Video.vue';
import PodcastContent from '@/container/modal/createContent/Podcast.vue';
import InfographicsContent from '@/container/modal/createContent/Infographics.vue';
import ArticleContent from '@/container/modal/createContent/Article.vue';
import ExternalLinkContent from '@/container/modal/createContent/ExternalLink.vue';
import ExternalYouTubeLinkContent from '@/container/modal/createContent/YoutubeVideos.vue';
import FlipbookContent from '@/container/modal/createContent/Flipbook.vue';
import FlipBlock from '@/components/FlipBlock.vue';
import QuizContent from '@/container/modal/createContent/Quiz.vue';
import ScormContent from '@/container/modal/createContent/Scorm.vue';
import SurveyContent from '@/container/modal/createContent/Survey.vue';
import RequestContent from '@/container/modal/createContent/Request.vue';
import ChecklistContent from '@/container/modal/createContent/Checklist.vue';
import NotificationContent from '@/container/modal/createContent/Notification.vue';
import ChapterContent from '@/container/modal/createContent/Chapter.vue';
import FileDownloadContent from '@/container/modal/createContent/FileDownload.vue';
import PdfuploadContent from '@/container/modal/createContent/PdfUpload.vue';
import ThumbnailContainer from '@/components/Thumbnail.vue';
import IconContainer from '@/components/elements/Icon.vue';
import IconTime from '@/components/icons/Time.vue';
import IconVideo from '@/components/icons/Video.vue';
import IconCogs from '@/components/icons/Cogs.vue';
import IconShare from '@/components/icons/Share.vue';
import IconContentToggle from '@/components/icons/ContentToggle.vue';
import IconCopy from '@/components/icons/Copy.vue';
import IconFile from '@/components/icons/File.vue';
import IconTasks from '@/components/icons/Tasks.vue';
import IconLiveEvent from '@/components/icons/LiveEvent.vue';
import IconFlag from '@/components/icons/MobilePreviewFlag.vue';
import IconPodcast from '@/components/icons/Podcast.vue';
import IconPlayFull from '@/components/icons/PlayFull.vue';
import IconScorm from '@/components/icons/Scorm.vue';
import IconQuestionCircle from '@/components/icons/QuestionCircle.vue';
import IconChapter from '@/components/icons/Chapter.vue';
import IconDownload from '@/components/icons/Download.vue';
import IconRequest from '@/components/icons/Request.vue';
import IconPdf from '@/components/icons/Pdf.vue';
import SvgIcon from '@jamescoyle/vue-icon';
import myTableMixin from '@/helpers/myTableMixin.vue';

import {
  mdiYoutubeTv,
} from '@mdi/js';

/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
Vue.directive('resize', {
  inserted(el) {
    iFrameResize({
      checkOrigin: false,
      log: false,
    }, `#${el.id}`);
  },
});

export default {
  name: 'ModalV2',
  inject: ['$validator'],
  mixins: [myTableMixin],
  directives: {
    'on-clickaway': onClickaway,
  },
  data() {
    return {
      isJourneyTypeV2: true,
      beginActivationDateTime: new Date(),
      beginActivationDateTimeOldValue: null,
      formSending: false,
      snotifyQueueList: [],
      pageIsLoading: false,
      displayquestions: [],
      questionId: [],
      questions: null,
      isPending: false,
      checklistItems: null,
      fileDownloadItems: null,
      pdfFile: null,
      isValidator: true,
      isWeeklyEntry: false,
      formIsCompleted: true,
      content_id: null,
      imageRatio: null,
      customer_id: this.$store.state.auth.customerId,
      info: ' ',
      content_provider: null,
      duration: null,
      subtype_id: 1,
      name: null,
      desc: null,
      file: null,
      aoptions: '',
      thumbnail_filename: '',
      videoDuration: null,
      classDuration: 30,
      numRemoteClass: 1,
      is_retake_enabled: 0,
      noficationId: null,
      notification_journeyId: null,
      notification_title: null,
      notification_body: null,
      notification_journey_item_id: null,
      thumbnail: null,
      thumbnailPreview: null,
      infographPreview: null,
      videoPreview: null,
      podcastPreview: null,
      showVideo: false,
      videoType: 'videoFile',
      showControl: false,
      videoElement: null,
      podcastElement: null,
      paused: null,
      emojiPack: packData,
      selectCustomer: null,
      customerOptions: [],
      dropdownIndex: null,
      tag: '',
      tags: '',
      tagsTemp: [],
      contentCreateType: '1',
      contentCreateValue: 'video',
      flipbookPreviewIds: [],
      flipImages: [],
      dirtyList: [],
      externalUrl: null,
      articleHtml: null,
      injectedArticleHtml: '',
      is_push_enabled: 1,
      is_email_enabled: 1,
      push_notification_title: this.$t('default_content_push_noti_head_msg'),
      push_notification_body: this.$t('default_content_push_noti_body_msg'),
      is_reminder_enabled: 1,
      is_static: 0,
      is_public: 0,
      hide_from_lxp: 1,
      is_file_upload_required: 1,
      showHideFromLxp: false,
      is_info_labels_hidden: 0,
      previewKnowledgeStep: 0,
      surveyQuestions: null,
      requestQuestions: null,
      canEditOnlyItem: null,
      updateInfo: false,
      package_zip: null,
      indexFilePath: '',
      starting_date: null,
      streamer_user_id: null,
      podcastDuration: null,
      podcastDurationInfo: null,
      currentPodcastProgressBar: 0,
      currentPodcastTime: 0,
      podcastIsPlaying: false,
      checkingCurrentPositionInTrack: '',
      day: 1,
      roleType: null,
      // roleType: {
      //   value: 3,
      //   label: 'USER',
      // },
      roleTypeOptions: [
        {
          value: 3,
          label: 'USER',
        },
        {
          value: 0,
          label: 'HRBP',
        },
        {
          value: 1,
          label: 'BUDDY',
        },
        {
          value: 2,
          label: 'MANAGER',
        },
        {
          value: 4,
          label: 'OTHER',
        },
      ],
      quizType: 'standard',
      surveyContentType: 'survey',
      repetitiveMaxQuestionPerDay: 3,
      repetitiveCorrectAnswerCount: 2,
      is_show_correct_answers_enabled: 1,
      is_show_score: 0,
      is_randomize_choices_enabled: 0,
      is_randomize_questions_enabled: 0,
      max_questions_count: 0,
      chapterIndex: 1,
      chapterId: null,
      contentLanguageOptions: [
        {
          name: 'en',
          value: 'English',
        },
        {
          name: 'es',
          value: 'Español',
        },
        {
          name: 'fr',
          value: 'Français',
        },
        {
          name: 'tr',
          value: 'Türkçe',
        },
      ],
      contentLanguage: null,
      as_journey2_content_id: null,
      // isAddingQuestion: 0,
      hasPreDefinedSubQuiz: false,
      slideOptions: {
        settings: {
          navButtons: true,
          dots: true,
          infinite: true,
        },
      },
    };
  },

  watch: {
    $data: {
      handler(value) {
        const that = value;
        if (that.contentCreateValue === 'video') {
          if (that.thumbnail && that.file) {
            that.formIsCompleted = false;
          } else {
            that.formIsCompleted = true;
          }
        }
      },
      deep: true,
    },
    hasPreDefinedSubQuiz: {
      handler(val) {
        console.log('hasPreDefinedSubQuiz watch:', val);
      },
    },
    errors: {
      handler(val) {
        const that = val;
        if (val.items === 0) {
          that.isValidator = false;
          that.formIsCompleted = false;
        } else {
          that.isValidator = true;
          that.formIsCompleted = true;
        }
      },
      deep: true,
    },
    totalChapterNumber: {
      handler(val) {
        if (!this.editMode) {
          if (val) {
            this.chapterIndex = val + 1;
          } else {
            this.chapterIndex = 1;
          }
        }
      },
      immediate: true,
    },
    tags: {
      handler(newVal) {
        if (newVal) {
          this.tagsTemp = newVal.split(',').map(k => ({ text: k }));
        }
      },
      immediate: true,
    },
    currentPodcastTime() {
      this.currentPodcastTime = Math.round(this.currentPodcastTime);
    },
    podcastPreview: 'podcastInit',

    articleHtml() {
      this.injectedArticleHtml = this.iframeInjector(this.articleHtml);
    },

    selectCustomer(newval) {
      if (this.isVibonsAdmin && newval && newval.id) {
        this.customer_id = newval.id;
      }
    },

    contentCreateValue: {
      handler(newValue) {
        if (newValue) {
          // reset variables when content type is changed
          if (!this.editMode) {
            if (this.contentLanguage) {
              this.setDescription(this.contentLanguage.name);
            }
            this.setDefaultThumbnail();
            this.$validator.errors.clear();
            this.name = null;
            this.info = null;
            this.file = null;
            this.content_provider = null;
            this.infographPreview = null;
            this.videoPreview = null;
            this.podcastPreview = null;
            this.showVideo = false;
            this.flipImages = [];
          }
        }
      },
      immediate: true,
      deep: true,
    },

    customerOptions(newValue) {
      if (!this.editMode && newValue) {
        this.selectCustomer = newValue.find(x => x.id === this.currentCustomerId);
      }
    },
    contentLanguage(newValue) {
      if (!this.editMode && newValue) {
        this.push_notification_title = this.$t('default_content_push_noti_head_msg', newValue.name);
        this.push_notification_body = this.$t('default_content_push_noti_body_msg', newValue.name);
        this.setDescription(newValue.name);
      }
    },

    autoGeneratedInfo(newValue) {
      if (newValue && (this.updateInfo || this.contentCreateValue !== 'external-link') && !this.editMode) {
        this.info = newValue;
      }
    },
    beginActivationDateTime: {
      handler(newValue) {
        this.beginActivationDateTimeOldValue = newValue;
      },
      immediate: true,
    },
    flipImages: {
      handler(newValue) {
        this.flipbookPreviewIds = newValue.map(x => x.id);
      },
      immediate: true,
      deep: true,
    },
  },

  components: {
    'video-content': VideoContent,
    'podcast-content': PodcastContent,
    'infographics-content': InfographicsContent,
    'article-content': ArticleContent,
    'external-link-content': ExternalLinkContent,
    'flipbook-content': FlipbookContent,
    'flip-book': FlipBlock,
    'quiz-content': QuizContent,
    'scorm-content': ScormContent,
    'quiz-component': QuizComponent,
    'survey-content': SurveyContent,
    'request-content': RequestContent,
    'checklist-content': ChecklistContent,
    'notification-content': NotificationContent,
    'date-picker': DatePicker,
    'thumbnail-container': ThumbnailContainer,
    'multi-select': Multiselect,
    VEmojiPicker,
    VueAgile,
    'icon-container': IconContainer,
    'icon-time': IconTime,
    'icon-video': IconVideo,
    'icon-pdf': IconPdf,
    'icon-cogs': IconCogs,
    'icon-share': IconShare,
    'icon-contenttoggle': IconContentToggle,
    'icon-copy': IconCopy,
    'icon-file': IconFile,
    'icon-tasks': IconTasks,
    'icon-podcast': IconPodcast,
    'icon-scorm': IconScorm,
    // eslint-disable-next-line vue/no-unused-components
    'icon-liveevent': IconLiveEvent,
    'icon-flag': IconFlag,
    'icon-playfull': IconPlayFull,
    'icon-questioncircle': IconQuestionCircle,
    VueTagsInput: vueTagsInput,
    'collapse-transition': CollapseTransition,
    'icon-chapter': IconChapter,
    'chapter-content': ChapterContent,
    'icon-download': IconDownload,
    'icon-request': IconRequest,
    'filedownload-content': FileDownloadContent,
    'pdfupload-content': PdfuploadContent,
    'external-youtube-link-content': ExternalYouTubeLinkContent,
    SvgIcon,
  },

  filters: {
    uppercase(value) {
      return value.toUpperCase();
    },
    timeFormat(s) {
      return (s - (s %= 60)) / 60 + (s > 9 ? ':' : ':0') + s;
    },
  },

  async created() {
    if (!this.editMode) {
      this.beginActivationDateTime.setHours(8, 0, 0);
    }
    if (this.isVibonsAdmin || (this.$store.state.auth.userInfo && this.$store.state.auth.userInfo.role === 'customer-admin' && this.$store.state.auth.userInfo.customer && this.$store.state.auth.userInfo.customer.services && this.$store.state.auth.userInfo.customer.services.length && this.$store.state.auth.userInfo.customer.services.includes('LXP'))) {
      this.showHideFromLxp = true;
    }
    if (this.isVibonsAdmin) {
      if (this.customerListFromCache && this.customerListFromCache.length === 0) {
        const formData = { params: {} };
        formData.params.per_page = 1000;
        await API.get(API_GET_CUSTOMER_LIST_LIGHT, formData).then((response) => {
          this.customerOptions = response.data;
          this.$store.commit('auth/set_customer_list', response.data);
        });
      } else {
        this.customerOptions = [...this.customerListFromCache];
      }
      if (this.userCustomerId) {
        this.selectCustomer = this.customerOptions.find(x => x.id === this.userCustomerId);
      }
    }
  },

  computed: {
    ...mapGetters({
      totalChapterNumber: 'modal/getTotalChapterNumber',
    }),

    isStatic() {
      return this.$store.state.modal.isStatic;
    },

    isInfoLabelsHidden() {
      return this.$store.state.modal.isInfoLabelsHidden;
    },

    fields() {
      return [
        {
          name: 'id',
          title: this.$t('journey_id'),
          width: '15%',
        },
        {
          name: 'name',
          title: this.$t('name'),
          width: '30%',
        },
        {
          name: 'order',
          title: this.$t('order'),
          width: '10%',
        },
        {
          name: 'activation_dt',
          title: this.$t('activation_date'),
          width: '30%',
          titleClass: 'center',
          dataClass: 'center',
        },
        {
          name: 'actions',
          title: this.$t('actions'),
          width: '10%',
        },
      ];
    },


    editMode() {
      return this.$store.state.modal.editMode;
    },

    customerListFromCache() {
      return this.$store.state.auth.customerList;
    },

    isOnlyDatesEditable: {
      get() {
        return this.$store.state.modal.isOnlyDatesEditable;
      },
      set(val) {
        this.$store.state.modal.isOnlyDatesEditable = val;
      },
    },

    currentCustomerId() {
      return this.$store.state.auth.customerId;
    },

    journeyDate() {
      if (this.$store.state.modal.journeyDetail && this.$store.state.modal.journeyDetail.activation_date) {
        return this.$store.state.modal.journeyDetail.activation_date;
      }
      return null;
    },


    autoGeneratedInfo() {
      let infoMsg = null;
      let lang = 'en';
      if (this.contentLanguage) {
        lang = this.contentLanguage.name;
      }
      switch (this.contentCreateValue) {
        case 'video':
          if (this.videoDuration) {
            infoMsg = this.$t('min_watch', lang, { min: this.videoDuration });
          }
          break;
        case 'podcast':
          if (this.podcastDurationInfo) {
            infoMsg = this.$t('min_listen', lang, { min: this.podcastDurationInfo });
          }
          break;
        case 'infographics':
          if (this.file) {
            const self = this;
            const url = URL.createObjectURL(this.file);
            const img = new Image();
            img.onload = () => {
              self.imageRatio = Math.ceil((img.height / img.width) * 4);
            };
            // img.onerror = (e) => {
            //   console.log('infograph', e);
            //   img.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAAHCAYAAADUITPXAAAANUlEQVR42u3WMREAAAgEIE1u9LeEkwcp6KSmAAA404IFACBYAACCBQAgWAAACBYAgGABADy1vfIRc2onHKsAAAAASUVORK5CYII=';
            // };
            img.src = url;
            if (self.imageRatio === 0) {
              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
              self.imageRatio = 1;
            }
            infoMsg = self.$t('min_read', lang, { min: self.imageRatio });
          }
          break;
        case 'flipbook':
          if (this.flipImages) {
            let duration = null;
            if (this.flipImages.length > 1) {
              duration = Math.ceil(this.flipImages.length / 5);
            } else {
              duration = 1;
            }
            infoMsg = this.$t('min_read', lang, { min: duration });
          }
          break;
        case 'external-link':
          if (this.externalUrl && (this.updateInfo || !this.editMode)) {
            let source = null;
            const externalUrl = this.$helpers.addhttp(this.externalUrl);
            const regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
            if (regexp.test(externalUrl)) {
              source = new URL(externalUrl).hostname;
              [source] = source.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/');
              if (source) {
                if (source.length > 30) { // trim subdomain
                  source = source.split('//').slice(-1)[0].split(':')[0].split('.').slice(-2).join('.');
                }
                if (source.length > 30) { // if still more than 30 chars then trim domain extension
                  [source] = source.split('.');
                }
                if (source.length >= 23) {
                  infoMsg = source;
                } else {
                  infoMsg = this.$t('source', lang, { domain: source });
                }
              }
            }
          }
          break;
        case 'survey':
          if (this.surveyQuestions) {
            infoMsg = `${this.surveyQuestions.length} ${this.$t('questions', lang)}`;
          }
          break;
        case 'request':
          if (this.requestQuestions) {
            infoMsg = `${this.requestQuestions.length} ${this.$t('questions', lang)}`;
          }
          break;
        case 'quiz':
          if (this.questions) {
            infoMsg = `${this.questions.length} ${this.$t('questions', lang)}`;
          }
          break;
        case 'checklist':
          if (this.checklistItems) {
            infoMsg = `${this.checklistItems.length} ${this.$t('items', lang)}`;
          }
          break;
        case 'article':
          if (this.injectedArticleHtml) {
            let duration = Math.floor(this.injectedArticleHtml.length / 3000);
            if (duration === 0) {
              duration = 1;
            }
            infoMsg = this.$t('min_read', lang, { min: duration });
          }
          break;
        default:
          break;
      }
      return infoMsg;
    },

    notification_preview_time() {
      return this.$helpers.getUserLangBasedDateTimeFormat(this.beginActivationDateTime, this.journeyDetail.lang, 'time');
    },

    notification_preview_date() {
      return this.$helpers.getUserLangBasedDateTimeFormat(this.beginActivationDateTime, this.journeyDetail.lang, 'snotif');
    },

    multiProcessProgressBar: {
      get() {
        return this.$store.state.modal.multiProcessProgressBar;
      },
      set(newValue) {
        this.$store.state.modal.multiProcessProgressBar = newValue;
      },
    },

    userLang() {
      if (this.$store.state.auth.userInfo) {
        return this.$store.state.auth.userInfo.lang;
      }
      return 'en';
    },

    contentCustomerId() {
      return this.$store.state.modal?.modalData?.content?.customer_id;
    },

    userCustomerId() {
      return this.$store.state.auth.customerId;
    },

    canNotEdit() {
      if (!this.editMode || this.isVibonsAdmin) {
        return false;
      }

      if (this.$store.state.modal.launchedFrom === 'Contents') {
        if (this.$store.state.auth.userInfo.role === 'customer-admin') {
          if (this.contentCustomerId === this.userCustomerId) {
            return false;
          }
          return true;
        }
      } else {
        if (this.journeyDetail && this.journeyDetail.is_public === 0 && this.modalData && !this.modalData?.content?.is_public) {
          return false;
        }
        if (this.journeyDetail && this.journeyDetail.is_public && this.isVibonsAdmin) {
          return false;
        }
        if (this.journeyDetail && this.journeyDetail.is_public === 0 && this.modalData && this.modalData?.content?.is_public) {
          if (this.isVibonsAdmin) {
            return false;
          }
          this.onlyEditItem(true);
          return true;
        }
      }

      this.onlyEditItem(false);
      return true;
    },

    isVibonsAdmin() {
      return this.$store.state.auth.isVibonsAdmin;
    },

    sNotification: {
      get() {
        return this.$store.state.modal.sNotification;
      },
      set(newValue) {
        this.$store.state.modal.sNotification = newValue;
      },
    },

    playing() {
      return !this.paused;
    },

    tabPane: {
      get() {
        return this.$store.state.modal.tabPane;
      },
      set(newValue) {
        this.$store.state.modal.tabPane = newValue;
      },
    },

    journeyHaveUsers() {
      if (this.journeyDetail && this.journeyDetail.assigned_users_count > 0) {
        return true;
      }
      return false;
    },

    journeyDetail() {
      return this.$store.state.modal.journeyDetail;
    },
    journeyItemV2Detail() {
      return this.$store.state.modal.modalItemV2;
    },
    // journeyItemDetail: {
    //   get() {
    //     return this.$store.state.modal.journeyItemDetail;
    //   },
    //   set(newValue) {
    //     this.$store.commit('modal/setJourneyItemDetail', newValue);
    //   },
    // },

    modalContentId() {
      return this.$store.state.modal.contentId;
    },

    modalData() {
      return this.$store.state.modal.modalData;
    },

    formError: {
      get() {
        return this.$store.state.modal.formError;
      },
      set(newValue) {
        this.$store.state.modal.formError = newValue;
      },
    },
  },

  deactivated() {
    this.journeyItemDetail = null;
    this.desc = null;
    this.podcastDestroy();
    console.log('item v2 modal deactivated!');
  },

  methods: {

    shuffle(myArray) {
      const array = [...myArray];
      for (let i = 0; i < array.length; i += 1) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    },
    podcastInit() {
      this.podcastElement = new Audio(this.podcastPreview);
      this.podcastElement.loop = false;
      const self = this;
      // eslint-disable-next-line func-names
      this.podcastElement.addEventListener('loadedmetadata', function () {
        self.setPodcastDuration(this.duration);
      });
      this.podcastElement.addEventListener('ended', this.handlePodcastEnd);
    },
    podcastDestroy() {
      if (this.podcastElement) {
        this.podcastElement.pause();
        this.podcastElement.removeEventListener('ended', this.handlePodcastEnd);
        this.podcastElement.removeEventListener('loadedmetadata', this.handlePodcastEnd);
        clearTimeout(this.checkingCurrentPositionInTrack);
      }
    },
    togglePodcast() {
      if (this.podcastElement) {
        if (this.podcastIsPlaying) {
          this.podcastElement.pause();
          clearTimeout(this.checkingCurrentPositionInTrack);
          this.podcastIsPlaying = false;
        } else {
          this.podcastElement.play();
          this.getCurrentTimeEverySecond(true);
          this.podcastIsPlaying = true;
        }
      }
    },
    isEmpty(value) {
      return (
      // null or undefined
        (value == null)

    // has length and it's zero
    // eslint-disable-next-line no-prototype-builtins
    || (value.hasOwnProperty('length') && value.length === 0)

    // is an Object and has no keys
    || (value.constructor === Object && Object.keys(value).length === 0)
      );
    },
    handlePodcastEnd() {
      this.podcastIsPlaying = false;
      this.checkingCurrentPositionInTrack = '';
    },
    /* eslint-disable-next-line no-unused-vars */
    getCurrentTimeEverySecond(startStop = false) {
      const self = this;
      this.checkingCurrentPositionInTrack = setTimeout(
        () => {
          self.currentPodcastTime = self.podcastElement.currentTime;
          self.currentPodcastProgressBar = (self.podcastElement.currentTime / self.podcastDuration) * 100;
          if (self.currentPodcastProgressBar > 100) {
            self.currentPodcastProgressBar = 100;
          }
          self.getCurrentTimeEverySecond(true);
        },
        1000,
      );
    },
    backwardPodcast() {
      const backTime = this.currentPodcastTime - 15;
      if (backTime > 0) {
        const perc = Math.round((backTime / this.podcastDuration) * 100);
        this.podcastSetVal(backTime, perc);
      } else {
        this.podcastSetVal(backTime, 0);
      }
    },

    forwardPodcast() {
      const forwardTime = this.currentPodcastTime + 15;
      if (forwardTime <= this.podcastDuration) {
        const perc = Math.round((forwardTime / this.podcastDuration) * 100);
        this.podcastSetVal(forwardTime, perc);
      }
    },

    podcastSeek(e) {
      if (this.podcastElement && this.$refs.progressBar) {
        const bounds = this.$refs.progressBar.getBoundingClientRect();
        const max = bounds.width;
        const pos = e.pageX - bounds.left;
        const posr = Math.round((pos / max) * 100);
        const seektime = (this.podcastDuration * posr) / 100;
        this.podcastSetVal(seektime, posr);
      }
    },
    podcastSetVal(time, percent) {
      this.podcastElement.currentTime = time;
      this.currentPodcastTime = time;
      this.currentPodcastProgressBar = percent;
    },
    adjustTime(type) {
      if (this.beginActivationDateTime !== null) {
        switch (type) {
          case 'date':
            if (this.beginActivationDateTimeOldValue) {
              this.adjustDateTimeByDate();
            } else {
              this.setDefaultTime();
            }
            break;
          case 'time':
            if (this.beginActivationDateTimeOldValue) {
              this.adjustDateTimeByTime();
            } else {
              this.setDefaultTime();
            }
            break;
          default:
            this.setDefaultTime();
            break;
        }
      }
    },

    adjustDateTimeByDate() {
      const date = moment(this.beginActivationDateTime, API_SERVER_TIME_FORMAT).format('YYYY-MM-DD');
      const time = moment(this.beginActivationDateTimeOldValue, API_SERVER_TIME_FORMAT).format('HH:mm:ss');
      this.beginActivationDateTime = moment(`${date} ${time}`, API_SERVER_TIME_FORMAT).toDate();
    },

    adjustDateTimeByTime() {
      const date = moment(this.beginActivationDateTimeOldValue, API_SERVER_TIME_FORMAT).format('YYYY-MM-DD');
      const time = moment(this.beginActivationDateTime, API_SERVER_TIME_FORMAT).format('HH:mm:ss');
      this.beginActivationDateTime = moment(`${date} ${time}`, API_SERVER_TIME_FORMAT).toDate();
    },

    setDefaultTime() {
      const newDate = new Date(this.beginActivationDateTime);
      newDate.setHours(8, 0, 0);
      this.beginActivationDateTime = newDate;
    },

    onlyEditItem(value) {
      this.canEditOnlyItem = value;
    },

    disableWeekends(date) {
      const day = new Date(date).getDay();
      return day === 0 || day === 6;
    },

    thumbnailAdded(file) {
      if (file) {
        [this.thumbnailPreview, this.thumbnail] = file;
      } else {
        this.thumbnailPreview = null;
        this.thumbnail = null;
      }
    },

    tabNavigation(value) {
      const $value = value;
      this.tabPane = $value;
    },

    setTags(val) {
      this.tagsTemp = val;
    },
    setInfo(val) {
      this.info = val;
    },
    setDesc(val) {
      this.desc = val;
    },
    setDuration(val) {
      this.duration = val;
    },
    contentCreateSelector(value) {
      const $value = value;
      if (!this.editMode && $value === 'flipbookeditable') {
        const obj = {
          modalType: 'modalFlipbookEditable',
          isJourneyTypeV2: true,
          pageToRefresh: (this.$route.path === '/contents') ? 'Contents' : 'journeyitems',
        };
        this.closeModal();
        setTimeout(() => this.$helpers.toggleModal(obj), 500); // wait for the the modal closing..
      } else {
        this.contentCreateValue = $value;
        this.$store.commit('modal/MODAL_CONTENT_TYPE', $value);
        this.thumbnail = null;
        this.thumbnailPreview = '';
      }
    },

    contentCreateMore() {
      this.contentCreateType = '2';
    },

    contentCreateBack() {
      this.contentCreateType = '1';
    },

    fileEvent(value) {
      this.file = value;
    },

    videoEvent(value) {
      this.videoPreview = value;
    },

    podcastEvent(value) {
      this.podcastPreview = value;
    },

    updatePaused(event) {
      this.videoElement = event.target;
      this.paused = event.target.paused;
    },

    play() {
      this.showVideo = true;
      const el = this.$el.getElementsByClassName('modal')[0];
      el.scrollIntoView();
      setTimeout(() => {
        if (el.scrollWidth > el.offsetWidth) {
          el.setAttribute('style', 'overflow:scroll;');
          el.scrollLeft = el.scrollWidth - el.offsetWidth;
          el.scrollTop = 0;
        }
      }, 1200);
    },

    exitVideo() {
      this.showVideo = false;
      const el = this.$el.getElementsByClassName('modal')[0];
      el.setAttribute('style', 'overflow:hidden;');
    },

    async getHtmlContent(contenturl) {
      if (contenturl) {
        const timestamp = new Date().getTime();
        const theURL = `${CORS_PROXY_URL}${contenturl}?${timestamp}`;
        const html = await fetch(theURL).then(r => r.text()).catch(() => null);
        if (html) {
          this.articleHtml = html;
        }
      }
    },

    infographEvent(value) {
      if (value) {
        [this.infographPreview, this.file] = value;
        console.log('info file val:', value);
        const canvas = document.createElement('canvas');

        const image = new Image();
        image.onload = async () => {
          const boxsize = {
            x: 0, y: 0, width: 1080, height: 1267,
          };
          const nWidth = image.naturalWidth;
          const nHeight = image.naturalHeight;
          const nRatio = nWidth / nHeight;
          console.log('Yuklenen Resim:', nWidth, nHeight, nRatio);
          const ratio = Math.max(boxsize.width / image.width, boxsize.height / image.height);
          const newWidth = Math.floor(nWidth * ratio);
          const newHeight = Math.floor(nHeight * ratio);
          console.log('Yeni width değerleri:', newWidth, newHeight);
          canvas.width = boxsize.width;
          canvas.height = boxsize.height;
          const ctx = canvas.getContext('2d');
          ctx.clearRect(0, 0, boxsize.width, boxsize.height);
          ctx.fillStyle = 'rgba(0,0,0,0)';
          ctx.fillRect(0, 0, boxsize.width, boxsize.height);
          ctx.drawImage(image, 0, 0, newWidth, newHeight);
          this.thumbnailPreview = canvas.toDataURL('image/png');
          this.thumbnail = await this.$helpers.dataURLToBlob(this.thumbnailPreview);
        };
        image.src = this.infographPreview;
      } else {
        this.infographPreview = null;
        this.file = null;
        this.thumbnailPreview = null;
        this.thumbnail = null;
      }
    },

    thumbnailEvent(value) {
      if (value) {
        [this.thumbnailPreview, this.thumbnail] = value;
      } else {
        this.thumbnailPreview = null;
        this.thumbnail = null;
      }
    },

    articleEvent(value) {
      this.articleHtml = value;
    },

    setDescription(lang) {
      let description = null;

      switch (this.contentCreateValue) {
        case 'video':
          description = this.$t('default_video_description', lang);
          break;
        case 'infographics':
          description = this.$t('infographic_description_msg', lang);
          break;
        case 'flipbook':
          description = this.$t('flipbook_description_msg', lang);
          break;
        case 'external-link':
          description = '';
          break;
        case 'survey':
          description = '';
          break;
        case 'request':
          description = '';
          break;
        case 'quiz':
          description = '';
          break;
        case 'checklist':
          description = '';
          break;
        case 'article':
          description = '';
          break;
        default:
          break;
      }
      this.desc = description;
    },

    setTitle(value) {
      this.name = value;
    },

    setVideoDuration(value) {
      if (value) {
        let minutes = Math.floor(value / 60);
        if (minutes === 0) {
          minutes = 1;
        }
        this.videoDuration = minutes;
      } else {
        this.videoDuration = null;
      }
    },

    setPodcastDuration(value) {
      if (value) {
        let minutes = Math.floor(value / 60);
        if (minutes === 0) {
          minutes = 1;
        }
        this.podcastDurationInfo = minutes;
        this.podcastDuration = Math.round(value);
      } else {
        this.podcastDuration = null;
      }
    },

    setNotificationBody(value) {
      this.notification_body = value;
    },

    setNotificationTitle(value) {
      this.notification_title = value;
    },

    setNotificationJourneyId(value) {
      this.notification_journey_item_id = value;
    },

    setQuestions(value) {
      this.questions = value;
      this.surveyQuestions = value;
    },

    setSurveyQuestions(value) {
      this.surveyQuestions = value;
    },
    setRequestQuestions(value) {
      this.requestQuestions = value;
    },
    setChecklistItems(value) {
      this.checklistItems = value;
    },
    setFiledownloadItems(value) {
      this.fileDownloadItems = value;
    },
    setPdfFile(value) {
      console.log('setPdfFile:', value);
      this.pdfFile = value;
    },
    getSubtypeId(value) {
      if (value === 'INFOGRAPHIC' || value === 'SCORM') {
        return 1;
      }
      if (value === 'EXTERNAL_VIDEO') {
        return 2;
      }
      if (value === 'INTERNAL_VIDEO' || value === 'EXTERNAL_CDN_VIDEO') {
        return 3;
      }
      if (value === 'EXTERNAL_ARTICLE') {
        return 4;
      }
      if (value === 'INTERNAL_ARTICLE') {
        return 5;
      }
      if (value === 'QUOTES') {
        return 6;
      }
      if (value === 'BOOK_SUGGESTION') {
        return 7;
      }
      if (value === 'LEARNING_PATH') {
        return 8;
      }
      if (value === 'FLIPBOOK') {
        return 9;
      }
      if (value === 'QUIZ') {
        return 10;
      }
      if (value === 'SURVEY') {
        return 11;
      }
      if (value === 'REQUEST') {
        return 41;
      }
      if (value === 'CHECKLIST') {
        return 12;
      }
      if (value === 'LINK') {
        return 36;
      }
      if (value === 'FILE_DOWNLOAD') {
        return 43;
      }
      return null;
    },
    openFileDownloadItem(item) {
      if (item && item.url && this.editMode) {
        const win = window.open(item.url, '_blank');
        win.focus();
      }
    },
    openExternalLink() {
      this.externalUrl = this.$helpers.addhttp(this.externalUrl);
      const win = window.open(this.externalUrl, '_blank');
      win.focus();
    },

    iframeInjector(htmlString) {
      const parser = new DOMParser();
      const htmlDoc = parser.parseFromString(htmlString, 'text/html');
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.src = 'https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.1.1/iframeResizer.contentWindow.min.js';
      const f = document.createElement('style');
      f.type = 'text/css';
      f.innerHTML = '* {'
                        + 'font-size: 0.93rem!important;line-height: 145%!important;'
                      + '}';
      htmlDoc.head.append(s);
      htmlDoc.body.append(s);
      htmlDoc.body.append(f);
      return htmlDoc.body.innerHTML;
    },

    // createFlipbookPreview(value) {
    //   const list = [...this.flipImages, ...value];
    //   const uniq = new Set(list.map(e => JSON.stringify(e)));
    //   const res = Array.from(uniq).map(e => JSON.parse(e));
    //   this.flipImages = res;
    //   this.pageIsLoading = false;
    // },
    onAction(action, data) {
      if (action === 'journey-detail') {
        const route = this.$router.resolve({ name: 'JourneyItems', params: { journeyId: data.journey.id } });
        window.open(route.href, '_blank');
      }
    },
    setSpinner(value) {
      this.pageIsLoading = value;
    },

    async setDefaultThumbnail() {
      this.pageIsLoading = true;
      const defaultThumbnail = await this.$helpers.getThumbnail(this.contentCreateValue);
      if (defaultThumbnail && !this.thumbnail && !this.thumbnailPreview) {
        this.thumbnailPreview = defaultThumbnail.thumbnailPreview;
        this.thumbnail = defaultThumbnail.thumbnail;
      }
      this.pageIsLoading = false;
    },
    flipThumbnail() {
      if (this.flipImages.length > 0) { // need to generate thumbnail from the first index of the image list
        const isValid = validDataUrl(this.flipImages[0].image); // check if datauri or url address
        if (isValid) {
          this.thumbnail = this.$helpers.dataURLToBlob(this.flipImages[0].image);
          this.thumbnailPreview = this.flipImages[0].image;
        } else {
          this.thumbnail = this.$helpers.getThumbnailImage(this.flipImages[0].image); // if url address, we need to get the final sorted image file object instead of url
          this.thumbnailPreview = this.flipImages[0].image;
        }
      }
    },
    flipEvent(newitems) {
      if (newitems) {
        this.flipImages = [...this.flipImages, ...newitems];
      }
      this.flipThumbnail();
    },
    flipSort() {
      if (this.flipImages.length > 0) { // need to generate thumbnail from the first index of the image list
        this.flipThumbnail();
      }
    },
    tagsEvent(value) {
      if (value) {
        this.tags = value.map(k => k.text).join(',');
      }
    },

    handlerExternalUrl(value) {
      this.externalUrl = value;
    },

    hideTitle(event) {
      if (event.target.checked) {
        this.is_info_labels_hidden = 1;
      } else {
        this.is_info_labels_hidden = 0;
      }
    },

    closeModal() {
      this.$store.commit('modal/toggleModal');
    },

    async quizCleanBeforeValidate() {
      if (this.questions) {
        await this.questions.forEach((question) => {
          question.choices.forEach((answer, aindex) => { // check empty answer box and delete before validation
            if (question.type === 'standard') {
              if (Object.prototype.hasOwnProperty.call(answer, 'image') && (answer.choice_text === '' || !answer.choice_text)) {
                if (!answer.imagePreview && question.choices.length > 1) {
                  question.choices.splice(aindex, 1);
                }
              } else if (!answer.choice_text && question.choices.length > 1) {
                question.choices.splice(aindex, 1);
              }
            } else if (!answer.choice_text && question.type === 'pairs' && question.choices.length > 4) {
              question.choices.splice(aindex, 2);
            }
          });
        });
      }
    },

    formatDate(value) {
      let fmt = null;
      let date = null;
      if (value) {
        if (this.userLang === 'en') {
          fmt = 'MM.DD.YYYY HH:mm:ss';
        }
        if (this.userLang === 'tr') {
          fmt = 'DD.MM.YYYY HH:mm:ss';
        }
        date = moment(value, API_SERVER_TIME_FORMAT).format(fmt);
      }
      return date;
    },

    isPushNotificationValid() {
      let customCheck = true;
      if (this.is_push_enabled && ((this.push_notification_title && !this.push_notification_body) || (!this.push_notification_title && this.push_notification_body))) { // check at least one question exist
        this.$validator.errors.add({
          field: 'push_notification',
          msg: this.$t('push_notification_empty'),
          scope: 'all',
        });
        customCheck = false;
      }
      return customCheck;
    },

    clearNotifMsg() {
      if (this.errors.has('all.push_notification')) {
        this.$validator.errors.clear();
      }
    },

    createContent(type) {
      console.log('vedat createContent-->', type);
      this.formSending = true;
      const self = this;
      const successMsg = this.$t('processed_success');
      if (this.isPushNotificationValid()) {
        this.$store.dispatch(`modal/${type}`, this.$data).then(() => {
          if (!self.formError) {
            self.$snotify.success(successMsg);
          } else {
            self.formError = false;
            self.formSending = false;
          }
        });
      } else {
        self.formError = true;
        self.formSending = false;
      }
    },

    removePreviewItem(value) {
      const contentId = this.flipImages[value].id;
      if (this.flipImages.length < 3) {
        this.$helpers.displayError(this.$t('flipbook_min_image_msg_after_del'));
        return;
      }
      if (!this.snotifyQueueList.includes(contentId)) {
        this.snotifyQueueList.push(contentId);
        this.$snotify.confirm(this.$t('flipbook_delete_msg_body'), this.$t('flipbook_delete_msg_title'), {
          showProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          buttons: [
            {
              text: this.$t('yes'),
              action: (toast) => {
                this.$snotify.remove(toast.id);
                const snotifyIndex = this.snotifyQueueList.indexOf(contentId);
                if (snotifyIndex > -1) {
                  this.snotifyQueueList.splice(snotifyIndex, 1);
                }
                API.delete(`${API_GET_CONTENT_INFO}/${contentId}`)
                  .then((response) => {
                    if (response.status === 200) {
                      if (this.flipImages.length > 1) {
                        this.flipImages.splice(value, 1);
                      } else {
                        this.flipImages = [];
                      }
                      if (this.editMode) {
                        const payload = this.$data;
                        payload.isAfterDeleteItem = true;
                        this.$store.dispatch('modal/UPDATE_FLIPBOOK_CONTENT', payload).then(() => {
                          payload.isAfterDeleteItem = false;
                          this.$snotify.success(response.data.message);
                        });
                      }
                    }
                  })
                  .catch((error) => {
                    this.$helpers.displayError(error);
                  });
              },
              bold: false,
            },
            {
              text: this.$t('no'),
              action: (toast) => {
                const snotifyIndex = this.snotifyQueueList.indexOf(contentId);
                if (snotifyIndex > -1) {
                  this.snotifyQueueList.splice(snotifyIndex, 1);
                }
                this.$snotify.remove(toast.id);
              },
            },
          ],
        });
      }
    },

    previewQuestionNext(index) {
      if (this.contentCreateValue === 'quiz' && this.modalData?.isAddingQuestion) {
        return;
      }
      const previewContainer = document.querySelector('.quiz-preview--container') || document.querySelector('.survey-preview--container') || document.querySelector('.request-preview--container');
      const previewQuestion = document.querySelector('.quiz-preview--question') || document.querySelector('.survey-preview--question') || document.querySelector('.request-preview--question');
      const previewQuestionsLength = document.querySelectorAll('.quiz-preview--question').length || document.querySelectorAll('.survey-preview--question').length || document.querySelectorAll('.request-preview--question').length;
      const previewQuestionWidth = previewQuestion.offsetWidth;
      const $index = index + 1;
      if (previewQuestionsLength === $index) {
        previewContainer.style.transform = 'translateX(0)';
      } else {
        previewContainer.style.transform = `translateX(-${$index * previewQuestionWidth}px)`;
      }
      this.$helpers.randomizeList();
    },

    previewKnowledgeReqNext(index) {
      this.previewKnowledgeStep = index;
      const knowledgePreviewContainer = document.querySelector('.mobile-content__knowledge');
      const knowledgePreviewQuestionWidth = document.querySelector('.knowledge-preview--container').offsetWidth;
      const $index = index + 1;
      if ($index === 0) {
        knowledgePreviewContainer.style.transform = 'translateX(0)';
      } else {
        knowledgePreviewContainer.style.transform = `translateX(-${knowledgePreviewQuestionWidth + 20}px)`;
      }
      knowledgePreviewContainer.scrollIntoView();
    },
    convertFormat(data) {
      if (!data) return '';
      return data.replaceAll('_', '-').replaceAll('#', '_');
    },
    hideDropdown() {
      this.dropdownIndex = null;
    },
    SelectEmojiForTitle(dataEmoji) {
      this.push_notification_title += dataEmoji.emoji;
    },
    SelectEmojiForBody(dataEmoji) {
      this.push_notification_body += dataEmoji.emoji;
    },
    getImgUrl(icon) {
      let iconimage = null;
      switch (icon) {
        case 'mdiYoutubeTv':
          iconimage = mdiYoutubeTv;
          break;
        default:
          break;
      }

      return iconimage;
    },
    reset() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    dropdownToggle(index) {
      this.dropdownIndex = index;
      setTimeout(() => {
        if (this.dropdownIndex && this.$refs[`dropdownBox${index}`]) {
          const elem = this.$refs[`dropdownBox${index}`];

          if (elem) {
            const isOut = this.$helpers.isOutOfScreen(elem);
            if (isOut.right) {
              elem.classList.add('emojiright');
            } else if (isOut.left) {
              elem.classList.add('left');
            } else if (isOut.bottom) {
              elem.classList.add('emojibottom');
            } else if (isOut.top) {
              elem.classList.add('top');
            }
          }
        }
      }, 50);
    },
    async checkQuizValidation() {
      let quizIsValid = false;
      let customCheck = true;

      this.questions.forEach((question) => {
        question.choices.forEach((answer, aindex) => { // check empty answer box and delete before validation
          if (question.type === 'standard') {
            if (Object.prototype.hasOwnProperty.call(answer, 'image') && (answer.choice_text === '' || !answer.choice_text)) {
              if (!answer.imagePreview && question.choices.length > 1) {
                question.choices.splice(aindex, 1);
              }
            } else if (!answer.choice_text && question.choices.length > 1) {
              question.choices.splice(aindex, 1);
            }
          } else if (!answer.choice_text && question.type === 'pairs' && question.choices.length > 4) {
            question.choices.splice(aindex, 2);
          }
        });
      });

      this.clearErrorMsg();

      if (this.questions && this.questions.length < 1) { // check at least one question exist
        this.$validator.errors.add({
          field: 'question',
          msg: this.$t('quiz_one_question'),
          scope: 'all',
        });
        customCheck = false;
      }

      this.$validator.validateAll().then((res) => {
        quizIsValid = res;
      });
      if (quizIsValid && customCheck) {
        return true;
      }
      return false;
    },

    clearErrorMsg() {
      this.$validator.errors.clear();
    },
    async setThumbnail(theImage) {
      if (theImage) {
        this.thumbnail = await this.$helpers.getThumbnailImage(theImage);
        this.thumbnailPreview = theImage;
      }
    },
    async updateOnlyDatesEditableItems() {
      this.isPending = true;
      await this.$store.dispatch('modal/UPDATE_JOURNEY_ITEM', this.$data);
      this.$store.commit('modal/REFRESH_PAGE', true);
      this.isPending = false;
      this.closeModal();
    },

  },

  beforeDestroy() {
    this.sNotification = false;
    this.isOnlyDatesEditable = false;
    this.isWeeklyEntry = false;
    this.$off();
    // this.reset();
    this.podcastDestroy();
    console.log('item v2 modal destroyed!');
  },

  mounted() {
    if (!this.editMode) {
      if (this.$store.state.modal.launchedFrom === 'journeyitemsv2') {
        this.day = this.$store.state.modal.dayNumber || 1;
        this.contentLanguage = this.contentLanguageOptions.find(x => x.name === this.userLang);
        this.push_notification_title = this.$t('default_content_push_noti_head_msg', this.journeyDetail?.options?.lang);
        this.push_notification_body = this.$t('default_content_push_noti_body_msg', this.journeyDetail?.options?.lang);
      }
      if (this.userLang && this.$store.state.modal.launchedFrom === 'Contents') {
        this.contentLanguage = this.contentLanguageOptions.find(x => x.name === this.userLang);

        this.push_notification_title = this.$t('default_content_push_noti_head_msg', this.userLang);
        this.push_notification_body = this.$t('default_content_push_noti_body_msg', this.userLang);
        this.desc = this.$t('default_video_description', this.userLang);
      }
      this.setDefaultThumbnail();
    } else {
      const $newValue = this.modalData;
      if ($newValue) {
        console.log('ındexModal.v2. modalData loaded', $newValue);
        if (this.isVibonsAdmin) {
          if (this.customerOptions) {
            this.selectCustomer = this.customerOptions.find(x => x.id === $newValue?.customer_id);
          }
        }

        if (this.editMode && $newValue.sub_type) {
          let subType;
          if ($newValue.sub_type === 'INTERNAL_VIDEO') {
            subType = 'VIDEO';
            this.videoType = 'videoFile';
          } else if ($newValue.sub_type === 'EXTERNAL_CDN_VIDEO') {
            subType = 'VIDEO';
            this.videoType = 'videoUrl';
          } else if ($newValue.sub_type === 'INTERNAL_ARTICLE') {
            subType = 'ARTICLE';
          } else if ($newValue.sub_type === 'EXTERNAL_VIDEO'
            || $newValue.sub_type === 'EXTERNAL_ARTICLE'
            || $newValue.sub_type === 'LINK'
            || $newValue.sub_type === 'BOOK_SUGGESTION'
            || $newValue.sub_type === 'LEARNING_PATH') {
            if ($newValue.options && $newValue.options.cdn_file) {
              subType = 'pdf_upload';
            } else {
              subType = 'external-link';
            }
          } else if ($newValue.sub_type === 'INFOGRAPHIC' || $newValue.sub_type === 'QUOTES') {
            subType = 'infographics';
          } else {
            subType = $newValue.sub_type;
          }

          this.contentCreateValue = subType.toLowerCase().trim();

          if (this.$store.state.modal.launchedFrom === 'journeyitemsv2') { // get these from journey item, instead of content
            this.as_journey2_content_id = this.$store.state.modal.journeyItemId;
            this.name = $newValue.name;
            this.desc = $newValue.desc;
            this.is_push_enabled = this.isEmpty(this.journeyItemV2Detail.options?.notification?.is_push_enabled) ? 1 : this.journeyItemV2Detail.options?.notification?.is_push_enabled;
            this.is_email_enabled = this.isEmpty(this.journeyItemV2Detail.options?.notification?.is_email_enabled) ? 1 : this.journeyItemV2Detail.options?.notification?.is_email_enabled;
            this.push_notification_title = this.journeyItemV2Detail.options?.notification?.title;
            this.push_notification_body = this.journeyItemV2Detail.options?.notification?.body;
            this.is_reminder_enabled = this.isEmpty(this.journeyItemV2Detail.options?.notification?.is_reminder_enabled) ? 1 : this.journeyItemV2Detail.options?.notification?.is_reminder_enabled;
            this.day = this.journeyItemV2Detail?.days;
            this.roleType = this.roleTypeOptions.find(x => x.value === this.journeyItemV2Detail?.role);
            this.beginActivationDateTime = moment(`${moment().format('YYYY-MM-DD')} ${this.journeyItemV2Detail.activation_time}`, API_SERVER_TIME_FORMAT).toDate();
            this.is_static = $newValue.content?.is_static;
            this.is_info_labels_hidden = $newValue.content?.is_info_labels_hidden;
          }
          if (this.$store.state.modal.launchedFrom === 'Contents') { // if launched from contents' edit button then get values from contents
            this.name = $newValue.name;
            this.desc = $newValue.desc;
            this.is_push_enabled = $newValue.is_notifications_enabled || 1;
            this.is_email_enabled = $newValue.is_email_enabled;
            this.push_notification_title = $newValue.push_notification_title;
            this.push_notification_body = $newValue.push_notification_body;
            this.is_reminder_enabled = $newValue.is_reminder_enabled;
            this.is_static = $newValue.is_static;
            this.is_info_labels_hidden = $newValue.is_info_labels_hidden;
          }

          if ($newValue.sub_type === 'CHAPTER') {
            this.name = $newValue.name;
            this.desc = $newValue.desc;
            this.chapterIndex = $newValue.chapter_index;
            this.chapterId = $newValue.id;
          }

          this.contentLanguage = this.contentLanguageOptions.find(x => x.name === $newValue.lang);
          this.tags = $newValue.content_tags;
          this.thumbnailPreview = $newValue.thumbnail_image;

          if ($newValue.content_url && ($newValue.content_url.lastIndexOf('.mp4') > -1 || $newValue.content_url.lastIndexOf('.m4v') > -1)) {
            this.videoPreview = $newValue.content_url;
          }
          if ($newValue.content_url && ($newValue.content_url.lastIndexOf('.mp3') > -1 || $newValue.content_url.lastIndexOf('.wav') > -1)) {
            this.podcastPreview = $newValue.content_url;
          }
          if ($newValue.content_url && $newValue.content_url.lastIndexOf('.html') > -1) {
            this.getHtmlContent($newValue.content_url);
          }
          if ($newValue.options && $newValue.options.is_scorm_content) {
            subType = 'scorm';
            if ($newValue.external_url) {
              // eslint-disable-next-line prefer-destructuring
              this.indexFilePath = $newValue.external_url.split('?package_url=')[1].split('/package/')[1];
              console.log('indexFilePath:', this.indexFilePath);
            }
          }
          this.externalUrl = $newValue.external_url;
          this.infographPreview = $newValue.content_url;
          this.is_public = $newValue.is_public;
          this.hide_from_lxp = $newValue.hide_from_lxp;
          this.is_file_upload_required = $newValue.request?.is_file_upload_required;
          this.customer_id = $newValue.customer_id;
          this.aoptions = JSON.stringify($newValue.options);
          this.content_id = $newValue.id;
          this.subtype_id = this.getSubtypeId($newValue.sub_type);

          if ($newValue.duration) {
            this.duration = $newValue.duration;
          }

          if ($newValue.info) {
            this.info = $newValue.info;
          }
          if ($newValue.content_provider) {
            this.content_provider = $newValue.content_provider;
          }

          if ($newValue.flipboard_items && $newValue.flipboard_items.length) {
            $newValue.flipboard_items.forEach((flip) => {
              if (flip) {
                this.flipImages.push({ id: flip.id, image: flip.content_url, name: flip.content_url.substring(flip.content_url.lastIndexOf('/') + 1) });
              }
            });
          }

          if ($newValue.quiz && $newValue.quiz?.questions) {
            this.questions = $newValue.quiz.questions;
            this.displayquestions = this.dash.cloneDeep(this.questions).map((q) => {
              const $q = q;
              if ($q.type === 'pairs') {
                $q.choices = this.shuffle($q.choices);
              }
              return $q;
            });
          }
          if ($newValue.checklist && $newValue.checklist?.items) {
            this.checklistItems = $newValue.checklist.items;
          }
          if ($newValue.survey && $newValue.survey?.questions) {
            this.surveyQuestions = $newValue.survey.questions;
            this.surveyContentType = $newValue.is_nps ? 'nps' : 'survey';
          }
          if ($newValue.request && $newValue.request?.questions) {
            this.requestQuestions = $newValue.request?.questions;
          }

          this.pageIsLoading = false;
          this.multiProcessProgressBar = false;
        }

        if ($newValue.quiz) {
          this.max_questions_count = $newValue.quiz.max_questions_count;
          this.quizType = $newValue.quiz.quiz_type;
          if ($newValue.quiz.settings) {
            this.is_retake_enabled = $newValue.quiz.settings.is_retake_enabled || 0;
            this.is_randomize_questions_enabled = $newValue.quiz.settings.is_randomize_questions_enabled || 0;
            this.is_randomize_choices_enabled = $newValue.quiz.settings.is_randomize_choices_enabled || 0;
            this.is_show_correct_answers_enabled = $newValue.quiz.settings.is_show_correct_answers_enabled || 0;
            this.is_show_correct_answers_enabled = $newValue.quiz.settings.is_show_correct_answers_enabled || 0;
            this.is_show_score = $newValue.quiz.settings.is_show_score || 0;
          }
          this.repetitiveMaxQuestionPerDay = $newValue.quiz.repetitive_max_question_per_day || 3;
          this.repetitiveCorrectAnswerCount = $newValue.quiz.repetitive_correct_answer_count || 2;
        }
        console.log('this.contentCreateValue:', this.contentCreateValue);
      }
    }

    // this.$root.$on('setIsAddQuestion', (val) => {
    //   console.log('DİNLEME setIsAddQuestion:', val);
    //   this.modalData.isAddingQuestion = val;
    // });
    this.$root.$on('updateDirtyList', (val) => {
      this.dirtyList = val;
    });
    this.$root.$on('setHasPreDefinedSubQuizV2', (val) => {
      console.log('*****ROOT listener------- hasPreDefinedSubQuiz V2: root listener updated:**********', val);
      this.hasPreDefinedSubQuiz = val;
    });
    this.$root.$on('setQuestions', (val) => {
      this.questions = val;
      this.displayquestions = this.dash.cloneDeep(this.questions).map((q) => {
        const $q = q;
        if ($q.type === 'pairs') {
          $q.choices = this.shuffle($q.choices);
        }
        return $q;
      });
    });
    this.$root.$on('checkQuizValidation', () => this.checkQuizValidation());
  },
};
</script>
<style lang="scss" scoped>
video {
  // position: absolute;
  transform: rotate(90deg);
  margin: 142px 0px 0px -142px;
  width: 536px;
  height: 252px;
  object-fit: cover;
  object-position: 50% 50%;
  border-radius: 25px;
 }
.videocontent {
  position: absolute;
  width: inherit;
  height: inherit;
  top:0;
  left:0;
  z-index: -1;
}

.videocontrols {
  position: absolute;
  top: calc(50% - 1em);
  left: calc(50% - 1em);
  background-color: rgba(0,0,0,.45);
  border-radius: 50%;
  font-size: 3em;
  height: 2em!important;
  width: 2em!important;
  line-height: 2em!important;
  text-align: center;
  z-index: 10;
}

.videocontrols button svg {
  position: absolute;
  width: 2em;
  height: 2em;
  top: calc(50% - 1em);
  left: calc(50% - 0.9em);
}
.videopausecontrol {
  position: absolute;
  bottom: 20px;
  right: 10px;
  background-color: rgba(0,0,0,.45);
  border-radius: 50%;
  font-size: 1em;
  height: 1.7em!important;
  width: 1.7em!important;
  line-height: 1em!important;
  text-align: center;
  z-index: 1000!important;
}
.videopausecontrol button svg {
  width: 1.5em;
  height: 1.5em;
  position: absolute;
  top: calc(50% - 0.75em);
  left: calc(50% - 0.75em);
  z-index: 1000!important;
}

.mobile-content__main {
  position: absolute!important;
  padding-right: 25px;
  p {
    font-family: Helvetica, Arial, sans-serif !important;
    font-size: 0.9rem!important;
  }
}
.mobile-content__title {
  font-family: Helvetica, Arial, sans-serif !important;
}
.modal .mobile-content {
  overflow-y: auto!important;
  font-family: Helvetica, Arial, sans-serif !important;
  z-index: 0;
}

.modal .mobile-content::-webkit-scrollbar {
    width: 0px;
}

.modal .mobile-content::-webkit-scrollbar-track {
    display: none;
}
.request-preview--question::-webkit-scrollbar,
.survey-preview--question::-webkit-scrollbar,
.mobile-content__checklist::-webkit-scrollbar {
  width: 0px;
}
.request-preview--question::-webkit-scrollbar-track,
.survey-preview--question::-webkit-scrollbar-track,
.mobile-content__checklist::-webkit-scrollbar-track {
  display: none;
}
.survey picture img {
  width: 50px;
  height: 50px;
}
.mobile-content__info {
  position: absolute;
  bottom: 10px;
  left: 15px;
  color: #fff;
  font-family: Helvetica, Arial, sans-serif !important;
  font-weight: 400;
}

.infograph-content {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
}

#articleIframe {
  position: absolute;
  top:-8px;
  margin-left: -25px;
  min-width: 100%;
  border: none;
}
.mobile-content__externallink {
  position: absolute;
  bottom: 10px;
  right: 20px;
  border-radius: 25px;
  font-size: 0.8em;
  line-height: initial;
  background-color: #fff;
  color: #256dfb;
  font-family: Helvetica, Arial, sans-serif !important;
  font-weight: 600;
  padding: 5px 12px;
}

.rotatepreview {
  transform: rotate(-90deg);
  margin-left: 150px!important;
}
.modal__mobile-preview {
  position: sticky;
  top: 28px;
  transition: 1s;
}
.mobile-content__type {
  font-family: Helvetica, Arial, sans-serif !important;
}

.page-loader {
    z-index: 10100;
    position: absolute;
    background-color: #ecf0f62d;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(2px);
    top: 0;
    left: 0;
}
.page-is-loading {
  display: inline-block;
  width: 64px;
  height: 64px;
  position: absolute;
  top: calc(50% - 32px);
  left: calc(50% - 32px);
}
.page-is-loading:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #666;
  border-color: #666 transparent #666 transparent;
  animation: page-is-loading 1.2s linear infinite;
}
@keyframes page-is-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.form--strong .form-label {
  justify-content: flex-start;
}
::v-deep .multiselect__tags {
  min-height: 44px !important;
}
.notification-content {
  width: 257px;
  height: 538px;
  margin: -20px;
  z-index: 1;
  display: inline-flex;

  &__time {
    z-index:2;
    width: 100%;
    position: absolute;
    top: 75px;
    display: inline-flex;
    justify-content: center;
    font-size: 2.5rem;
    color: #fff;
  }

  &__date {
    z-index:2;
    width: 100%;
    position: absolute;
    top: 130px;
    display: inline-flex;
    justify-content: center;
    font-size: 1.2rem;
    color: #fff;
  }
  &__info {
    z-index:2;
    position: absolute;
    top: 200px;
    display: inline-flex;
    justify-content: flex-start;
    background-color: rgba(#494949, 0.5);
    width: 96%;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
    color: #fff;

    &-content {
      display: block;
      position: relative;
    }
    &-header {
      display: block;
      font-size: 0.8rem;
      font-weight: 500;
      margin-bottom: 5px;
      color:#ffffffdd;

      img {
        margin-right: 8px;
        width: 25px;
        border-radius: 5px;
      }
    }
    &-title {
      font-size: 0.9rem;
      font-weight: 700;
      display: block;
    }
    &-body {
      font-size: 0.9rem;
      display: block;
    }
  }
}

::v-deep #Emojis div.container-emoji div.grid-emojis span.emoji {
  display: inline-table!important;
}
.emoji-button {
  position: absolute;
  display: inline-flex;
  right: 10px;
  padding-top: 2px;
  font-size: 1.2em;
  color: #727d92;
}
.emoji-dropdown-title {
  transform: scale(0.8);
  top: -70px;
}
.emoji-dropdown-body {
  transform: scale(0.8);
  top: -110px;
}

.selectbox {
  width: 100%!important;
  max-width: 400px!important;
}
.thumbnail-wrapper{
  width: 280px;
  height: 328px;
}
textarea.form-control,
textarea.ti-input,
body textarea.mx-input {
    height: 120px;
    resize: none;
}
.noborder-bottom {
  border-bottom: none!important;
}
.daytext {
  position: absolute;
  top: 40px;
  right: 50px;
  font-size: 12px !important;
  color: #7d889d;
  font-family: "Work Sans", sans-serif;
  font-weight: 500;
}
.sample-link {
  font-size: 14px !important;
  line-height: 16px !important;
  font-weight: 500;
  margin-bottom: 5px;
  color: #727d92;
  padding-top: 30px;
  padding-bottom: 30px;
  border-radius: 4px;
  border: 1px solid rgba(114, 125, 146, 0.25);
}
textarea.form-control {
  height: 75px !important;
}

::v-deep .agile {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
::v-deep .agile__actions {
  align-items: center;
  width: 50%;
}
.agile__nav-button {
  background: transparent;
  border: none;
  color: #ccc;
  cursor: pointer;
  font-size: 24px;
  transition-duration: 0.3s;
}
.agile__nav-button:hover {
  color: #888;
}
::v-deep .agile__dots {
  margin-bottom: 0 !important;
}
::v-deep .agile__dot {
  margin: 0 10px;
  height: 10px!important;
  width: 10px!important;
  background: #ccc!important;
  border-radius: 50%;
    &.agile__dot--current {
      background: #727d92!important;
    }
}
.agile__dot button {
  background-color: #eee;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 10px;
  width: 10px;
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
  transition-duration: 0.3s;
  width: 10px;
}
.agile__dot--current button, .agile__dot:hover button {
  background-color: #888;
}

.slide {
  align-items: center;
  color: #fff;
  display: flex;
  margin: 0 !important;
  justify-content: flex-start;
}
.slide h3 {
  font-size: 32px;
  font-weight: 300;
}
.slide-navbutton {
  color: #727d92;
  transform: rotate(180deg);
}
.top-group {
  display: flex;
  justify-content: space-between;
}
::v-deep ul.haveImages {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  li {
    margin: 0 10px 10px 0;
    width: 45%;
    picture span {
    display: flex !important;
    padding: 5px!important;
    flex-direction: column!important;
    img {
      margin-top: 5px;
      width: 100%;
      object-fit: contain;
    }
    }
  }
}
</style>
