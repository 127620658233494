<template>
  <div
    class="tab-pane show active"
  >
    <slot />
    <div
      class="form-group"
      :class="{ 'is-danger': errors.has('quiz.title') }"
    >
      <label
        for="contentTitle"
        class="form-label"
      >{{ $t('title').toUpperCase() }}</label>
      <input
        type="text"
        class="form-control"
        id="contentTitle"
        v-model="title"
        :disabled="disabled && !canEditOnlyItem"
        data-vv-scope="quiz"
        data-vv-name="title"
        :data-vv-as="$t('title')"
        v-validate="'required|max:100'"
        @input="handleTitle"
      >
      <span
        v-show="errors.has('quiz.title')"
        class="help is-danger"
      >{{ errors.first('quiz.title') }}</span>
    </div>
    <div
      v-show="errors.items.length > 0"
      class="form-group"
    >
      <div class="alert alert--card alert--error">
        <p>{{ $t('error_msg_title') }}</p>
        <ul>
          <li
            v-for="(error, index) in errors"
            :key="index"
          >
            {{ error.msg }}
          </li>
        </ul>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="submit"
        class="button button--primary"
        @click="editMode ? updateContent() : createContent()"
        :disabled="isPending || (disabled && !canEditOnlyItem)"
      >
        <span
          v-if="!isPending"
        >
          {{ editMode ? $t('update_button') : $t('create_button') }}
        </span>
        <div
          class="lds-ellipsis"
          v-if="isPending"
        >
          <div />
          <div />
          <div />
          <div />
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import subQuizMixin from '../../../helpers/subQuizMixin.vue';

export default {
  name: 'QuizContent',
  inject: ['$validator'],
  mixins: [subQuizMixin],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    canEditOnlyItem: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      title: '',
      isPending: false,
      questions: [],
    };
  },

  watch: {
    name: {
      handler(newVal) {
        this.title = newVal;
      },
      immediate: true,
    },
  },
  mounted() {
    // this.$root.$emit('setIsAddQuestion', 0);
    if (this.modalData) { this.modalData.isAddingQuestion = 0; }
  },
  activated() {
    // this.$root.$emit('setIsAddQuestion', 0);
    if (this.modalData) { this.modalData.isAddingQuestion = 0; }
  },
  methods: {

    handleTitle() {
      this.$emit('setTitle', this.title);
    },
    async checkValidation() {
      let allIsValid = false;
      let customIsValid = false;
      let quizIsValid = false;

      if (this.modalData?.isAddingQuestion) {
        quizIsValid = await this.checkQuizValidation('quiz');
      } else {
        quizIsValid = true;
      }

      await this.$validator.validateAll('all').then((res) => {
        allIsValid = res;
      });

      await this.$validator.validateAll('quiz').then((res) => {
        customIsValid = res;
      });

      if (allIsValid && customIsValid && quizIsValid) {
        return true;
      }

      return false;
    },

    createContent() {
      this.checkValidation().then((res) => {
        if (res) {
          this.$emit('createContent');
          this.isPending = true;
        }
      });
    },

    updateContent() {
      this.checkValidation().then((res) => {
        if (res) {
          this.$emit('updateContent');
          this.isPending = true;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.is-danger {
  border-color: #f27299;
}
.form-label.second-pair {
  color: #fff!important;
}
.form-group.first-pair:after {
  content: ' - ';
  color:#727d92;
  position: absolute;
  top: 35px;
  right: -4px;
}
</style>
