var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tab-pane show active" }, [
    _c("div", { staticClass: "form-group" }, [
      _c("label", { staticClass: "form-label", attrs: { for: "url" } }, [
        _vm._v(_vm._s(_vm.$t("external_link"))),
      ]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.url,
            expression: "url",
          },
          {
            name: "validate",
            rawName: "v-validate",
            value: { required: true },
            expression: "{required: true}",
          },
        ],
        ref: "urlInput",
        staticClass: "form-control",
        class: { "is-danger": _vm.errors.has("externallink.url") },
        attrs: {
          type: "text",
          disabled: _vm.disabled,
          id: "url",
          "data-vv-scope": "externallink",
          "data-vv-name": "url",
          "data-vv-as": _vm.$t("external_link"),
        },
        domProps: { value: _vm.url },
        on: {
          input: [
            function ($event) {
              if ($event.target.composing) return
              _vm.url = $event.target.value
            },
            _vm.handleUrl,
          ],
        },
      }),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.errors.has("externallink.url"),
              expression: "errors.has('externallink.url')",
            },
          ],
          staticClass: "help is-danger",
        },
        [_vm._v(_vm._s(_vm.errors.first("externallink.url")))]
      ),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.urlValid,
            expression: "urlValid",
          },
        ],
        staticClass: "form-group",
        class: { "is-danger": _vm.errors.has("externallink.title") },
      },
      [
        _c(
          "label",
          { staticClass: "form-label", attrs: { for: "contentTitle" } },
          [_vm._v(_vm._s(_vm.$t("title").toUpperCase()))]
        ),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.title,
              expression: "title",
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|max:100",
              expression: "'required|max:100'",
            },
          ],
          staticClass: "form-control",
          attrs: {
            type: "text",
            id: "contentTitle",
            disabled: _vm.disabled && !_vm.canEditOnlyItem,
            "data-vv-scope": "externallink",
            "data-vv-name": "title",
            "data-vv-as": _vm.$t("title"),
          },
          domProps: { value: _vm.title },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.title = $event.target.value
              },
              _vm.handleTitle,
            ],
          },
        }),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.errors.has("externallink.title"),
                expression: "errors.has('externallink.title')",
              },
            ],
            staticClass: "help is-danger",
          },
          [_vm._v(_vm._s(_vm.errors.first("externallink.title")))]
        ),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.urlValid,
            expression: "urlValid",
          },
        ],
        staticClass: "quiz-assubcomponent",
      },
      [
        _c("div", { staticClass: "title" }, [
          _c("h5", { staticClass: "modal-title" }, [
            _vm._v(" " + _vm._s(_vm.$t("add_question")) + " "),
          ]),
          _c("div", { staticClass: "swicth-checkbox" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.isAddingQuestion,
                  expression: "isAddingQuestion",
                },
              ],
              attrs: {
                type: "checkbox",
                id: "isAddingQuestion",
                "true-value": 1,
                "false-value": 0,
              },
              domProps: {
                checked: Array.isArray(_vm.isAddingQuestion)
                  ? _vm._i(_vm.isAddingQuestion, null) > -1
                  : _vm._q(_vm.isAddingQuestion, 1),
              },
              on: {
                change: [
                  function ($event) {
                    var $$a = _vm.isAddingQuestion,
                      $$el = $event.target,
                      $$c = $$el.checked ? 1 : 0
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.isAddingQuestion = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.isAddingQuestion = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.isAddingQuestion = $$c
                    }
                  },
                  (e) => _vm.setIsAddingQuestion(e.target.checked),
                ],
              },
            }),
            _c("label", {
              staticClass: "swicth-label",
              attrs: { for: "isAddingQuestion" },
            }),
          ]),
        ]),
        _c("div", { staticClass: "warning-box" }, [
          _c(
            "div",
            { staticClass: "desc-text" },
            [
              _c(
                "icon-container",
                {
                  attrs: {
                    width: "27",
                    height: "30",
                    "view-box": "0 0 27 30",
                    name: "lamp",
                    color: "#727d92",
                  },
                },
                [_c("icon-lamp")],
                1
              ),
              _c("div", { staticClass: "description" }, [
                _vm._v(" " + _vm._s(_vm.$t("quiz_remark")) + " "),
              ]),
            ],
            1
          ),
        ]),
        _c(
          "collapse-transition",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.modalData?.isAddingQuestion,
                expression: "modalData?.isAddingQuestion",
              },
            ],
            attrs: { duration: 500, dimension: "height" },
          },
          [
            _c("quiz-component", {
              attrs: {
                disabled: _vm.disabledTemp,
                "can-edit-only-item": _vm.canEditOnlyItemTemp,
                "quiz-type": "content",
                "as-scope": "externallinkquiz",
                repetitive_max_question_per_day:
                  _vm.repetitiveMaxQuestionPerDayTemp,
                repetitive_correct_answer_count:
                  _vm.repetitiveCorrectAnswerCountTemp,
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.errors.items.length > 0,
            expression: "errors.items.length > 0",
          },
        ],
        staticClass: "form-group",
      },
      [
        _c("div", { staticClass: "alert alert--card alert--error" }, [
          _c("p", [_vm._v(_vm._s(_vm.$t("error_msg_title")))]),
          _c(
            "ul",
            _vm._l(_vm.errors, function (error, index) {
              return _c("li", { key: index }, [
                _vm._v(" " + _vm._s(error.msg) + " "),
              ])
            }),
            0
          ),
        ]),
      ]
    ),
    _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        {
          staticClass: "button button--primary",
          attrs: {
            type: "button",
            disabled: _vm.isPending || (_vm.disabled && !_vm.canEditOnlyItem),
          },
          on: {
            click: function ($event) {
              _vm.editMode ? _vm.updateContent() : _vm.createContent()
            },
          },
        },
        [
          !_vm.isPending
            ? _c("span", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.editMode
                        ? _vm.$t("update_button")
                        : _vm.$t("create_button")
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
          _vm.isPending
            ? _c("div", { staticClass: "lds-ellipsis" }, [
                _c("div"),
                _c("div"),
                _c("div"),
                _c("div"),
              ])
            : _vm._e(),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }