<template>
  <div
    class="tab-pane show active"
  >
    <div
      class="form-group"
      :class="{ 'is-danger': errors.has('article.title') }"
    >
      <label
        for="contentTitle"
        class="form-label"
      >{{ $t('title').toUpperCase() }}</label>
      <input
        type="text"
        class="form-control"
        id="contentTitle"
        v-model="title"
        :disabled="disabled && !canEditOnlyItem"
        data-vv-scope="article"
        data-vv-name="title"
        :data-vv-as="$t('title')"
        v-validate="'required|max:100'"
        @input="handleTitle"
      >
      <span
        v-show="errors.has('article.title')"
        class="help is-danger"
      >{{ errors.first('article.title') }}</span>
    </div>
    <div class="form-group form-group--cards">
      <div class="row">
        <div class="col-12">
          <label
            for="uploadHtml"
            class="form-label"
          >{{ $t('upload_html_file') }}</label>
          <div
            class="card card--upload"
            :class="{ 'is-danger': errors.has('article.article') }"
          >
            <div
              class="html-container"
              v-if="articlePreview"
            >
              <div
                id="article"
                v-html="articlePreview"
                class="article-preview"
              />

              <button
                class="js-media-delete"
                @click="articleRemoved"
              >
                <icon-container
                  :width="20"
                  :height="20"
                  name="trash"
                >
                  <icon-trash />
                </icon-container>
              </button>
            </div>
            <div
              class="video-upload-file"
              v-if="!articlePreview"
            >
              <input
                type="file"
                :disabled="disabled"
                data-vv-scope="article"
                name="article"
                data-vv-name="article"
                :data-vv-as="$t('html_file')"
                v-validate="'required|size:1000|mimes:text/html'"
                accept="text/html,.html,.htm"
                @input="$emit('input', $event.target.value)"
                @change="articleUploaded"
              >
              <span class="media-object media-object--row">
                <span class="media-object__media">
                  <icon-container
                    name="circleplusfull"
                    view-box="0 0 512 512"
                    :width="45"
                    :height="45"
                    :is-icon-class="false"
                  >
                    <icon-circleplusfull />
                  </icon-container>
                </span>
              </span>
            </div>
          </div>
          <span
            v-if="errors.has('article.article')"
            class="help is-danger"
          >{{ errors.first('article.article') }}</span>
        </div>
      </div>
    </div>

    <div
      v-show="errors.items.length > 0"
      class="form-group"
    >
      <div class="alert alert--card alert--error">
        <p>{{ $t('error_msg_title') }}</p>
        <ul>
          <li
            v-for="(error, index) in errors"
            :key="index"
          >
            {{ error.msg }}
          </li>
        </ul>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="button button--primary"
        @click="editMode ? updateContent() : createContent()"
        :disabled="isPending || (disabled && !canEditOnlyItem)"
      >
        <span
          v-if="!isPending"
        >
          {{ editMode ? $t('update_button') : $t('create_button') }}
        </span>
        <div
          class="lds-ellipsis"
          v-if="isPending"
        >
          <div />
          <div />
          <div />
          <div />
        </div>
      </button>
    </div>
  </div>
</template>
<script>
import IconContainer from '@/components/elements/Icon.vue';
import IconCirclePlusFull from '@/components/icons/CirclePlusFull.vue';

export default {
  name: 'ArticleContent',
  inject: ['$validator'],
  components: {
    'icon-container': IconContainer,
    'icon-circleplusfull': IconCirclePlusFull,
  },

  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    articleHtml: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    canEditOnlyItem: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isPending: false,
      articleErrorField: false,
      articlePreview: '',
      file: null,
      title: '',
    };
  },

  computed: {
    modalData: {
      get() {
        return this.$store.state.modal.modalData;
      },
      set(newValue) {
        this.$store.state.modal.modalData = newValue;
      },
    },
    modalError() {
      return this.$store.state.modal.formError;
    },
  },

  watch: {
    modalError: {
      handler(newVal) {
        if (newVal) {
          this.isPending = false;
        }
      },
      immediate: true,
    },

    articleHtml: {
      handler(newVal) {
        if (this.editMode) {
          this.articlePreview = newVal;
        }
      },
      immediate: true,
    },

    name: {
      handler(newVal) {
        this.title = newVal;
      },
      immediate: true,
    },
  },

  methods: {
    async articleUploaded(e) {
      const files = e.target.files || e.dataTransfer.files;
      const file = files[0];
      let preview;
      if (file) {
        preview = await this.readFile(file);
        this.$validator.validate('article.article').then((res) => {
          if (res) {
            this.file = file;
            this.$emit('file', file);
            this.articlePreview = preview;
            this.$emit('article', preview);
          }
        });
      }
    },

    async readFile(file) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = (event) => {
          const preview = event.target.result;
          resolve(preview);
        };
      });
    },

    articleRemoved() {
      this.file = null;
      this.articlePreview = '';
      this.$emit('file', null);
      this.$emit('article', null);
    },

    handleTitle() {
      this.$emit('setTitle', this.title);
    },

    async checkValidation() {
      let allIsValid = false;
      let articleIsValid = false;
      await this.$validator.validateAll('all').then((res) => {
        allIsValid = res;
      });
      await this.$validator.validateAll('article').then((res) => {
        articleIsValid = res;
      });
      if (allIsValid && articleIsValid) {
        return true;
      }
      return false;
    },

    createContent() {
      this.checkValidation().then((res) => {
        if (res) {
          this.$emit('createContent');
          this.isPending = true;
        }
      });
    },

    updateContent() {
      this.checkValidation().then((res) => {
        if (res) {
          this.$emit('updateContent');
          this.isPending = true;
        }
      });
    },
  },
};
</script>
<style scoped>
.article-preview {
    position: absolute;
    top: 0;
    width: 400px;
}
.is-danger {
  border-color: #f27299;
}
</style>
