<template>
  <div
    class="tab-pane show active"
  >
    <div class="row">
      <div class="col-12">
        <div
          class="form-group form-group--cards"
        >
          <label
            for="package_zip"
            class="form-label"
          >{{ $t('package_zip').toUpperCase() + '*' }}</label>
          <fileupload-container
            :class="{ 'is-danger': errors.has(`scorm.package_zip`) }"
            :file-url="url"
            :disabled="disabled"
            :file-index="0"
            id="package_zip"
            :is-multiple="false"
            vee-rule="required|size:1800000"
            vee-scoop-name="scorm"
            :vee-name="`package_zip`"
            :vee-as="$t('package_zip')"
            @update="fileAdd"
            class="card cardfile--upload"
          />
          <span
            v-if="errors.has(`scorm.package_zip`)"
            class="help is-danger"
          >{{ errors.first(`scorm.package_zip`) }}</span>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label
            for="indexFilePath"
            class="form-label"
          >{{ $t('indexFilePath').toUpperCase() + '*' }}</label>
          <input
            :class="{ 'is-danger': errors.has('scorm.indexFilePath') }"
            type="text"
            :disabled="disabled"
            class="form-control"
            id="indexFilePath"
            ref="indexFilePath"
            v-model="iFilePath"
            data-vv-scope="scorm"
            data-vv-name="indexFilePath"
            :data-vv-as="$t('indexFilePath')"
            v-validate="{required: true}"
            @input="$emit('setIndexFilePath', iFilePath)"
          >
          <span
            v-show="errors.has('scorm.indexFilePath')"
            class="help is-danger"
          >{{ errors.first('scorm.indexFilePath') }}</span>
        </div>
      </div>
    </div>

    <div
      class="form-group"
      :class="{ 'is-danger': errors.has('scorm.title') }"
    >
      <label
        for="contentTitle"
        class="form-label"
      >{{ $t('title').toUpperCase() }}</label>
      <input
        type="text"
        class="form-control"
        id="contentTitle"
        v-model="title"
        :disabled="disabled && !canEditOnlyItem"
        data-vv-scope="scorm"
        data-vv-name="title"
        :data-vv-as="$t('title')"
        v-validate="'required|max:100'"
        @input="$emit('setTitle', title)"
      >
      <span
        v-show="errors.has('scorm.title')"
        class="help is-danger"
      >{{ errors.first('scorm.title') }}</span>
    </div>
    <div
      v-show="errors.items.length > 0"
      class="form-group"
    >
      <div class="alert alert--card alert--error">
        <p>{{ $t('error_msg_title') }}</p>
        <ul>
          <li
            v-for="(error, index) in errors"
            :key="index"
          >
            {{ error.msg }}
          </li>
        </ul>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="button button--primary"
        @click="editMode ? handleContent('updateContent') : handleContent('createContent')"
        :disabled="isPending || (disabled && !canEditOnlyItem)"
      >
        <span
          v-if="!isPending"
        >
          {{ editMode ? $t('update_button') : $t('create_button') }}
        </span>
        <div
          class="lds-ellipsis"
          v-if="isPending"
        >
          <div />
          <div />
          <div />
          <div />
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import FileUploadContainer from '@/components/FileUpload.vue';

export default {
  name: 'ScormContent',
  inject: ['$validator'],

  props: {
    name: {
      type: String,
      default: '',
    },
    externalUrl: {
      type: String,
      default: '',
    },
    indexFilePath: {
      type: String,
      default: 'index.html',
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    canEditOnlyItem: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isPending: false,
      thumbnail: null,
      thumbnailErrorField: false,
      title: '',
      url: '',
      iFilePath: '',
      updateInfo: false,
      dataHolder: null,
      toastHolder: null,
    };
  },
  components: {
    'fileupload-container': FileUploadContainer,
  },
  computed: {
    modalData: {
      get() {
        return this.$store.state.modal.modalData;
      },
      set(newValue) {
        this.$store.state.modal.modalData = newValue;
      },
    },
    modalError() {
      return this.$store.state.modal.formError;
    },
  },

  watch: {
    modalError: {
      handler(newVal) {
        if (newVal) {
          this.isPending = false;
        }
      },
      immediate: true,
    },

    externalUrl: {
      handler(newVal) {
        if (this.editMode) {
          this.url = newVal;
        }
      },
      immediate: true,
    },

    indexFilePath: {
      handler(newVal) {
        if (this.editMode) {
          this.iFilePath = newVal;
        }
      },
      immediate: true,
    },

    name: {
      handler(newVal) {
        this.title = newVal;
      },
      immediate: true,
    },
  },

  methods: {
    fileAdd(data) {
      // eslint-disable-next-line no-unused-vars
      let preview = null;
      let file = null;
      let index = null;
      [preview, file, index] = data;
      if (index != null || index !== undefined) {
        this.$emit('setPackage', file);
        if (file && file.name) {
          this.url = file.name;
        } else {
          this.url = null;
        }
      }
    },


    async checkValidation() {
      let allIsValid = false;
      let scormIsValid = false;

      await this.$validator.validateAll('all').then((res) => {
        allIsValid = res;
      });
      await this.$validator.validateAll('scorm').then((res) => {
        scormIsValid = res;
      });

      if (allIsValid && scormIsValid) {
        return true;
      }

      return false;
    },

    handleContent(type) {
      this.checkValidation().then((res) => {
        if (res) {
          this.$emit(type);
          this.isPending = true;
        }
      });
    },

  },
};
</script>
<style scoped>
.form-control.is-danger {
  border-color: #f27299;
}
.filedownload-box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  border-radius: 14px;
  box-shadow: 0 1px 6px 0 rgba(114, 125, 146, 0.2);
  border: solid 2px rgba(114, 125, 146, 0.2);
  background-color: rgba(255, 255, 255, 0.09);
  margin-bottom: 30px;
  position: relative;
}
</style>
