<template>
  <div
    class="tab-pane show active"
  >
    <div class="form-group form-group--cards">
      <div class="row">
        <div class="col-12 col-sm-6">
          <label
            for="uploadInfographic"
            class="form-label"
          >{{ $t('upload_infographic') }}</label>
          <thumbnail-container
            :class="{ 'is-danger': errors.has('infographics.infographic') }"
            :thumbnail-image="thumbnailInfographicsPreview"
            :is-disable="disabled"
            @update="thumbnailInfographicsAdded"
            vee-scoop-name="infographics"
            vee-name="infographic"
            vee-as="infographics Image"
            vee-rule="required|size:5000|mimes:image/jpeg,image/png"
            class="card card--upload"
          />
          <span
            v-show="errors.has('infographics.infographic')"
            class="help is-danger"
          >{{ errors.first('infographics.infographic') }}</span>
        </div>
      </div>
    </div>
    <div
      v-show="thumbnailInfographicsPreview"
      class="form-group"
      :class="{ 'is-danger': errors.has('infographics.title') }"
    >
      <label
        for="contentTitle"
        class="form-label"
      >{{ $t('title').toUpperCase() }}</label>
      <input
        type="text"
        class="form-control"
        id="contentTitle"
        v-model="title"
        :disabled="disabled && !canEditOnlyItem"
        data-vv-scope="infographics"
        data-vv-name="title"
        :data-vv-as="$t('title')"
        v-validate="'required|max:100'"
        @input="handleTitle"
      >
      <span
        v-show="errors.has('infographics.title')"
        class="help is-danger"
      >{{ errors.first('infographics.title') }}</span>
    </div>
    <div
      v-show="thumbnailInfographicsPreview"
      class="quiz-assubcomponent"
    >
      <div class="title">
        <h5 class="modal-title">
          {{ $t('add_question') }}
        </h5>
        <div
          class="swicth-checkbox"
        >
          <input
            type="checkbox"
            id="isAddingQuestion"
            v-model="isAddingQuestion"
            @change="(e)=>setIsAddingQuestion(e.target.checked)"
            :true-value="1"
            :false-value="0"
          >
          <label
            class="swicth-label"
            for="isAddingQuestion"
          />
        </div>
      </div>
      <div class="warning-box">
        <div class="desc-text">
          <icon-container
            width="27"
            height="30"
            view-box="0 0 27 30"
            name="lamp"
            color="#727d92"
          >
            <icon-lamp />
          </icon-container>
          <div class="description">
            {{ $t('quiz_remark') }}
          </div>
        </div>
      </div>
      <collapse-transition
        :duration="500"
        dimension="height"
        v-show="modalData && modalData.isAddingQuestion"
      >
        <quiz-component
          :disabled="disabledTemp"
          :can-edit-only-item="canEditOnlyItemTemp"
          :quiz-type="'content'"
          as-scope="infographicsquiz"
          :repetitive_max_question_per_day="repetitiveMaxQuestionPerDayTemp"
          :repetitive_correct_answer_count="repetitiveCorrectAnswerCountTemp"
        />
      </collapse-transition>
    </div>
    <div
      v-show="errors.items.length > 0 && thumbnailInfographicsPreview"
      class="form-group"
    >
      <div class="alert alert--card alert--error">
        <p>{{ $t('error_msg_title') }}</p>
        <ul>
          <li
            v-for="(error, index) in errors"
            :key="index"
          >
            {{ error.msg }}
          </li>
        </ul>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="button button--primary"
        @click="editMode ? updateContent() : createContent()"
        :disabled="isPending || (disabled && !canEditOnlyItem)"
      >
        <span
          v-if="!isPending"
        >
          {{ editMode ? $t('update_button') : $t('create_button') }}
        </span>
        <div
          class="lds-ellipsis"
          v-if="isPending"
        >
          <div />
          <div />
          <div />
          <div />
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ThumbnailContainer from '@/components/Thumbnail.vue';
import subQuizMixin from '../../../helpers/subQuizMixin.vue';

export default {
  name: 'InfographicsContent',
  inject: ['$validator'],
  mixins: [subQuizMixin],
  components: {
    'thumbnail-container': ThumbnailContainer,
  },

  props: {
    name: {
      type: String,
      default: '',
    },
    infoGraphUrl: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    canEditOnlyItem: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isPending: false,
      thumbnailInfographics: null,
      thumbnailInfographicsPreview: null,
      thumbnailInfographicsErrorField: false,
      thumbnail: null,
      thumbnailPreview: null,
      thumbnailErrorField: false,
      title: '',
      isDisabled: false,
      isAddingQuestion: 0,
    };
  },

  computed: {
    modalData: {
      get() {
        return this.$store.state.modal.modalData;
      },
      set(newValue) {
        this.$store.state.modal.modalData = newValue;
      },
    },
    modalError() {
      return this.$store.state.modal.formError;
    },
  },

  watch: {
    modalData: {
      handler(newVal) {
        if (newVal) {
          this.isAddingQuestion = newVal.isAddingQuestion;
        }
      },
      immediate: true,
    },
    modalError: {
      handler(newVal) {
        if (newVal) {
          this.isPending = false;
        }
      },
      immediate: true,
    },

    infoGraphUrl: {
      handler(newVal) {
        if (this.editMode) {
          this.thumbnailInfographicsPreview = newVal;
        }
      },
      immediate: true,
    },

    name: {
      handler(newVal) {
        this.title = newVal;
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions({
      setIsAddingQuestion: 'modal/SET_ISADDING_QUESTION',
    }),
    thumbnailInfographicsAdded(file) {
      if (file) {
        [this.thumbnailInfographicsPreview, this.thumbnailInfographics] = file;
      } else {
        this.thumbnailInfographicsPreview = null;
        this.thumbnailInfographics = null;
      }
      if (this.thumbnailInfographics) {
        this.title = this.$helpers.getFileNameOnly(this.thumbnailInfographics);
        this.handleTitle();
      }
      this.$emit('infograph', file);
    },

    handleTitle() {
      this.$emit('setTitle', this.title);
    },

    async checkValidation() {
      let allIsValid = false;
      let infographicIsValid = false;
      let quizIsValid = false;

      if (this.modalData?.isAddingQuestion) {
        quizIsValid = await this.checkQuizValidation('infographics');
      } else {
        quizIsValid = true;
      }

      await this.$validator.validateAll('all').then((res) => {
        allIsValid = res;
      });

      await this.$validator.validateAll('infographics').then((res) => {
        infographicIsValid = res;
      });

      if (allIsValid && infographicIsValid && quizIsValid) {
        return true;
      }

      return false;
    },

    createContent() {
      this.checkValidation().then((res) => {
        if (res) {
          this.$emit('createContent');
          this.isPending = true;
        }
      });
    },

    async updateContent() {
      if ((this.modalData?.isAddingQuestion === 0 || this.modalData?.isAddingQuestion === false) && this.modalData?.quiz && this.modalData?.quiz?.questions) {
        // eslint-disable-next-line array-callback-return
        await Promise.all(this.modalData?.quiz?.questions.map((question) => {
          this.$store.dispatch('modal/DELETE_QUIZ_QUESTION', question.id);
        }));
        await this.$store.dispatch('modal/DELETE_CONTENT_SUB_QUIZ', this.modalData.quiz.id);
      }
      this.checkValidation().then((res) => {
        if (res) {
          this.$emit('updateContent');
          this.isPending = true;
        }
      });
    },
  },
};
</script>
<style scoped>
.is-danger {
  border-color: #f27299;
}
</style>
