var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tab-pane show active" }, [
    _c("div", { staticClass: "form-group form-group--cards" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "label",
            { staticClass: "form-label", attrs: { for: "uploadPodcast" } },
            [_vm._v(_vm._s(_vm.$t("upload_podcast")))]
          ),
          _c(
            "div",
            {
              staticClass: "card card--upload",
              class: { "is-danger": _vm.errors.has("podcast.podcast") },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "vloader",
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        version: "1.1",
                        id: "loader-1",
                        xmlns: "http://www.w3.org/2000/svg",
                        "xmlns:xlink": "http://www.w3.org/1999/xlink",
                        x: "0px",
                        y: "0px",
                        width: "60px",
                        height: "60px",
                        viewBox: "0 0 60 60",
                        "enable-background": "new 0 0 60 60",
                        "xml:space": "preserve",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          opacity: "0.2",
                          fill: "#000",
                          d: "M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946\n                  s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634\n                  c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z",
                        },
                      }),
                      _c(
                        "path",
                        {
                          attrs: {
                            fill: "#000",
                            d: "M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0\n                  C22.32,8.481,24.301,9.057,26.013,10.047z",
                          },
                        },
                        [
                          _c("animateTransform", {
                            attrs: {
                              attributeType: "xml",
                              attributeName: "transform",
                              type: "rotate",
                              from: "0 20 20",
                              to: "360 20 20",
                              dur: "0.5s",
                              repeatCount: "indefinite",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
              _vm.podcastPreview
                ? _c(
                    "div",
                    { staticClass: "podcast-container" },
                    [
                      _c("av-waveform", {
                        staticClass: "podcast-visual",
                        attrs: {
                          "audio-src": _vm.podcastPreview,
                          "cors-anonym": true,
                          "canv-top": true,
                          "audio-class": "podcast-audio",
                        },
                      }),
                      _c(
                        "button",
                        {
                          staticClass: "js-media-delete",
                          attrs: { disabled: _vm.disabled },
                          on: { click: _vm.podcastRemoved },
                        },
                        [
                          _c(
                            "icon-container",
                            { attrs: { width: 20, height: 20, name: "trash" } },
                            [_c("icon-trash")],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.podcastPreview
                ? _c("div", { staticClass: "podcast-upload-file" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value:
                            "required|size:200000|mimes:audio/*,audio/x-mpeg-3,.mp3",
                          expression:
                            "'required|size:200000|mimes:audio/*,audio/x-mpeg-3,.mp3'",
                        },
                      ],
                      attrs: {
                        type: "file",
                        "data-vv-scope": "podcast",
                        disabled: _vm.disabled,
                        name: "podcast",
                        "data-vv-name": "podcast",
                        "data-vv-as": _vm.$t("podcast"),
                        accept: "audio/mpeg3,audio/x-mpeg-3,.mp3",
                      },
                      on: {
                        change: _vm.podcastUploaded,
                        input: function ($event) {
                          return _vm.$emit("input", $event.target.value)
                        },
                      },
                    }),
                    !_vm.loading
                      ? _c(
                          "span",
                          { staticClass: "media-object media-object--row" },
                          [
                            _c(
                              "span",
                              { staticClass: "media-object__media" },
                              [
                                _c(
                                  "icon-container",
                                  {
                                    attrs: {
                                      name: "circleplusfull",
                                      "view-box": "0 0 512 512",
                                      width: 45,
                                      height: 45,
                                      "is-icon-class": false,
                                    },
                                  },
                                  [_c("icon-circleplusfull")],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]
          ),
          _vm.errors.has("podcast.podcast")
            ? _c("span", { staticClass: "help is-danger" }, [
                _vm._v(_vm._s(_vm.errors.first("podcast.podcast"))),
              ])
            : _vm._e(),
        ]),
      ]),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.podcastPreview,
            expression: "podcastPreview",
          },
        ],
        staticClass: "form-group",
        class: { "is-danger": _vm.errors.has("podcast.title") },
      },
      [
        _c(
          "label",
          { staticClass: "form-label", attrs: { for: "contentTitle" } },
          [_vm._v(_vm._s(_vm.$t("title").toUpperCase()))]
        ),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.title,
              expression: "title",
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|max:600",
              expression: "'required|max:600'",
            },
          ],
          staticClass: "form-control",
          attrs: {
            type: "text",
            id: "contentTitle",
            disabled: _vm.disabled && !_vm.canEditOnlyItem,
            "data-vv-scope": "podcast",
            "data-vv-name": "title",
            "data-vv-as": _vm.$t("title"),
          },
          domProps: { value: _vm.title },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.title = $event.target.value
              },
              _vm.handleTitle,
            ],
          },
        }),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.errors.has("podcast.title"),
                expression: "errors.has('podcast.title')",
              },
            ],
            staticClass: "help is-danger",
          },
          [_vm._v(_vm._s(_vm.errors.first("podcast.title")))]
        ),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.podcastPreview,
            expression: "podcastPreview",
          },
        ],
        staticClass: "quiz-assubcomponent",
      },
      [
        _c("div", { staticClass: "title" }, [
          _c("h5", { staticClass: "modal-title" }, [
            _vm._v(" " + _vm._s(_vm.$t("add_question")) + " "),
          ]),
          _c("div", { staticClass: "swicth-checkbox" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.isAddingQuestion,
                  expression: "isAddingQuestion",
                },
              ],
              attrs: {
                type: "checkbox",
                id: "isAddingQuestion",
                "true-value": 1,
                "false-value": 0,
              },
              domProps: {
                checked: Array.isArray(_vm.isAddingQuestion)
                  ? _vm._i(_vm.isAddingQuestion, null) > -1
                  : _vm._q(_vm.isAddingQuestion, 1),
              },
              on: {
                change: [
                  function ($event) {
                    var $$a = _vm.isAddingQuestion,
                      $$el = $event.target,
                      $$c = $$el.checked ? 1 : 0
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.isAddingQuestion = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.isAddingQuestion = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.isAddingQuestion = $$c
                    }
                  },
                  (e) => _vm.setIsAddingQuestion(e.target.checked),
                ],
              },
            }),
            _c("label", {
              staticClass: "swicth-label",
              attrs: { for: "isAddingQuestion" },
            }),
          ]),
        ]),
        _c("div", { staticClass: "warning-box" }, [
          _c(
            "div",
            { staticClass: "desc-text" },
            [
              _c(
                "icon-container",
                {
                  attrs: {
                    width: "27",
                    height: "30",
                    "view-box": "0 0 27 30",
                    name: "lamp",
                    color: "#727d92",
                  },
                },
                [_c("icon-lamp")],
                1
              ),
              _c("div", { staticClass: "description" }, [
                _vm._v(" " + _vm._s(_vm.$t("quiz_remark")) + " "),
              ]),
            ],
            1
          ),
        ]),
        _c(
          "collapse-transition",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.modalData?.isAddingQuestion,
                expression: "modalData?.isAddingQuestion",
              },
            ],
            attrs: { duration: 500, dimension: "height" },
          },
          [
            _c("quiz-component", {
              attrs: {
                disabled: _vm.disabledTemp,
                "can-edit-only-item": _vm.canEditOnlyItemTemp,
                "quiz-type": "content",
                "as-scope": "podcastquiz",
                repetitive_max_question_per_day:
                  _vm.repetitiveMaxQuestionPerDayTemp,
                repetitive_correct_answer_count:
                  _vm.repetitiveCorrectAnswerCountTemp,
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.errors.items.length > 0 && _vm.podcastPreview,
            expression: "errors.items.length > 0 && podcastPreview",
          },
        ],
        staticClass: "form-group",
      },
      [
        _c("div", { staticClass: "alert alert--card alert--error" }, [
          _c("p", [_vm._v(_vm._s(_vm.$t("error_msg_title")))]),
          _c(
            "ul",
            _vm._l(_vm.errors, function (error, index) {
              return _c("li", { key: index }, [
                _vm._v(" " + _vm._s(error.msg) + " "),
              ])
            }),
            0
          ),
        ]),
      ]
    ),
    _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        {
          staticClass: "button button--primary",
          attrs: {
            type: "button",
            disabled: _vm.isPending || (_vm.disabled && !_vm.canEditOnlyItem),
          },
          on: {
            click: function ($event) {
              _vm.editMode ? _vm.updateContent() : _vm.createContent()
            },
          },
        },
        [
          !_vm.isPending
            ? _c("span", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.editMode
                        ? _vm.$t("update_button")
                        : _vm.$t("create_button")
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
          _vm.isPending
            ? _c("div", { staticClass: "lds-ellipsis" }, [
                _c("div"),
                _c("div"),
                _c("div"),
                _c("div"),
              ])
            : _vm._e(),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }