<template>
  <div
    class="tab-pane show active"
  >
    <div
      class="form-group"
      :class="{ 'is-danger': errors.has('checklist.title') }"
    >
      <label
        for="contentTitle"
        class="form-label"
      >{{ $t('title').toUpperCase() }}</label>
      <input
        type="text"
        class="form-control"
        id="contentTitle"
        v-model="title"
        :disabled="disabled && !canEditOnlyItem"
        data-vv-scope="checklist"
        data-vv-name="title"
        :data-vv-as="$t('title')"
        v-validate="'required|max:100'"
        @input="handleTitle"
      >
      <span
        v-show="errors.has('checklist.title')"
        class="help is-danger"
      >{{ errors.first('checklist.title') }}</span>
    </div>
    <div class="form-group form-group--cards checklist-box">
      <div
        class="check-text-item"
        ref="formItem"
        v-for="(item, index) in checklistItems"
        :key="index"
      >
        <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
        <label
          :for="'checkInput' + index"
          class="form-label"
        >{{ $t('checklist_item') }} {{ index + 1 }}</label>
        <div class="d-flex justify-content-between align-items-center">
          <input
            :class="{ 'is-danger': errors.has(`checklist.checklist${index}`) }"
            type="text"
            :disabled="disabled"
            class="form-control"
            v-model="item.item_text"
            :id="'checkInput' + index"
            data-vv-scope="checklist"
            :key="index"
            :data-vv-name="`checklist${index}`"
            :data-vv-as="$t('checklist_item')"
            v-validate="'required|max:500|min:3'"
            @keypress="addChecklistItem(index + 1)"
            @paste="addChecklistItem(index + 1)"
          >
          <button
            :disabled="disabled"
            @click="removeChecklistItem(index)"
            v-if="checklistItems.length > 1"
          >
            <icon-container
              name="trash"
              color="#727d92"
              :width="15"
              :height="15"
              view-box="0 0 25 25"
            >
              <icon-trash />
            </icon-container>
          </button>
        </div>
        <span
          v-if="errors.has(`checklist.checklist${index}`)"
          class="help is-danger"
        >{{ errors.first(`checklist.checklist${index}`) }}</span>
      </div>
    </div>
    <div class="modal-footer">
      <button
        v-if="checklistItems.length < 1"
        :disabled="disabled"
        type="button"
        class="button button--alternative button--icon u-full"
        @click.prevent="addChecklistItem()"
      >
        <icon-container
          name="circleplusfull"
          view-box="0 0 512 512"
          color="#fff"
          class="icon-svg"
          :is-icon-class="false"
        >
          <icon-circleplusfull />
        </icon-container>
        {{ $t('add_item') }}
      </button>
    </div>
    <div
      v-show="errors.items.length > 0"
      class="form-group"
    >
      <div class="alert alert--card alert--error">
        <p>{{ $t('error_msg_title') }}</p>
        <ul>
          <li
            v-for="(error, index) in errors"
            :key="index"
          >
            {{ error.msg }}
          </li>
        </ul>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="button button--primary"
        @click="editMode ? updateContent() : createContent()"
        :disabled="isPending || (disabled && !canEditOnlyItem)"
      >
        <span
          v-if="!isPending"
        >
          {{ editMode ? $t('update_button') : $t('create_button') }}
        </span>
        <div
          class="lds-ellipsis"
          v-if="isPending"
        >
          <div />
          <div />
          <div />
          <div />
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import IconTrash from '@/components/icons/Trash.vue';
import IconContainer from '@/components/elements/Icon.vue';
import IconCirclePlusFull from '@/components/icons/CirclePlusFull.vue';

export default {
  name: 'ChecklistContent',

  inject: ['$validator'],

  components: {
    'icon-container': IconContainer,
    'icon-trash': IconTrash,
    'icon-circleplusfull': IconCirclePlusFull,
  },

  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    canEditOnlyItem: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      title: '',
      isPending: false,
      checklistItems: [],
    };
  },

  computed: {
    modalData: {
      get() {
        return this.$store.state.modal.modalData;
      },
      set(newValue) {
        this.$store.state.modal.modalData = newValue;
      },
    },
    formError: {
      get() {
        return this.$store.state.modal.formError;
      },
      set(newValue) {
        this.$store.state.modal.formError = newValue;
      },
    },
  },

  watch: {
    modalData: {
      handler(newVal) {
        const $newVal = newVal;
        if ($newVal) {
          if (this.editMode && $newVal.checklist) {
            this.checklistItems = $newVal.checklist.items;
            this.checklistItems.push({
              item_text: '',
            });
          }
        }
      },
      immediate: true,
    },

    formError: {
      handler(newVal) {
        if (newVal) {
          this.isPending = false;
        }
      },
      immediate: true,
    },

    name: {
      handler(newVal) {
        this.title = newVal;
      },
      immediate: true,
    },

    checklistItems: {
      handler(newVal) {
        this.$emit('setChecklistItems', newVal);
      },
      immediate: true,
    },
  },

  mounted() {
    if (!this.editMode) {
      this.addChecklistItem();
    }
  },

  methods: {
    addChecklistItem(index = 0) {
      if (index === this.checklistItems.length) {
        this.checklistItems.push({
          item_text: '',
        });
      }
    },

    removeChecklistItem(index) {
      const itemToremoveId = this.checklistItems[index].id;
      if (itemToremoveId) {
        this.$store.dispatch('modal/DELETE_CHECKLIST_ITEM', itemToremoveId).then(() => {
          if (!this.formError) {
            this.checklistItems.splice(index, 1);
          } else {
            this.formError = false;
          }
        });
      } else {
        this.checklistItems.splice(index, 1);
      }
    },

    handleTitle() {
      this.$emit('setTitle', this.title);
    },

    clearErrorMsg() {
      this.$validator.errors.clear();
    },

    async checkValidation() {
      let allIsValid = false;
      let checklistIsValid = false;
      let customCheck = true;
      this.clearErrorMsg();

      this.checklistItems.forEach((item, index) => { // delete empty cheklist before validation
        if (!item.item_text) {
          this.checklistItems.splice(index, 1);
        }
      });
      if (this.checklistItems.length < 1) { // check at least one question exist
        this.$validator.errors.add({
          field: 'checklist',
          msg: this.$t('minimum_checklist_item'),
          scope: 'checklist',
        });
        customCheck = false;
      }

      await this.$validator.validateAll('all').then((res) => {
        allIsValid = res;
      });
      await this.$validator.validateAll('checklist').then((res) => {
        checklistIsValid = res;
      });
      if (allIsValid && checklistIsValid && customCheck) {
        return true;
      }
      return false;
    },

    createContent() {
      this.checkValidation().then((res) => {
        if (res) {
          this.$emit('createContent');
          this.isPending = true;
        }
      });
    },

    updateContent() {
      this.checkValidation().then((res) => {
        if (res) {
          this.$emit('updateContent');
          this.isPending = true;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.is-danger {
  border-color: #f27299;
}
.checklist-box {
  width: 100%;
  padding: 25px 50px;
  border-radius: 14px;
  box-shadow: 0 1px 6px 0 rgba(114, 125, 146, 0.2);
  border: solid 2px rgba(114, 125, 146, 0.2);
  background-color: rgba(255, 255, 255, 0.09);
  margin-bottom: 30px;
  position: relative;
}
.check-text-item {
  width: 100%;
  padding: 10px;
  position: relative;
}
</style>
