<template>
  <div
    class="tab-pane show active"
  >
    <div class="form-group form-group--cards">
      <div class="row">
        <div class="col-12">
          <div
            class="d-flex my-2"
            v-if="!editMode"
          >
            <label
              class="form-label check-button mr-4"
              :class="{'active' : videoType === 'videoFile'}"
              @click="$emit('setVideoType', 'videoFile')"
            >
              {{ $t('upload_video') }}
            </label>
            <label
              class="form-label check-button mr-4"
              :class="{'active' : videoType === 'videoUrl'}"
              @click="$emit('setVideoType', 'videoUrl')"
            >
              {{ $t('enter_video_url') }}
            </label>
          </div>
          <div
            class="d-flex my-2"
            v-else
          >
            <label
              v-if="videoType === 'videoFile'"
              class="form-label mr-4"
            >
              {{ $t('upload_video') }}
            </label>
          </div>
          <div
            class="form-group"
            v-if="videoType === 'videoUrl'"
          >
            <label
              for="url"
              class="form-label"
            >{{ $t('video_external_link') }}</label>
            <input
              :class="{ 'is-danger': errors.has('video.file_url') }"
              type="text"
              :disabled="disabled"
              class="form-control"
              id="file_url"
              ref="urlInput"
              v-model="fileUrl"
              data-vv-scope="video"
              data-vv-name="file_url"
              :placeholder="$t('video_external_link_placeholder')"
              :data-vv-as="$t('video_external_link')"
              v-validate="{required: true}"
              @input="handleUrl"
            >
            <span
              v-show="errors.has('video.file_url')"
              class="help is-danger"
            >{{ errors.first('video.file_url') }}</span>
          </div>
          <div
            class="card card--upload"
            v-if="videoType === 'videoUrl' && videoPreview"
          >
            <div
              class="video-container"
            >
              <video
                controls
                ref="videoPlayer"
                volume="0.5"
                @loadedmetadata="videoLoaded"
                class="video"
              >
                <source
                  :src="videoPreview"
                  type="video/mp4"
                >
              </video>
              <button
                class="js-media-delete"
                :disabled="disabled"
                @click="videoPreview = null;fileUrl = null"
              >
                <icon-container
                  :width="20"
                  :height="20"
                  name="trash"
                >
                  <icon-trash />
                </icon-container>
              </button>
            </div>
          </div>
          <div
            class="card card--upload"
            :class="{ 'is-danger': errors.has('video.video') }"
            v-if="videoType === 'videoFile'"
          >
            <div
              v-show="loading"
              class="vloader"
            >
              <svg
                version="1.1"
                id="loader-1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="60px"
                height="60px"
                viewBox="0 0 60 60"
                enable-background="new 0 0 60 60"
                xml:space="preserve"
              >
                <path
                  opacity="0.2"
                  fill="#000"
                  d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
                    s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
                    c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
                />
                <path
                  fill="#000"
                  d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
                    C22.32,8.481,24.301,9.057,26.013,10.047z"
                >
                  <animateTransform
                    attributeType="xml"
                    attributeName="transform"
                    type="rotate"
                    from="0 20 20"
                    to="360 20 20"
                    dur="0.5s"
                    repeatCount="indefinite"
                  />
                </path>
              </svg>
            </div>
            <div
              class="video-container"
              v-if="videoPreview"
            >
              <video
                controls
                ref="videoPlayer"
                volume="0.5"
                @loadedmetadata="videoLoaded"
                class="video"
              >
                <source
                  :src="videoPreview"
                  type="video/mp4"
                >
              </video>
              <button
                class="js-media-delete"
                :disabled="disabled"
                @click="videoRemoved"
              >
                <icon-container
                  :width="20"
                  :height="20"
                  name="trash"
                >
                  <icon-trash />
                </icon-container>
              </button>
            </div>
            <div
              class="video-upload-file"
              v-if="!videoPreview"
            >
              <input
                type="file"
                @change="videoUploaded"
                data-vv-scope="video"
                :disabled="disabled"
                name="video"
                data-vv-name="video"
                :data-vv-as="$t('video')"
                v-validate="'required|size:700000'"
                accept="video/mp4,.mp4,.m4v"
                @input="$emit('input', $event.target.value)"
              >
              <span
                v-if="!loading"
                class="media-object media-object--row"
              >
                <span class="media-object__media">
                  <icon-container
                    name="circleplusfull"
                    view-box="0 0 512 512"
                    :width="45"
                    :height="45"
                    :is-icon-class="false"
                  >
                    <icon-circleplusfull />
                  </icon-container>
                </span>
              </span>
            </div>
          </div>
          <span
            v-if="errors.has('video.video')"
            class="help is-danger"
          >{{ errors.first('video.video') }}</span>
        </div>
      </div>
    </div>
    <div
      v-show="videoPreview"
      class="form-group"
      :class="{ 'is-danger': errors.has('video.title') }"
    >
      <label
        for="contentTitle"
        class="form-label"
      >{{ $t('title').toUpperCase() }}</label>
      <input
        type="text"
        class="form-control"
        id="contentTitle"
        v-model="title"
        :disabled="disabled && !canEditOnlyItem"
        data-vv-scope="video"
        data-vv-name="title"
        :data-vv-as="$t('title')"
        v-validate="'required|max:600'"
        @input="handleTitle"
      >
      <span
        v-show="errors.has('video.title')"
        class="help is-danger"
      >{{ errors.first('video.title') }}</span>
    </div>
    <div
      v-show="videoPreview"
      class="quiz-assubcomponent"
    >
      <div class="title">
        <h5 class="modal-title">
          {{ $t('add_question') }}
        </h5>
        <div
          class="swicth-checkbox"
        >
          <input
            type="checkbox"
            id="isAddingQuestion"
            v-model="isAddingQuestion"
            @change="(e)=>setIsAddingQuestion(e.target.checked)"
            :true-value="1"
            :false-value="0"
          >
          <label
            class="swicth-label"
            for="isAddingQuestion"
          />
        </div>
      </div>
      <div class="warning-box">
        <div class="desc-text">
          <icon-container
            width="27"
            height="30"
            view-box="0 0 27 30"
            name="lamp"
            color="#727d92"
          >
            <icon-lamp />
          </icon-container>
          <div class="description">
            {{ $t('quiz_remark') }}
          </div>
        </div>
      </div>
      <collapse-transition
        :duration="500"
        dimension="height"
        v-show="modalData?.isAddingQuestion"
      >
        <quiz-component
          :disabled="disabledTemp"
          :can-edit-only-item="canEditOnlyItemTemp"
          :quiz-type="'content'"
          as-scope="videoquiz"
          :repetitive_max_question_per_day="repetitiveMaxQuestionPerDayTemp"
          :repetitive_correct_answer_count="repetitiveCorrectAnswerCountTemp"
        />
      </collapse-transition>
    </div>
    <div
      v-show="errors.items.length > 0 && videoPreview"
      class="form-group"
    >
      <div class="alert alert--card alert--error">
        <p>{{ $t('error_msg_title') }}</p>
        <ul>
          <li
            v-for="(error, index) in errors"
            :key="index"
          >
            {{ error.msg }}
          </li>
        </ul>
      </div>
    </div>

    <div class="modal-footer">
      <button
        type="button"
        class="button button--primary"
        @click="editMode ? updateContent() : createContent()"
        :disabled="isPending || (disabled && !canEditOnlyItem)"
      >
        <span
          v-if="!isPending"
        >
          {{ editMode ? $t('update_button') : $t('create_button') }}
        </span>
        <div
          class="lds-ellipsis"
          v-if="isPending"
        >
          <div />
          <div />
          <div />
          <div />
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import IconContainer from '@/components/elements/Icon.vue';
import IconTrash from '@/components/icons/Trash.vue';
import IconCirclePlusFull from '@/components/icons/CirclePlusFull.vue';
import subQuizMixin from '../../../helpers/subQuizMixin.vue';

export default {
  name: 'VideoContent',
  inject: ['$validator'],
  mixins: [subQuizMixin],
  components: {
    'icon-container': IconContainer,
    'icon-trash': IconTrash,
    'icon-circleplusfull': IconCirclePlusFull,
  },

  props: {
    name: {
      type: String,
      default: '',
    },
    videoUrl: {
      type: String,
      default: '',
    },
    videoType: {
      type: String,
      default: 'videoFile',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    canEditOnlyItem: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isPending: false,
      videoPreview: '',
      videoErrorField: false,
      videoDuration: null,
      title: '',
      file: null,
      loading: false,
      fileUrl: null,
      isAddingQuestion: 0,
    };
  },

  watch: {
    modalError: {
      handler(newVal) {
        if (newVal) {
          this.isPending = false;
        }
      },
      immediate: true,
    },
    modalData: {
      handler(newVal) {
        if (newVal) {
          this.isAddingQuestion = newVal.isAddingQuestion;
        }
      },
      immediate: true,
    },
    videoUrl: {
      handler(newVal) {
        if (this.videoType === 'videoUrl') {
          this.fileUrl = newVal;
          this.videoPreview = newVal;
        }

        if (this.editMode) {
          this.videoPreview = newVal;
        }
      },
      immediate: true,
    },

    name: {
      handler(newVal) {
        this.title = newVal;
      },
      immediate: true,
    },

    videoDuration: {
      handler(newVal) {
        this.$emit('videoDuration', newVal);
      },
      immediate: true,
    },
  },

  computed: {
    modalData: {
      get() {
        return this.$store.state.modal.modalData;
      },
      set(newValue) {
        this.$store.state.modal.modalData = newValue;
      },
    },
    modalError() {
      return this.$store.state.modal.formError;
    },
  },
  methods: {
    ...mapActions({
      setIsAddingQuestion: 'modal/SET_ISADDING_QUESTION',
    }),
    async handleUrl(value) {
      const url = value.target.value.trim();
      await this.$validator.validate('video.file_url').then((res) => {
        if (res) {
          if (this.$helpers.isValidUrl(url) && url.toLowerCase().includes('.mp4')) {
            this.$emit('setVideoFileUrl', url);
          } else {
            // alert only not valid urls
            this.$validator.errors.add({
              field: 'file_url',
              msg: this.$t('not_valid_mp4_video_url'),
              scope: 'video',
            });
          }
        }
      });
    },

    async videoUploaded(e) {
      this.$parent.multiProcessProgressBar = true;
      const files = e.target.files || e.dataTransfer.files;
      const file = files[0];
      let preview;
      this.loading = true;
      if (file) {
        preview = await this.readFile(file);
        this.$validator.validate('video.video').then((res) => {
          if (res) {
            this.file = file;
            this.title = this.$helpers.getFileNameOnly(file);
            this.handleTitle();
            this.videoPreview = preview;
            this.$emit('file', file);
            this.$emit('video', preview);
            this.$parent.multiProcessProgressBar = false;
          } else {
            this.$parent.multiProcessProgressBar = false;
          }
        });
      }
    },

    async readFile(file) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          const preview = event.target.result;
          this.loading = false;
          resolve(preview);
        };
      });
    },

    videoRemoved() {
      this.file = null;
      this.videoPreview = '';
      this.videoDuration = null;
      this.$emit('video', '');
    },

    videoLoaded() {
      if (this.$refs.videoPlayer && this.$refs.videoPlayer.duration) {
        this.videoDuration = this.$refs.videoPlayer.duration;
      }
    },

    handleTitle() {
      this.$emit('setTitle', this.title);
    },

    async checkValidation() {
      let allIsValid = false;
      let videoIsValid = false;
      let quizIsValid = false;

      if (this.modalData?.isAddingQuestion) {
        quizIsValid = await this.checkQuizValidation('video');
      } else {
        quizIsValid = true;
      }

      await this.$validator.validateAll('all').then((res) => {
        allIsValid = res;
      });

      await this.$validator.validateAll('video').then((res) => {
        videoIsValid = res;
      });

      if (allIsValid && videoIsValid && quizIsValid) {
        return true;
      }
      return false;
    },

    createContent() {
      this.checkValidation().then((res) => {
        if (res) {
          this.$emit('createContent');
          this.isPending = true;
        }
      });
    },

    async updateContent() {
      if ((this.modalData?.isAddingQuestion === 0 || this.modalData?.isAddingQuestion === false) && this.modalData?.quiz && this.modalData?.quiz?.questions) {
        // eslint-disable-next-line array-callback-return
        await Promise.all(this.modalData?.quiz?.questions.map((question) => {
          this.$store.dispatch('modal/DELETE_QUIZ_QUESTION', question.id);
        }));
        await this.$store.dispatch('modal/DELETE_CONTENT_SUB_QUIZ', this.modalData.quiz.id);
      }
      this.checkValidation().then((res) => {
        if (res) {
          this.$emit('updateContent');
          this.isPending = true;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.video-container {
  width: 100%;
  padding-top: 56.25%;
  height: 0px;
  position: initial;
}

.video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
}

.video-upload-file {
  width: 100%;
  input {
    cursor: pointer;
    z-index: 20;
  }
}
.vloader {
  display: flex;
  left: calc(50% - 30px);
  position: absolute;
  svg path,
  svg rect{
  fill: #f27299;
  }
}
.card.card--upload.is-danger {
  border-color: #f27299;
}
.check-button {
  height:30px;
  padding:10px;
  border: 1px solid #b4bac6;
  border-radius:5px;
  display:flex;
  justify-content: center;
  align-items: center;
  font-size: 12px !important;
  line-height: 16px !important;
  font-weight: 500;
  color: #727d92;
  min-width: 100px;
  input {
    display: none;
  }
  &.active {
    border: 1px solid #f27299;
  }
  &:hover {
    cursor: pointer;
  }
}
</style>
