<template>
  <div>
    <div class="form-group form-group--cards">
      <div
        class="quiz-text-item"
        v-for="(item, qindex) in questions"
        :key="qindex"
      >
        <div
          class="single-update-block"
          v-if="editMode"
        >
          <transition
            name="fade"
            mode="in-out"
          >
            <button
              type="button"
              v-if="isDirty(item)"
              class="button button--alternative text-size-xs size-sm m-0 p-2 float-right"
              @click="saveChanges"
              :disabled="isPending"
            >
              {{ $t('save_changes') }}
            </button>
          </transition>
          <transition
            name="fade"
            mode="in-out"
          >
            <button
              type="button"
              v-if="isUpdated(item)"
              class="button button--secondary shadow-none text-size-xs size-sm m-0 p-2 float-right"
            >
              {{ $t('saved_changes') }}
            </button>
          </transition>
        </div>
        <template v-if="item.type === 'standard'">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
                <label
                  for=""
                  class="form-label"
                  v-if="item.choices[0] && Object.prototype.hasOwnProperty.call(item.choices[0], 'image') && (item.choices[0].image || item.choices[0].choice_text === '-' || Object.prototype.hasOwnProperty.call(item.choices[0], 'imagePreview'))"
                >{{ $t('question') }}
                  {{ qindex + 1 + ': ' + $t('quiz_type_image') }}</label>
                <label
                  for=""
                  class="form-label"
                  v-else
                >{{ $t('question') }} {{ qindex + 1 + ': ' + $t('quiz_type_text') }}</label>
                <textarea
                  :class="{ 'is-danger': errors.has(veeField(`question${qindex}`)) }"
                  @focus="triggerPreviewNextQuestion(qindex)"
                  @blur="checkItemValidate"
                  :disabled="disabled"
                  rows="1"
                  v-resizable
                  class="form-control"
                  :placeholder="$t('question_placeholder')"
                  v-model="item.question"
                  :data-vv-scope="asScope"
                  :key="qindex"
                  :name="'question' + qindex"
                  :data-vv-as="$t('question_') + (qindex + 1)"
                  v-validate.disable="{
                    required: true,
                    min:5,
                    minAnswer: item,
                    oneAnswer: item,
                  }"
                />
                <span
                  :key="'error'+qindex"
                  v-if="errors.has(veeField(`question${qindex}`))"
                  class="help is-danger"
                >{{ errors.first(veeField(`question${qindex}`)) }}</span>
              </div>
            </div>

            <div
              class="col-12 col-md-6"
              v-for="(choice, aindex) in item.choices"
              :key="aindex"
            >
              <div
                class="form-group"
                v-if="choice && Object.prototype.hasOwnProperty.call(choice, 'image') && (choice.image || item.choices[0].choice_text === '-' || Object.prototype.hasOwnProperty.call(choice, 'imagePreview'))"
              >
                <div class="d-flex flex-column">
                  <div>
                    <label
                      for=""
                      class="form-label"
                    >{{ $t('quiz_type_image') }} {{ aindex + 1 }}</label>

                    <thumbnail-container
                      :class="{ 'is-danger': errors.has(`image${qindex}${aindex}`) }"
                      :thumbnail-image="questions[qindex].choices[aindex].imagePreview"
                      :quiz-indexes="{qindex, aindex}"
                      :disabled="disabled"
                      :is-multiple="true"
                      vee-rule="size:5000|mimes:image/jpeg,image/png"
                      vee-scoop-name="quiz"
                      :vee-name="`image${qindex}${aindex}`"
                      :vee-as="$t('quiz_type_image')"
                      @update="imageAdd"
                      @updateMultiple="imageAddMultiple"
                      class="card card--upload"
                    />
                    <span
                      v-show="errors.has(`image${qindex}${aindex}`)"
                      class="help is-danger"
                    >{{ errors.first(`image${qindex}${aindex}`) }}</span>
                  </div>
                  <div>
                    <label
                      for=""
                      class="form-label"
                    >{{ $t('choice') }} {{ aindex + 1 }}</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="choice.choice_text"
                      :disabled="disabled"
                      @blur="checkItemValidate"
                      name="answer"
                      :placeholder="$t('answer_placeholder_optional')"
                      :key="`${qindex}${aindex}`"
                    >
                  </div>
                </div>

                <div class="d-flex justify-content-between align-items-center">
                  <button
                    :disabled="disabled"
                    @click="removeAnswerItem(aindex, qindex)"
                    v-if="item.choices.length > 1"
                  >
                    <icon-container
                      :width="15"
                      :height="15"
                      view-box="0 0 25 25"
                      name="trash"
                      color="#727d92"
                    >
                      <icon-trash />
                    </icon-container>
                  </button>
                  <label
                    :for="qindex+'-answers'"
                    class="checkbox"
                    style="margin-bottom:0;"
                  >
                    <input
                      type="checkbox"
                      :id="aindex"
                      :disabled="disabled"
                      :name="qindex+'-answers'"
                      :key="qindex+'-answers'"
                      :true-value="1"
                      :false-value="0"
                      v-model="choice.is_correct"
                      @change="checkItemValidate"
                    >
                    {{ $t('correct_answer') }}
                  </label>
                </div>
              </div>
              <div
                class="form-group"
                v-else
              >
                <label
                  for=""
                  class="form-label"
                >{{ $t('choice') }} {{ aindex + 1 }}</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="choice.choice_text"
                  :disabled="disabled"
                  name="answer"
                  :placeholder="$t('answer_placeholder')"
                  :data-vv-scope="asScope"
                  :key="`${qindex}${aindex}`"
                  :data-vv-name="`answer${qindex}${aindex}`"
                  :data-vv-as="$t('answer')"
                  v-validate="'required'"
                  @blur="checkItemValidate"
                  @focus="() =>{ addAnswerItem('text', qindex, aindex);triggerPreviewNextQuestion(qindex);}"
                >
                <span
                  v-if="errors.has(veeField(`answer${qindex}${aindex}`))"
                  class="help is-danger"
                >{{ errors.first(veeField(`answer${qindex}${aindex}`)) }}</span>
                <div class="d-flex justify-content-between align-items-center">
                  <button
                    :disabled="disabled"
                    @click="removeAnswerItem(aindex, qindex)"
                    v-if="item.choices.length > 1"
                  >
                    <icon-container
                      :width="15"
                      :height="15"
                      view-box="0 0 25 25"
                      name="trash"
                      color="#727d92"
                    >
                      <icon-trash />
                    </icon-container>
                  </button>
                  <label
                    :for="qindex+'-answers'"
                    class="checkbox"
                    style="margin-bottom:0;"
                  >
                    <input
                      type="checkbox"
                      :id="aindex"
                      :disabled="disabled"
                      :name="qindex+'-answers'"
                      :key="qindex+'-answers'"
                      :true-value="1"
                      :false-value="0"
                      v-model="choice.is_correct"
                      @change="checkItemValidate"
                    >
                    {{ $t('correct_answer') }}
                  </label>
                </div>
              </div>
            </div>
            <div
              class="align-items-center d-flex"
              style="margin: 0 auto"
              v-if="checkNeedForAddAnswer(item.choices)"
            >
              <button
                type="button"
                :disabled="disabled"
                class="button button--alternative button--icon text-size-xs"
                @click.prevent="addAnswerSmart(item.choices, qindex)"
              >
                <icon-container
                  name="circleplusfull"
                  view-box="0 0 512 512"
                  color="#fff"
                  class="icon-svg"
                  :is-icon-class="false"
                >
                  <icon-circleplusfull />
                </icon-container>
                {{ $t('add_answer_button') }}
              </button>
            </div>

            <button
              class="button-remove"
              :disabled="disabled"
              @click="removeQuestionItem(qindex)"
              v-if="item"
            >
              <icon-container
                :width="15"
                :height="15"
                view-box="0 0 25 25"
                name="trash"
                color="#727d92"
              >
                <icon-trash />
              </icon-container>
            </button>
          </div>
        </template>
        <template v-if="item.type === 'pairs'">
          <div
            class="row"
            :set="pairIndex = 0"
          >
            <div class="col-12">
              <div class="form-group">
                <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
                <label
                  for=""
                  class="form-label"
                >{{ $t('question') }} {{ qindex + 1 + ': ' + $t('quiz_type_pairs') }}</label>
                <textarea
                  :class="{ 'is-danger': errors.has(veeField(`question${qindex}`)) }"
                  @focus="triggerPreviewNextQuestion(qindex)"
                  @blur="checkItemValidate"
                  :disabled="disabled"
                  rows="1"
                  v-resizable
                  class="form-control"
                  :placeholder="$t('question_placeholder')"
                  v-model="item.question"
                  :data-vv-scope="asScope"
                  :key="qindex"
                  :name="'question' + qindex"
                  :data-vv-as="$t('question_') + (qindex + 1)"
                  v-validate.disable="{
                    required: true,
                    min:5,
                    minAnswer: item,
                    oneAnswer: item,
                  }"
                />
                <span
                  :key="'error'+qindex"
                  v-if="errors.has(veeField(`question${qindex}`))"
                  class="help is-danger"
                >{{ errors.first(veeField(`question${qindex}`)) }}</span>
              </div>
            </div>

            <div
              class="col-12 col-md-6"
              v-for="(choice, aindex) in item.choices"
              :key="aindex"
            >
              <div
                :class="(aindex % 2 === 0) ? 'form-group first-pair' : 'form-group'"
                :set="(aindex % 2 === 0) ? ++pairIndex : null "
              >
                <label
                  :class="(aindex % 2 === 0) ? 'form-label' : 'form-label  second-pair'"
                >
                  {{ (aindex % 2 === 0) ? `${$t('pair')} ${pairIndex}` : $t('pair_second_item_placehoder') }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  v-model="choice.choice_text"
                  :disabled="disabled"
                  name="answer"
                  :placeholder="(aindex % 2 === 0) ? $t('answer_placeholder_quiz_pairs_first') : $t('answer_placeholder_quiz_pairs_second')"
                  :data-vv-scope="asScope"
                  :key="`${qindex}${aindex}`"
                  :data-vv-name="`answer${qindex}${aindex}`"
                  :data-vv-as="$t('answer')"
                  v-validate="'required'"
                  @blur="checkItemValidate"
                  @focus="addAnswerItem('pairs', qindex, aindex);triggerPreviewNextQuestion(qindex)"
                >
                <span
                  v-if="errors.has(veeField(`answer${qindex}${aindex}`))"
                  class="help is-danger"
                >{{ errors.first(veeField(`answer${qindex}${aindex}`)) }}</span>
                <div class="d-flex justify-content-between align-items-center">
                  <button
                    :disabled="disabled"
                    @click="removeAnswerItem(aindex, qindex)"
                    v-if="item.choices.length > 2 && aindex % 2 === 0 && pairIndex > 2"
                  >
                    <icon-container
                      :width="15"
                      :height="15"
                      view-box="0 0 25 25"
                      name="trash"
                      color="#727d92"
                    >
                      <icon-trash />
                    </icon-container>
                  </button>
                </div>
              </div>
            </div>

            <button
              class="button-remove"
              :disabled="disabled"
              @click="removeQuestionItem(qindex)"
              v-if="item"
            >
              <icon-container
                :width="15"
                :height="15"
                view-box="0 0 25 25"
                name="trash"
                color="#727d92"
              >
                <icon-trash />
              </icon-container>
            </button>
          </div>
        </template>
      </div>
    </div>
    <div class="modal-footer">
      <div class="row">
        <div class="col-12 col-md-4">
          <button
            type="button"
            :disabled="disabled"
            class="button button--alternative button--icon u-full text-size-xs size-sm"
            @click.prevent="addQuestion('text')"
          >
            <icon-container
              name="circleplusfull"
              view-box="0 0 512 512"
              color="#fff"
              class="icon-svg"
              :is-icon-class="false"
            >
              <icon-circleplusfull />
            </icon-container>
            {{ $t('add_button_quiz_text') }}
          </button>
        </div>
        <div class="col-12 col-md-4">
          <button
            type="button"
            :disabled="disabled"
            class="button button--alternative button--icon u-full text-size-xs size-sm"
            @click.prevent="addQuestion('image')"
          >
            <icon-container
              name="circleplusfull"
              view-box="0 0 512 512"
              color="#fff"
              class="icon-svg"
              :is-icon-class="false"
            >
              <icon-circleplusfull />
            </icon-container>
            {{ $t('add_button_quiz_image') }}
          </button>
        </div>
        <div class="col-12 col-md-4">
          <button
            type="button"
            :disabled="disabled"
            class="button button--alternative button--icon u-full text-size-xs size-sm"
            @click.prevent="addQuestion('pairs')"
          >
            <icon-container
              name="circleplusfull"
              view-box="0 0 512 512"
              color="#fff"
              class="icon-svg"
              :is-icon-class="false"
            >
              <icon-circleplusfull />
            </icon-container>
            {{ $t('add_button_quiz_pairs') }}
          </button>
        </div>
      </div>
      <span
        v-if="errors.has(veeField('question'))"
        class="help is-danger"
      >{{ errors.first(veeField('question')) }}</span>
    </div>
  </div>
</template>

<script>
import { Validator } from 'vee-validate';
import ThumbnailContainer from '@/components/Thumbnail.vue';
import { i18n } from '@/helpers/i18n';
import IconTrash from '@/components/icons/Trash.vue';
import IconContainer from '@/components/elements/Icon.vue';
import IconCirclePlusFull from '@/components/icons/CirclePlusFull.vue';

Validator.extend('minAnswer', {
  getMessage() {
    return i18n.t('quiz_min_awswer');
  },
  validate(value, obj) {
    if (obj && obj.choices && obj.choices.length < 2) {
      return false;
    }
    return true;
  },
});

Validator.extend('oneAnswer', {
  getMessage() {
    return i18n.t('quiz_one_awswer');
  },
  validate(value, obj) {
    const answerChecked = [];
    if (obj && obj.choices) {
      obj.choices.forEach((answer) => { // check if one answer is marked as correct
        answerChecked.push((answer.is_correct));
      });
      if (!answerChecked.includes(1)) {
        return false;
      }
      return true;
    }
    return true;
  },
});

export default {
  name: 'QuizComponent',
  inject: ['$validator'],
  components: {
    'thumbnail-container': ThumbnailContainer,
    'icon-container': IconContainer,
    'icon-trash': IconTrash,
    'icon-circleplusfull': IconCirclePlusFull,
  },
  directives: {
    resizable: {
      inserted(el) {
        el.addEventListener('input', (e) => {
          e.target.style.height = 'auto';
          e.target.style.height = `${e.target.scrollHeight}px`;
        });
      },
    },
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    canEditOnlyItem: {
      type: Boolean,
      default: false,
    },
    quizType: {
      type: String,
      default: '',
    },
    repetitiveMaxQuestionPerDay: {
      type: Number,
      default: 3,
    },
    repetitiveCorrectAnswerCount: {
      type: Number,
      default: 2,
    },
    asScope: {
      type: String,
      default: 'all',
    },
  },

  data() {
    return {
      isPending: false,
      questions: [],
      quizTypeTemp: '',
      repetitiveMaxQuestionPerDayTemp: 0,
      repetitiveCorrectAnswerCountTemp: 0,
      questionsCloned: [],
      dirtyList: null,
      updatedList: [],
      idHolder: null,
      snotifyQueueList: [],
      queindex: null,
    };
  },

  computed: {
    modalData: {
      get() {
        return this.$store.state.modal.modalData;
      },
      set(newValue) {
        this.$store.state.modal.modalData = newValue;
      },
    },
    formError: {
      get() {
        return this.$store.state.modal.formError;
      },
      set(newValue) {
        this.$store.state.modal.formError = newValue;
      },
    },
    editMode() {
      return this.$store.state.modal.editMode;
    },
  },

  watch: {
    modalData: {
      handler(newVal) {
        const $newVal = newVal;
        console.log('v2 item modal watch:', $newVal, `questions lenght: ${$newVal?.quiz?.questions?.length}`);
        if ($newVal) {
          if (this.editMode) {
            if ($newVal.quiz) {
              console.log('editing...');
              this.quizTypeTemp = $newVal.quiz.quiz_type;
              this.repetitiveCorrectAnswerCountTemp = $newVal.quiz.repetitive_correct_answer_count;
              this.repetitiveMaxQuestionPerDayTemp = $newVal.quiz.repetitive_max_question_per_day;
              this.questions = $newVal?.quiz?.questions || [];
              this.hasPreDefinedSubQuiz = !!(this.questions && this.questions.length);
              console.log(`hasPreDefinedSubQuiz ---> v2 modal --- vedat*->: ${this.hasPreDefinedSubQuiz}`);
              this.$nextTick(() => {
                console.log('next tickkked');
                this.$root.$emit('setHasPreDefinedSubQuizV2', this.hasPreDefinedSubQuiz);
                this.$root.$emit('setHasPreDefinedSubQuiz', this.hasPreDefinedSubQuiz);
              });
              if (this.hasPreDefinedSubQuiz) {
                this.questions.forEach(async (q) => {
                  if (q.type === 'standard') {
                    const e = q.choices[0];
                    // determine whether it is image or text
                    if (e && e.image && q.choices[q.choices.length - 1].image !== '') { // ok it's image
                      // prepare imagepreviews

                      q.choices.map((obj) => { // eslint-disable-next-line no-param-reassign
                        obj.imagePreview = obj.image;
                        return obj;
                      });// eslint-disable-line no-return-assign

                      q.choices.push({ // push the blank answer field for image
                        is_correct: 0,
                        choice_text: '',
                        image: '',
                        imagePreview: '',
                      });
                    } else if (e && e.choice_text && e.choice_text !== '-' && !e.image && q.choices[q.choices.length - 1].choice_text !== '') {
                      q.choices.push({
                        choice_text: '',
                        is_correct: 0,
                      });
                    }
                  } else { // so the type is pair
                    // first sort the pairs
                    const newArray = [];

                    q.choices.forEach((item) => {
                      let index = null;
                      const found = q.choices.find(element => element.choice_pair_id === item.id);
                      if (found) {
                        newArray.push(item);
                        newArray.push(found);
                        index = q.choices.findIndex(element => element.choice_pair_id === item.id);
                        q.choices.splice(index, 1);
                      }
                    });

                    q.choices = newArray;// eslint-disable-line no-param-reassign
                    q.choices.push({ // push blank answer fields for pair
                      choice_text: '',
                      is_correct: 1,
                      choice_pair_id: null,
                    }, {
                      choice_text: '',
                      is_correct: 1,
                      choice_pair_id: null,
                    });
                  }
                });
              }
              this.prepareDirty();
            }
          }
        }
      },
      immediate: true,
    },

    formError: {
      handler(newVal) {
        if (newVal) {
          this.isPending = false;
        }
      },
      immediate: true,
    },

    quizType: {
      handler(newvalue) {
        this.quizTypeTemp = newvalue;
        this.$parent.quizType = newvalue;
      },
    },
    dirtyList: {
      handler(newVal) {
        this.$root.$emit('updateDirtyList', newVal);
      },
      immediate: true,
    },
    repetitiveMaxQuestionPerDay: {
      handler(newvalue) {
        this.repetitiveMaxQuestionPerDayTemp = newvalue;
        this.$parent.repetitiveMaxQuestionPerDay = newvalue;
      },
    },

    repetitiveCorrectAnswerCount: {
      handler(newvalue) {
        this.repetitiveCorrectAnswerCountTemp = newvalue;
        this.$parent.repetitiveCorrectAnswerCount = newvalue;
      },
    },

    quizTypeTemp: {
      handler(newvalue) {
        this.$parent.quizType = newvalue;
      },
      immediate: true,
    },

    repetitiveMaxQuestionPerDayTemp: {
      handler(newvalue) {
        this.$parent.repetitiveMaxQuestionPerDay = newvalue;
      },
      immediate: true,
    },

    repetitiveCorrectAnswerCountTemp: {
      handler(newvalue) {
        this.$parent.repetitiveCorrectAnswerCount = newvalue;
      },
      immediate: true,
    },

  },

  async mounted() {
    if (!this.editMode) {
      this.addQuestion('text');
    } else if (this.modalData && this.modalData.quiz && this.modalData.quiz.questions) {
      const items = this.dash.cloneDeep(this.modalData.quiz.questions);
      this.questionsCloned = items;
      this.$nextTick(() => {
        [...document.querySelectorAll('textarea')].forEach((textarea) => {
          // eslint-disable-next-line no-param-reassign
          textarea.style.height = `${textarea.scrollHeight}px`;
        });
      });
    }
  },

  methods: {
    async saveChanges() {
      let customFN = null;
      if (this.quizType === 'content') {
        customFN = this.$parent.$parent.checkValidation();
      } else {
        customFN = this.$parent.checkValidation();
      }
      customFN.then(async (res) => {
        if (res) {
          this.isPending = true;
          const payload = { questions: [], content_id: null };
          payload.content_id = this.modalData.id;
          if (this.dirtyList) {
            this.dirtyList.forEach((element) => {
              payload.questions.push(element);
            });
          }
          await this.$store.dispatch('modal/UPDATE_QUIZ_QUESTION', payload).then(async () => {
            if (!this.$store.state.modal.formError) {
              this.questionsCloned = await this.dash.cloneDeep(this.questions);
              this.isPending = false;
              this.dirtyList = [];
              setTimeout(() => {
                this.updatedList = payload.questions;
              }, 1000);
              setTimeout(() => {
                this.updatedList = [];
              }, 3000);
            }
          });
        } else {
          console.log('Parent validation failed', res);
        }
      });
    },

    isDirty(item) {
      if (this.dirtyList) {
        const found = this.dirtyList.find(element => item.id && element.id === item.id);
        if (found) {
          return true;
        }
      }
      return false;
    },

    isUpdated(item) {
      if (this.updatedList) {
        const found = this.updatedList.find(element => element.id === item.id);
        if (found) {
          console.log('isUpdated');
          return true;
        }
      }
      return false;
    },
    checkNeedForAddAnswer(choices) {
      if (!choices || choices === undefined) return false;
      const needed = choices.filter(c => ((Object.prototype.hasOwnProperty.call(c, 'image') && c.image === '') || (!Object.prototype.hasOwnProperty.call(c, 'image') && c.choice_text === '')));
      // console.log('checkNeedForAddAnswer:', needed, (needed.length === 0));
      return (needed.length === 0);
    },
    addAnswerSmart(choices, qindex) {
      if (choices) {
        const hasImageField = choices.filter(c => Object.prototype.hasOwnProperty.call(c, 'image'));
        const aindex = choices.length - 1;
        if (hasImageField && hasImageField.length > 0) {
          this.addAnswerItem('image', qindex, aindex);
        } else {
          this.addAnswerItem('text', qindex, aindex);
        }
      }
    },
    emitQuestions() {
      this.$root.$emit('setQuestions', this.questions);
      if (this.editMode) {
        this.prepareDirty();
      }
    },

    async prepareDirty() {
      const items = await this.dash.cloneDeep(this.questions);
      this.dirtyList = await this.dash.differenceWith(items, this.questionsCloned, this.dash.isEqual);
    },

    veeField(val) {
      if (this.asScope) {
        return `${this.asScope}.${val}`.toString();
      }
      return `all.${val}`.toString();
    },

    triggerPreviewNextQuestion(qindex) {
      console.log('triggerPreviewNextQuestion');
      this.$emit('triggerPreviewNextQuestion', qindex - 1);
    },

    checkItemValidate() {
      this.emitQuestions();
      if (this.errors.items.length > 0) {
        this.$validator.validateAll(`${this.asScope}`);
      }
    },

    addQuestion(questionType) { // type: text, image, or pairs
      switch (questionType) {
        case 'text':
          this.questions.push({
            question: '',
            type: 'standard',
            choices: [
              {
                choice_text: '',
                is_correct: 0,
              },
              {
                choice_text: '',
                is_correct: 0,
              },
            ],
          });
          break;
        case 'image':
          this.questions.push({
            question: '',
            type: 'standard',
            choices: [
              {
                choice_text: '',
                is_correct: 0,
                image: '',
                imagePreview: '',
              },
              {
                choice_text: '',
                is_correct: 0,
                image: '',
                imagePreview: '',
              },
            ],
          });
          break;
        case 'pairs':
          this.questions.push({
            question: '',
            type: 'pairs',
            choices: [
              {
                choice_text: '',
                is_correct: 1,
                choice_pair_id: null,
              },
              {
                choice_text: '',
                is_correct: 1,
                choice_pair_id: null,
              },
              {
                choice_text: '',
                is_correct: 1,
                choice_pair_id: null,
              },
              {
                choice_text: '',
                is_correct: 1,
                choice_pair_id: null,
              },
            ],
          });
          break;
        default:
          break;
      }
      this.emitQuestions();
    },

    addAnswerItem(type, qindex = 0, aindex = 0) {
      const q = this.questions[qindex];
      if (q && aindex === q.choices.length - 1) {
        if (type === 'text') {
          q.choices.push({
            choice_text: '',
            is_correct: 0,
          });
        }
        if (type === 'image') {
          q.choices.push({
            choice_text: '',
            image: '',
            imagePreview: '',
            is_correct: 0,
          });
        }
        if (type === 'pairs') {
          q.choices.push({
            choice_text: '',
            is_correct: 1,
            choice_pair_id: null,
          }, {
            choice_text: '',
            is_correct: 1,
            choice_pair_id: null,
          });
        }
      }
      this.emitQuestions();
    },

    removeAnswerItem(aindex, qindex) {
      const removeAnswerId = this.questions[qindex].choices[aindex].id;
      const { type } = this.questions[qindex];
      if (type === 'standard') {
        this.deleteAnswerItem(aindex, qindex, removeAnswerId);
      } else if (type === 'pairs') {
        const pairAnswerId = this.questions[qindex].choices[aindex + 1].id;
        this.deleteAnswerItem(aindex, qindex, pairAnswerId).then(() => this.deleteAnswerItem(aindex, qindex, removeAnswerId));
      }
      this.emitQuestions();
    },

    async deleteAnswerItem(aindex, qindex, removeAnswerId = null) {
      if (removeAnswerId) {
        await this.$store.dispatch('modal/DELETE_QUIZ_ANSWER', removeAnswerId).then(() => {
          if (!this.formError) {
            this.questions[qindex].choices.splice(aindex, 1);
          } else {
            this.formError = false;
          }
        });
      } else {
        this.questions[qindex].choices.splice(aindex, 1);
      }
      this.emitQuestions();
    },

    removeQuestionItem(qindex) {
      const removeQestionId = this.questions[qindex].id;
      this.queindex = qindex;
      if (removeQestionId) {
        if (!this.snotifyQueueList.includes(removeQestionId)) {
          this.idHolder = removeQestionId;
          this.snotifyQueueList.push(removeQestionId);
          this.$snotify.confirm(this.$t('question_delete_msg_body'), this.$t('question_delete_msg_title'), {
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            buttons: [
              {
                text: this.$t('yes'),
                action: (toast) => {
                  this.deleteConfirm(toast, this.idHolder, qindex);
                },
                bold: false,
              },
              {
                text: this.$t('no'),
                action: (toast) => {
                  this.deleteCancel(toast, this.idHolder);
                },
              },
            ],
          }).on('shown', (toast) => {
            this.toastHolder = toast;
            document.addEventListener('keydown', this.eventHandler, false);
          }).on('hidden', (toast) => {
            this.toastHolder = toast;
            document.removeEventListener('keydown', this.eventHandler, false);
          });
        }
      } else {
        this.questions.splice(qindex, 1);
      }
      this.emitQuestions();
    },

    deleteConfirm(toast, itemId, qindex) {
      this.$snotify.remove(toast.id);
      const snotifyIndex = this.snotifyQueueList.indexOf(itemId);
      if (snotifyIndex > -1) {
        this.snotifyQueueList.splice(snotifyIndex, 1);
      }
      this.$store.dispatch('modal/DELETE_QUIZ_QUESTION', itemId).then(() => {
        if (!this.formError) {
          this.questions.splice(qindex, 1);
        } else {
          this.formError = false;
        }
        if (this.editMode && this.questions && this.questions.length === 0) {
          if (this.quizType === 'content' && this.modalData && this.modalData.quiz && this.modalData.quiz.id) {
            this.$store.dispatch('modal/DELETE_CONTENT_SUB_QUIZ', this.modalData.quiz.id);
          }
        }
      });
      this.emitQuestions();
    },

    deleteCancel(toast, itemId) {
      const snotifyIndex = this.snotifyQueueList.indexOf(itemId);
      if (snotifyIndex > -1) {
        this.snotifyQueueList.splice(snotifyIndex, 1);
      }
      this.$snotify.remove(toast.id);
    },

    eventHandler(event) {
      event.preventDefault();
      const key = event.key || event.keyCode;
      if (key === 'Enter' || key === 13) {
        this.deleteConfirm(this.toastHolder, this.idHolder, this.queindex);
      } else if (key === 'Escape' || key === 'Esc' || key === 27) {
        this.deleteCancel(this.toastHolder, this.idHolder);
      }
    },
    imageAdd(data) {
      let preview = null;
      let file = null;
      let index = null;
      [preview, file, index] = data;
      if (index != null || index !== undefined) {
        if (file) {
          this.questions[index.qindex].choices[index.aindex].imagePreview = preview;
          this.questions[index.qindex].choices[index.aindex].image = file;
          this.addAnswerItem('image', index.qindex, index.aindex);
        } else {
          this.questions[index.qindex].choices[index.aindex].imagePreview = '';
          this.questions[index.qindex].choices[index.aindex].image = '';
          if (this.questions[index.qindex].choices.length > 1) {
            this.removeAnswerItem(index.aindex, index.qindex);
          }
        }
      }
      this.emitQuestions();
    },

    imageAddMultiple(data) {
      let preview = null;
      let file = null;
      let index = null;
      [preview, file, index] = data;
      if (preview && file) {
        this.addAnswerItem('image', index.qindex, index.aindex);
        this.questions[index.qindex].choices[index.aindex].imagePreview = preview;
        this.questions[index.qindex].choices[index.aindex].image = file;
      }
      this.emitQuestions();
    },
  },
};
</script>

<style lang="scss" scoped>
.quiz-text-item {
  width: 100%;
  padding: 25px 50px;
  border-radius: 14px;
  box-shadow: 0 1px 6px 0 rgba(114, 125, 146, 0.2);
  border: solid 2px rgba(114, 125, 146, 0.2);
  background-color: rgba(255, 255, 255, 0.09);
  margin-bottom: 30px;
  position: relative;

  .button-remove {
    opacity: 0;
    position: absolute;
    right: 10px;
    bottom: 10px;
  }

  &:hover {
    .button-remove {
      opacity: 1;
    }
  }

  ol {
    list-style: upper-alpha inside;
  }

  .form-label {
    @include primary-medium;
    font-size: 12px;
    color: #727d92;
  }

  .checkbox {
    @include primary-medium;
    padding: 5px;
    font-size: 12px;
    letter-spacing: 0.38px;
    color: #727d92;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    input {
      margin-right: 5px;
    }
  }
}
.is-danger {
  border-color: #f27299;
}
.form-label.second-pair {
  color: #fff!important;
}
.form-group.first-pair:after {
  content: ' - ';
  color:#727d92;
  position: absolute;
  top: 35px;
  right: -4px;
}
::v-deep textarea.form-control {
  min-height: 44px;
  height: 100%;
  overflow: hidden;
  resize:vertical;
}

</style>
