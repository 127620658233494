<template>
  <div class="page">
    <app-header
      :child-header-navigation="childHeaderNavigation"
      is-child-header-left-actions
    >
      <div class="button-search ml-4">
        <div class="search-box">
          <span
            v-if="filterText"
            class="icon icon-reset"
            @click="resetFilter"
          >
            <icon-container
              name="reset"
              view-box="0 0 20 20"
            >
              <icon-reset />
            </icon-container>
          </span>
          <span
            @click="doFilter()"
            class="icon-search"
          >
            <icon-container
              name="search"
              :width="14"
              :height="14"
              color="#727d92"
              view-box="0 0 14 14"
            >
              <icon-search />
            </icon-container>
          </span>
          <input
            type="text"
            ref="searchBox"
            class="form-control"
            v-sanitize
            :placeholder="$t('search_placeholder')"
            v-model="filterText"
            @keydown="doFilter()"
          >
        </div>
      </div>
    </app-header>
    <nav class="navigation navigation--ternary">
      <div class="container">
        <ul
          class="navigation__list nav"
          id="myJourneys"
          role="tablist"
        >
          <li class="navigation__item">
            <button
              class="navigation__link"
              :class="{ 'active': activeJourneyTab==='private'}"
              @click="activeJourneyTab='private'"
            >
              {{ $t('private_journey') }}
            </button>
          </li>
          <li class="navigation__item">
            <button
              class="navigation__link"
              :class="{ 'active': activeJourneyTab==='public'}"
              @click="activeJourneyTab='public'"
            >
              {{ $t('public_journey') }}
            </button>
          </li>
        </ul>
      </div>
    </nav>
    <main class="page__body">
      <div
        v-if="isLoading"
        class="lds-dual-ring"
      />
      <div class="container-fluid container-cards mb-4">
        <div class="tab-content">
          <div
            class="tab-pane"
            v-show="activeJourneyTab === 'private'"
          >
            <transition-group
              name="slide-in"
              tag="div"
              class="d-flex flex-wrap justify-center items-center px-4"
              :style="{ '--total': 20 }"
            >
              <div
                class="card card--create card-button"
                key="createCard"
                @click="openModal"
              >
                <icon-container
                  :width="82"
                  :height="82"
                  name="plus"
                  color="#E3E3E3"
                  view-box="0 0 448 448"
                >
                  <icon-plus />
                </icon-container>
                <div class="card__footer">
                  <span
                    class="button button--border size-sm"
                    role="button"
                  >{{
                    $t("create_a_journey")
                  }}</span>
                </div>
              </div>
              <div
                v-for="(item, index) in privateJourneyList"
                :key="item.id"
                class="card card--catalog"
                :class="{ is_public: item.is_public }"
                :style="{ '--i': index % perPage }"
              >
                <div
                  v-if="isItemNew(item.activation_date)"
                  class="ribbon"
                >
                  <span>{{ $t("new") }}</span>
                </div>
                <router-link
                  :to="{
                    name: 'JourneyItems',
                    params: {
                      journeyId: item.id || item.options,
                      title: item.name,
                    },
                  }"
                >
                  <figure
                    class="card__media_home"
                    :style="{ backgroundImage: `url('${item.thumbnail_image}')` }"
                  />
                  <div class="card__body">
                    <h3
                      class="card__title"
                      :title="item.name"
                    >
                      {{ item.name | truncate }}
                    </h3>
                  </div>
                </router-link>
                <div class="card__footer">
                  <span
                    v-if="item.duplicated_journey_id === null || item.duplicated_journey_id === undefined"
                    @click.prevent="modalAssign(item)"
                    class="button button--border button--border-light text-size-xs size-sm button--icon"
                    role="button"
                  >
                    <span class="icon">
                      <icon-container
                        name="assign"
                        view-box="0 0 14 14"
                        :is-icon-class="false"
                      >
                        <icon-assign />
                      </icon-container>
                    </span>
                    {{ $t("assign") }}
                  </span>
                  <div
                    class="dropdown"
                    @mouseover="dropdownToggle(index, 'Private')"
                    @mouseleave="hideDropdown"
                  >
                    <span class="card--actions dropdown__toggle">
                      <icon-container
                        name="threedotsvertical"
                        :width="5"
                        :height="22"
                        view-box="0 0 5 22"
                      >
                        <icon-threedotsvertical />
                      </icon-container>
                    </span>
                    <div
                      class="dropdown__menu"
                      :ref="`dropdownBox${index}Private`"
                      v-if="dropdownIndex === index + 'Private'"
                      v-on-clickaway="hideDropdown"
                    >
                      <span
                        v-if="item.duplicated_journey_id === null || item.duplicated_journey_id === undefined"
                        class="dropdown__button button"
                        @click="createPacketCode(item.id)"
                      >
                        <icon-container
                          name="password"
                          :width="22"
                          :height="22"
                          view-box="0 0 32 32"
                          color="#727d92"
                        >
                          <icon-password />
                        </icon-container>
                        {{ $t("create_packet_code") }}
                      </span>
                      <span
                        class="dropdown__button button"
                        @click="requestAssignedUserReport(item)"
                      >
                        <icon-container
                          name="content"
                          view-box="0 0 18 17"
                          :is-icon-class="false"
                        >
                          <icon-dashboard />
                        </icon-container>
                        {{ $t("request_assigned_user_report") }}
                      </span>
                      <span
                        class="dropdown__button button"
                        @click="exportContentListReport(item)"
                      >
                        <icon-container
                          name="content"
                          view-box="0 0 15 14"
                          :is-icon-class="false"
                        >
                          <icon-download />
                        </icon-container>
                        {{ $t("download_journey_content_report") }}
                      </span>
                      <span
                        class="dropdown__button button"
                        @click="duplicateJourney(item.id)"
                      >
                        <icon-container
                          name="duplicate"
                          view-box="0 0 15 15"
                        >
                          <icon-duplicate />
                        </icon-container>
                        {{ $t("duplicate") }}
                      </span>
                      <span
                        class="dropdown__button button"
                        @click="newDuplicateJourney(item.id)"
                      >
                        <icon-container
                          name="new_journey_duplicate"
                          view-box="0 0 15 15"
                        >
                          <icon-duplicate />
                        </icon-container>
                        {{ $t("new_journey_duplicate") }}
                      </span>
                      <span
                        class="dropdown__button button"
                        @click="addToJourneyGroup(item)"
                      >
                        <icon-container
                          width="18"
                          height="18"
                          view-box="0 0 24 24"
                          name="cplus"
                          color="#727d92"
                        >
                          <icon-circleplus />
                        </icon-container>
                        {{ $t('add_to_journey_group') }}
                      </span>
                      <span
                        class="dropdown__button button"
                        @click="editJourney(item.id, 'mainTab')"
                      >
                        <icon-container
                          name="setting"
                          view-box="0 0 15 15"
                        >
                          <icon-setting />
                        </icon-container>
                        {{ $t("settings") }}
                      </span>
                      <span
                        class="dropdown__button button"
                        @click="deleteJourney(index, item)"
                        v-if="!canNotEdit(item.is_public)"
                      >
                        <icon-container
                          name="trash"
                          view-box="0 0 24 24"
                        >
                          <icon-trash />
                        </icon-container>
                        {{ $t("delete") }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </transition-group>
            <Observer
              v-if="!isLoading"
              @intersect="privateIntersected"
            />
          </div>

          <div
            class="tab-pane"
            v-show="activeJourneyTab === 'public'"
          >
            <div
              v-if="isPublicLoading"
              class="lds-dual-ring"
            />
            <transition-group
              name="slide-in"
              tag="div"
              class="d-flex flex-wrap justify-center items-center px-4"
              :style="{ '--total': publicJourneyList ? publicJourneyList.length : 0 }"
            >
              <div
                class="card card--create card-button"
                key="createCardPublic"
                @click="openModal"
              >
                <icon-container
                  :width="82"
                  :height="82"
                  name="plus"
                  color="#E3E3E3"
                  view-box="0 0 448 448"
                >
                  <icon-plus />
                </icon-container>
                <div class="card__footer">
                  <span
                    class="button button--border size-sm"
                    role="button"
                  >{{
                    $t("create_a_journey")
                  }}</span>
                </div>
              </div>
              <div
                v-for="(item, index) in publicJourneyList"
                :key="item.id"
                class="card card--catalog is_public"
                :style="{ '--i': index}"
              >
                <div
                  v-if="isItemNew(item.activation_date)"
                  class="ribbon"
                >
                  <span>{{ $t("new") }}</span>
                </div>
                <router-link
                  :to="{
                    name: 'JourneyItems',
                    params: {
                      journeyId: item.id || item.options,
                      title: item.name,
                    },
                  }"
                >
                  <figure
                    class="card__media_home"
                    :style="{ backgroundImage: `url('${item.thumbnail_image}')` }"
                  />
                  <div class="card__body">
                    <h3 class="card__title">
                      {{ item.name | truncate }}
                    </h3>
                  </div>
                </router-link>
                <div class="card__footer">
                  <span
                    @click.prevent="modalAssign(item)"
                    class="button button--border button--border-light text-size-xs size-sm button--icon"
                    role="button"
                  >
                    <span class="icon">
                      <icon-container
                        name="assign"
                        view-box="0 0 14 14"
                        :is-icon-class="false"
                      >
                        <icon-assign />
                      </icon-container>
                    </span>
                    {{ $t("assign") }}
                  </span>
                  <div
                    class="dropdown"
                    @mouseover="dropdownToggle(index, 'Public')"
                    @mouseleave="hideDropdown"
                  >
                    <span class="card--actions dropdown__toggle">
                      <icon-container
                        name="threedotsvertical"
                        :width="5"
                        :height="22"
                        view-box="0 0 5 22"
                      >
                        <icon-threedotsvertical />
                      </icon-container>
                    </span>
                    <div
                      class="dropdown__menu"
                      :ref="`dropdownBox${index}Public`"
                      v-if="dropdownIndex === index + 'Public'"
                      v-on-clickaway="hideDropdown"
                    >
                      <span
                        class="dropdown__button button"
                        @click="createPacketCode(item.id)"
                      >
                        <icon-container
                          name="password"
                          :width="22"
                          :height="22"
                          view-box="0 0 32 32"
                          color="#727d92"
                        >
                          <icon-password />
                        </icon-container>
                        {{ $t("create_packet_code") }}
                      </span>
                      <span
                        class="dropdown__button button"
                        @click="requestAssignedUserReport(item)"
                      >
                        <icon-container
                          name="content"
                          view-box="0 0 18 17"
                          :is-icon-class="false"
                        >
                          <icon-dashboard />
                        </icon-container>
                        {{ $t("request_assigned_user_report") }}
                      </span>
                      <span
                        class="dropdown__button button"
                        @click="exportContentListReport(item)"
                      >
                        <icon-container
                          name="content"
                          view-box="0 0 15 14"
                          :is-icon-class="false"
                        >
                          <icon-download />
                        </icon-container>
                        {{ $t("download_journey_content_report") }}
                      </span>
                      <span
                        class="dropdown__button button"
                        @click="duplicateJourney(item.id)"
                      >
                        <icon-container
                          name="duplicate"
                          view-box="0 0 15 15"
                        >
                          <icon-duplicate />
                        </icon-container>
                        {{ $t("duplicate") }}
                      </span>
                      <span
                        class="dropdown__button button"
                        @click="editJourney(item.id, 'mainTab')"
                      >
                        <icon-container
                          name="setting"
                          view-box="0 0 15 15"
                        >
                          <icon-setting />
                        </icon-container>
                        {{ $t("settings") }}
                      </span>
                      <span
                        class="dropdown__button button"
                        @click="deleteJourney(index, item)"
                        v-if="!canNotEdit(item.is_public)"
                      >
                        <icon-container
                          name="trash"
                          view-box="0 0 24 24"
                        >
                          <icon-trash />
                        </icon-container>
                        {{ $t("delete") }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </transition-group>
            <Observer
              v-if="!isPublicLoading"
              @intersect="publicIntersected"
            />
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import moment from 'moment';
import { directive as onClickaway } from 'vue-clickaway';
import IconContainer from '@/components/elements/Icon.vue';
import IconPlus from '@/components/icons/Plus.vue';
import IconDuplicate from '@/components/icons/Duplicate.vue';
import IconSetting from '@/components/icons/Setting.vue';
import IconTrash from '@/components/icons/Trash.vue';
import IconSearch from '@/components/icons/Search.vue';
import IconReset from '@/components/icons/Reset.vue';
import IconAssign from '@/components/icons/Assign.vue';
import IconDownload from '@/components/icons/Download.vue';
import IconDashboard from '@/components/icons/Dashboard.vue';
import IconCirclePlus from '@/components/icons/CirclePlus.vue';
import IconPassword from '@/components/icons/Password.vue';
import IconThreedotsVertical from '@/components/icons/ThreedotsVertical.vue';
import AppHeader from '@/components/AppHeader.vue';
import API from '@/services/';
import {
  API_JOURNEY_CATALOG,
  API_JOURNEY_COPY,
  API_POST_JOURNEY_V2_FROM_JOURNEY,
  API_USER_GROUP_JOURNEY_REPORT,
  API_JOURNEY_UPDATE,
  API_POST_JOURNEY,
  API_JOURNEY_REPORT,
  API_SERVER_TIME_FORMAT,
} from '@/helpers/config';
import Observer from '@/components/Observer.vue';

console.log('Journey');

export default {
  name: 'Journey',

  components: {
    'app-header': AppHeader,
    'icon-container': IconContainer,
    'icon-plus': IconPlus,
    'icon-duplicate': IconDuplicate,
    'icon-setting': IconSetting,
    'icon-circleplus': IconCirclePlus,
    'icon-trash': IconTrash,
    'icon-reset': IconReset,
    'icon-download': IconDownload,
    'icon-search': IconSearch,
    'icon-password': IconPassword,
    'icon-dashboard': IconDashboard,
    'icon-assign': IconAssign,
    'icon-threedotsvertical': IconThreedotsVertical,
    Observer,
  },

  data() {
    return {
      isDropdown: null,
      dropdownIndex: null,
      journeyName: 'all',
      notificationTitle: '',
      notificationBody: '',
      timer: null,
      snotifyQueueList: [],
      filterText: '',
      searchFor: null,
      activeJourneyTab: 'private',
      isLoading: false,
      isPublicLoading: false,
      idHolder: null,
      toastHolder: null,
      debounce: null,
      publicJourneyList: null,
      privateJourneyList: null,
      privateJourneyTotalPage: 0,
      publicJourneyTotalPage: 0,
      perPage: 10,
      currentPublicPage: 0,
      currentPrivatePage: 0,
    };
  },

  deactivated() {
    console.log('Journey has been deactivated');
  },

  beforeDestroy() {
    console.log('Journey has been before destroyed');
    this.publicJourneyList = null;
    this.privateJourneyList = null;
    this.timer = null;
    this.snotifyQueueList = [];
  },

  directives: {
    'on-clickaway': onClickaway,
  },

  filters: {
    truncate(input) {
      return input.length > 32 ? `${input.substring(0, 30)}...` : input;
    },
  },

  computed: {
    childHeaderNavigation() {
      const pathsTemp = [
        {
          text: this.$t('catalog'),
          url: '/',
        },
        {
          text: this.$t('journey_group'),
          url: '/journeygroup',
        },
        {
          text: this.$t('planned_journey'),
          url: '/plannedjourney',
        },
      ];
      // if (this.isVibonsAdmin) {
      //  pathsTemp.push(
      //    {
      //      text: this.$t('planned_journey'),
      //      url: '/plannedjourney',
      //    },
      //  );
      // }
      return pathsTemp;
    },

    refreshPage: {
      get() {
        return this.$store.state.modal.refreshPage;
      },

      set(newValue) {
        this.$store.state.modal.refreshPage = newValue;
      },
    },

    pageToRefresh: {
      get() {
        return this.$store.state.modal.pageToRefresh;
      },

      set(newValue) {
        this.$store.state.modal.pageToRefresh = newValue;
      },
    },

    currentCustomerId() {
      return this.$store.state.auth.customerId;
    },

    isVibonsAdmin() {
      return this.$store.state.auth.isVibonsAdmin;
    },

  },

  watch: {
    activeJourneyTab: {
      handler(newVal) {
        if (newVal === 'public' && !this.isPublicLoading && this.currentPublicPage === 0) {
          this.getJourneys(1, newVal);
        }
      },
      immediate: false,
    },
    refreshPage(newValue) {
      if (newValue && this.pageToRefresh === 'Journey') {
        this.getJourneys(1, this.activeJourneyTab);
        this.refreshPage = false;
        this.pageToRefresh = null;
      }
    },
    currentCustomerId: {
      handler(newVal) {
        if (newVal) {
          this.getJourneys(1, this.activeJourneyTab);
        }
      },
      immediate: true,
    },
  },

  methods: {
    privateIntersected() {
      if (!this.isLoading && this.privateJourneyTotalPage) {
        const nextPage = this.currentPrivatePage + 1;
        const p = this.privateJourneyTotalPage / this.perPage || 1;
        if (nextPage <= Math.ceil(p)) {
          this.getJourneys(nextPage, 'private');
        }
        console.log('intersected nextPage:', nextPage, 'this.journeyTotalPage:', this.privateJourneyTotalPage);
      }
    },
    publicIntersected() {
      if (!this.isPublicLoading && this.publicJourneyTotalPage) {
        const nextPage = this.currentPublicPage + 1;
        const p = this.publicJourneyTotalPage / this.perPage || 1;
        if (nextPage <= Math.ceil(p)) {
          this.getJourneys(nextPage, 'public');
        }
        console.log('intersected nextPage:', nextPage, 'this.journeyTotalPage:', this.publicJourneyTotalPage);
      }
    },
    hideDropdown() {
      this.isDropdown = null;
      this.timer = setTimeout(() => {
        this.dropdownIndex = null;
      }, 750);
    },

    addToJourneyGroup(item) {
      const obj = {
        modalType: 'modalAddJourneyToJourneyGroup',
        journeyId: item.id,
        journeyCustomerId: this.currentCustomerId,
      };
      this.$helpers.toggleModal(obj);
    },

    isItemNew(itemDate) {
      const today = moment().startOf('day');
      const theDate = moment(itemDate, API_SERVER_TIME_FORMAT);
      const diffDays = today.diff(theDate, 'days', false);
      if (diffDays <= 15) {
        return true;
      }
      return false;
    },

    canNotEdit(item) {
      if (!item) {
        return false;
      }
      if (this.isVibonsAdmin) {
        return false;
      }
      return true;
    },

    dropdownAction(value) {
      this.isDropdown = value;
    },

    dropdownToggle(index, type) {
      clearTimeout(this.timer);
      this.dropdownIndex = index + type;
      setTimeout(() => {
        const elem = this.$refs[`dropdownBox${index}${type}`].length > 0
          ? this.$refs[`dropdownBox${index}${type}`][0]
          : null;
        if (elem) {
          const isOut = this.$helpers.isOutOfScreen(elem);
          if (isOut.right) {
            elem.classList.add('right');
          } else if (isOut.left) {
            elem.classList.add('left');
          } else if (isOut.bottom) {
            elem.classList.add('bottomjourney');
          } else if (isOut.top) {
            elem.classList.add('top');
          }
        }
      }, 1);
    },

    doFilter() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.searchFor = this.filterText;
        this.getJourneys(1, this.activeJourneyTab);
      }, 300);
    },

    resetFilter() {
      this.searchFor = null;
      this.filterText = '';
      this.getJourneys(1, this.activeJourneyTab);
    },

    createPacketCode(itemId) {
      const obj = {
        modalType: 'modalPackageCode',
        launchedFrom: 'Journey',
        journeyId: itemId,
      };
      this.$helpers.toggleModal(obj);
    },

    requestAssignedUserReport(data) {
      const itemId = data.id;
      const obj = {
        modalType: 'modalReportDownload',
        reportDownloadUrl: `${API_USER_GROUP_JOURNEY_REPORT}`,
        dateOnly: false,
        reportDownloadPayload: {
          customer_id: this.currentCustomerId,
          journey_id: itemId,
        },
      };
      this.$helpers.toggleModal(obj);
    },

    exportContentListReport(data) {
      const itemId = data.id;
      const obj = {
        modalType: 'modalReportDownload',
        reportDownloadUrl: `${API_JOURNEY_REPORT}/${itemId}`,
        reportDownloadRequest: 'get',
        dateOnly: false,
        reportDownloadPayload: {},
      };
      this.$helpers.toggleModal(obj);
    },

    newDuplicateJourney(id) {
      this.hideDropdown();

      if (!this.snotifyQueueList.includes(id)) {
        this.snotifyQueueList.push(id);
        this.$snotify
          .confirm(
            this.$t('journey_duplicate'), // Mesaj içeriği
            {
              showProgressBar: false,
              closeOnClick: false,
              pauseOnHover: false,
              buttons: [
                {
                  text: this.$t('yes'),
                  action: (toast) => {
                    this.executeDuplicateJourney(id); // Kopyalama işlemini çalıştır
                    this.$snotify.remove(toast.id); // Toast'u kaldır
                  },
                  bold: false,
                },
                {
                  text: this.$t('no'),
                  action: (toast) => {
                    this.$snotify.remove(toast.id); // Toast'u kaldır
                  },
                },
              ],
            },
          )
          .on('shown', (toast) => {
            this.toastHolder = toast;
            document.addEventListener('keydown', this.eventHandler, false);
          })
          .on('hidden', (toast) => {
            this.toastHolder = toast;
            document.removeEventListener('keydown', this.eventHandler, false);
          });
      }
    },


    executeDuplicateJourney(id) {
      const formData = new FormData();
      formData.append('journey_id', id);

      API.post(API_POST_JOURNEY_V2_FROM_JOURNEY, formData)
        .then((response) => {
          if (response.status === 200) {
            this.$snotify.success(this.$t('duplicated_success'));
            this.StatusMessage = 'SUCCESS';
          }
        })
        .catch((error) => {
          this.$helpers.displayError(error);
        });
    },


    duplicateJourney(id) {
      this.hideDropdown();
      const formData = new FormData();
      formData.append('journey_id', id);

      API.post(API_JOURNEY_COPY, formData)
        .then((response) => {
          if (response.status === 200) {
            // const item = response.data.journey;
            // if (item) {
            //   this.updatedJourney(item);
            // }
            this.pageToRefresh = 'Journey';
            this.$store.commit('modal/REFRESH_PAGE', true);
          }
        })
        .catch((error) => {
          this.$helpers.displayError(error);
        });
    },

    updatedJourney(data) {
      const item = data;
      this.hideDropdown();
      const time = moment().format(API_SERVER_TIME_FORMAT);
      item.activation_date = time;

      const formData = new FormData();
      formData.append('journey_id', item.id); // eslint-disable-next-line
      formData.append("name", `${item.name} copy`);
      formData.append('activation_date', item.activation_date);
      formData.append('lang', item.lang);
      formData.append('is_list_on_the_catalog_enabled', 1);
      formData.append('is_welcome_notifications_enabled', 0);

      API.post(API_JOURNEY_UPDATE, formData)
        .then((response) => {
          if (response.status === 200) {
            if (item.name.search('copy') !== -1 || item.name.search('copy') === -1) {
              // eslint-disable-next-line
              item.name = `${item.name} copy`;
            }
            const newItem = {
              id: item.id,
              name: item.name,
              content_count: item.total_content_count,
              total_content_count: item.total_content_count,
              thumbnail_image: item.template_thumbnail_image,
              is_public: item.is_public,
              activation_date: item.activation_date,
            };
            if (this.activeJourneyTab === 'private') {
              this.privateJourneyList.unshift(newItem);
            } else {
              this.publicJourneyList.unshift(newItem);
            }
            window.scrollTo(0, 0);
            this.$snotify.success(response.data.message);
          }
        })
        .catch((error) => {
          this.$helpers.displayError(error);
        });
    },

    deleteJourney(index, item) {
      const { id } = item;
      this.idHolder = id;
      this.hideDropdown();
      if (!this.snotifyQueueList.includes(id)) {
        this.snotifyQueueList.push(id);
        this.$snotify
          .confirm(
            this.$t('journey_delete_msg_body', { name: item.name }),
            this.$t('journey_delete_msg_title'),
            {
              showProgressBar: false,
              closeOnClick: false,
              pauseOnHover: false,
              buttons: [
                {
                  text: this.$t('yes'),
                  action: (toast) => {
                    this.deleteConfirm(toast, id);
                  },
                  bold: false,
                },
                {
                  text: this.$t('no'),
                  action: (toast) => {
                    this.deleteCancel(toast, id);
                  },
                },
              ],
            },
          )
          .on('shown', (toast) => {
            this.toastHolder = toast;
            document.addEventListener('keydown', this.eventHandler, false);
          })
          .on('hidden', (toast) => {
            this.toastHolder = toast;
            document.removeEventListener('keydown', this.eventHandler, false);
          });
      }
    },

    async getJourneys(page = 1, jtype = 'private') {
      if (this.currentCustomerId) {
        if (jtype === 'private') {
          if (page === 1) this.privateJourneyList = null;
          this.isLoading = true;
        } else {
          if (page === 1) this.publicJourneyList = null;
          this.isPublicLoading = true;
        }

        let apiurl = `${API_JOURNEY_CATALOG}?is_public=${jtype === 'private' ? 0 : 1}&customer_id=${this.currentCustomerId}&per_page=${this.perPage}&page=${page}`;

        let responseData = null;
        let responseMeta = null;
        if (this.searchFor) {
          apiurl = `${API_JOURNEY_CATALOG}?is_public=${jtype === 'private' ? 0 : 1}&customer_id=${this.currentCustomerId}&per_page=100&page=1&search=${this.searchFor}`;
        }
        await API.get(apiurl)
          .then((response) => {
            if (response.status === 200) {
              responseData = response.data.data;
              responseMeta = response.data.meta;
            }
          })
          .catch((error) => {
            this.$helpers.displayError(error);
            this.isLoading = false;
            this.isPublicLoading = false;
          });

        if (responseData && responseMeta) {
          if (jtype === 'private') {
            if (page === 1) {
              this.privateJourneyList = responseData;
            } else {
              this.privateJourneyList = this.privateJourneyList.concat(responseData);
            }
            this.currentPrivatePage = page;
            this.privateJourneyTotalPage = responseMeta.total;
            this.isLoading = false;
          } else {
            if (page === 1) {
              this.publicJourneyList = responseData;
            } else {
              this.publicJourneyList = this.publicJourneyList.concat(responseData);
            }
            this.currentPublicPage = page;
            this.publicJourneyTotalPage = responseMeta.total;
            this.isPublicLoading = false;
          }
        }
      }
    },

    openModal() {
      const obj = {
        modalType: 'modalJourney',
        launchedFrom: 'Journey',
        tabPane: 'mainTab',
      };
      this.$helpers.toggleModal(obj);
    },

    editJourney(itemid, tabPane = 'mainTab') {
      if (itemid) {
        const obj = {
          modalType: 'modalJourney',
          journeyId: itemid,
          launchedFrom: 'Journey',
          pageToRefresh: 'Journey',
          tabPane,
          editMode: true,
        };
        this.$helpers.toggleModal(obj);
      }
    },

    modalAssign(item) {
      const id = item.id || item.options;
      let modalType = 'modalAssign';
      if (
        item
        && item.journey_type
        && (item.journey_type === 2 || item.journey_type === 'ON_BOARDING')
      ) {
        modalType = 'modalOnboardingAssign';
      }
      console.log('Assigning journey item info:', item, id, modalType);
      this.$store.commit('modal/setJourneyId', id);
      this.$helpers.toggleModal(modalType);
    },

    deleteConfirm(toast, id) {
      this.$snotify.remove(toast.id);
      const snotifyIndex = this.snotifyQueueList.indexOf(id);
      if (snotifyIndex > -1) {
        this.snotifyQueueList.splice(snotifyIndex, 1);
      }
      API.delete(`${API_POST_JOURNEY}/${id}`)
        .then((response) => {
          if (response.status === 200) {
            this.$snotify.success(response.data.message);
            if (this.activeJourneyTab === 'private') {
              const elementPos = this.privateJourneyList.map(elm => elm.id).indexOf(id);
              this.privateJourneyList.splice(elementPos, 1);
            } else {
              const elementPos = this.publicJourneyList.map(elm => elm.id).indexOf(id);
              this.publicJourneyList.splice(elementPos, 1);
            }
          }
        })
        .catch((error) => {
          this.$helpers.displayError(error);
        });
    },

    deleteCancel(toast, id) {
      const snotifyIndex = this.snotifyQueueList.indexOf(id);
      if (snotifyIndex > -1) {
        this.snotifyQueueList.splice(snotifyIndex, 1);
      }
      this.$snotify.remove(toast.id);
    },

    eventHandler(event) {
      event.preventDefault();
      const key = event.key || event.keyCode;
      if (key === 'Enter' || key === 13) {
        this.deleteConfirm(this.toastHolder, this.idHolder);
      } else if (key === 'Escape' || key === 'Esc' || key === 27) {
        this.deleteCancel(this.toastHolder, this.idHolder);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card__footer .button {
  margin: 0 auto;
}

.card__footer .dropdown {
  position: relative;
  margin-left: auto;
}

.card__footer .dropdown__menu {
  position: absolute;
  top: 100%;
  right: 0;
  display: none;
  z-index: 1;
}
.card__footer span.button:hover {
  cursor: pointer !important;
}
.card__footer .dropdown:hover .dropdown__menu {
  display: block;
}

.filter-active {
  background-color: #2dcf85;
  svg path {
    fill: #fff;
  }
}
.button-search {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .search-box {
    position: relative;
    width: 100%;
    input {
      background: #ffffff;
      padding-left: 20px;
      padding-right: 40px;
      height: 36px;
      color: #333;
      min-width: 205px;
      max-width: 300px;
    }
  }
  .icon-search {
    position: absolute;
    right: 10px;
    top: 15px;
    cursor: pointer;
  }
  .icon-reset {
    position: absolute;
    left: 5px;
    top: 15px;
    cursor: pointer;

    svg {
      width: 13px;
      height: 13px;
    }
  }
}
.journey-title {
  font-family: "Work Sans", sans-serif;
  font-weight: 500;
  color: #727d92;
  padding: 10px 0;
  margin-bottom: 10px;
}

.collapse {
  margin-bottom: 2px;
  label {
    cursor: pointer;
  }
}
.collapse .collapse-header {
  margin-bottom: 10px;
  position: relative;
}

.collapse .collapse-header::before {
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  content: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='chevron-down' class='svg-inline--fa fa-chevron-down fa-w-14' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='%23727d92' d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'%3E%3C/path%3E%3C/svg%3E");
  display: inline-block;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  width: 0.8em;
  padding-right: 3px;
}
.collapse.is-active .collapse-header::before {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
.margin-left-15 {
  margin-left: -15px;
}
</style>
