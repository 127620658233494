<template>
  <div
    class="tab-pane show active"
  >
    <div class="form-group form-group--cards">
      <div
        class="check-text-item"
        ref="formItem"
      >
        <div class="row">
          <div class="col-12">
            <div
              class="form-group  mt-5"
            >
              <fileupload-container
                :class="{ 'is-danger': errors.has(`pdfupload.file`) }"
                :file-url="cdn_url"
                :disabled="disabled"
                :is-pdf="true"
                :file-index="0"
                :is-multiple="false"
                vee-rule="required|size:60000"
                vee-scoop-name="pdfupload"
                :vee-name="`file0`"
                :vee-as="$t('file')"
                @update="fileAdd"
                class="card cardfile--upload"
              />
              <span
                v-if="errors.has(`pdfupload.file0`)"
                class="help is-danger"
              >{{ errors.first(`pdfupload.file0`) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="form-group"
      :class="{ 'is-danger': errors.has('pdfupload.title') }"
    >
      <label
        for="contentTitle"
        class="form-label"
      >{{ $t('title').toUpperCase() }}</label>
      <input
        type="text"
        class="form-control"
        id="contentTitle"
        v-model="title"
        :disabled="disabled && !canEditOnlyItem"
        data-vv-scope="pdfupload"
        data-vv-name="title"
        :data-vv-as="$t('title')"
        v-validate.disable="'required|max:100'"
        @input="handleTitle"
      >
      <span
        v-show="errors.has('pdfupload.title')"
        class="help is-danger"
      >{{ errors.first('pdfupload.title') }}</span>
    </div>
    <div
      v-show="cdn_url"
      class="quiz-assubcomponent"
    >
      <div class="title">
        <h5 class="modal-title">
          {{ $t('add_question') }}
        </h5>
        <div
          class="swicth-checkbox"
        >
          <input
            type="checkbox"
            id="isAddingQuestion"
            v-model="isAddingQuestion"
            @change="(e)=>setIsAddingQuestion(e.target.checked)"
            :true-value="1"
            :false-value="0"
          >
          <label
            class="swicth-label"
            for="isAddingQuestion"
          />
        </div>
      </div>
      <div class="warning-box">
        <div class="desc-text">
          <icon-container
            width="27"
            height="30"
            view-box="0 0 27 30"
            name="lamp"
            color="#727d92"
          >
            <icon-lamp />
          </icon-container>
          <div class="description">
            {{ $t('quiz_remark') }}
          </div>
        </div>
      </div>
      <collapse-transition
        :duration="500"
        dimension="height"
        v-show="modalData?.isAddingQuestion"
      >
        <quiz-component
          :disabled="disabledTemp"
          :can-edit-only-item="canEditOnlyItemTemp"
          :quiz-type="'content'"
          as-scope="externallinkquiz"
          :repetitive_max_question_per_day="repetitiveMaxQuestionPerDayTemp"
          :repetitive_correct_answer_count="repetitiveCorrectAnswerCountTemp"
        />
      </collapse-transition>
    </div>
    <div
      v-show="errors.items.length > 0"
      class="form-group"
    >
      <div class="alert alert--card alert--error">
        <p>{{ $t('error_msg_title') }}</p>
        <ul>
          <li
            v-for="(error, index) in errors"
            :key="index"
          >
            {{ error.msg }}
          </li>
        </ul>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="button button--primary"
        @click="editMode ? updateContent() : createContent()"
        :disabled="isPending || (disabled && !canEditOnlyItem)"
      >
        <span
          v-if="!isPending"
        >
          {{ editMode ? $t('update_button') : $t('create_button') }}
        </span>
        <div
          class="lds-ellipsis"
          v-if="isPending"
        >
          <div />
          <div />
          <div />
          <div />
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import FileUploadContainer from '@/components/FileUpload.vue';
import subQuizMixin from '@/helpers/subQuizMixin.vue';

export default {
  name: 'PdfUploadContent',
  inject: ['$validator'],
  mixins: [subQuizMixin],

  components: {
    'fileupload-container': FileUploadContainer,
  },

  props: {
    externalUrl: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    canEditOnlyItem: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      title: '',
      isPending: false,
      cdn_url: '',
      isAddingQuestion: 0,
    };
  },

  directives: {
    resizable: {
      inserted(el) {
        el.addEventListener('input', (e) => {
          e.target.style.height = 'auto';
          e.target.style.height = `${e.target.scrollHeight}px`;
        });
      },
    },
  },
  computed: {
    modalData: {
      get() {
        return this.$store.state.modal.modalData;
      },
      set(newValue) {
        this.$store.state.modal.modalData = newValue;
      },
    },
    formError: {
      get() {
        return this.$store.state.modal.formError;
      },
      set(newValue) {
        this.$store.state.modal.formError = newValue;
      },
    },
  },

  watch: {
    modalData: {
      handler(newVal) {
        const $newVal = newVal;
        if ($newVal) {
          this.isAddingQuestion = newVal.isAddingQuestion;
          if (this.editMode) {
            console.log('hanfle data', newVal);
          }
        }
      },
      immediate: true,
    },

    formError: {
      handler(newVal) {
        if (newVal) {
          this.isPending = false;
        }
      },
      immediate: true,
    },

    name: {
      handler(newVal) {
        this.title = newVal;
      },
      immediate: true,
    },
  },

  mounted() {
    if (this.editMode) {
      this.cdn_url = this.externalUrl;
    }
  },

  methods: {
    ...mapActions({
      setIsAddingQuestion: 'modal/SET_ISADDING_QUESTION',
    }),
    fileAdd(data) {
      // eslint-disable-next-line no-unused-vars
      let preview = null;
      let file = null;
      let index = null;
      [preview, file, index] = data;
      console.log('file:', file, index);
      if (index != null || index !== undefined) {
        if (file) {
          this.$emit('setPdfFile', file);
          this.cdn_url = file.name;
        } else {
          this.cdn_url = null;
        }
      }
    },

    handleTitle() {
      this.$emit('setTitle', this.title);
    },
    clearErrorMsg() {
      this.$validator.errors.clear();
    },

    async checkValidation() {
      let allIsValid = false;
      let pdfuploadIsValid = false;
      this.clearErrorMsg();

      if (this.modalData?.isAddingQuestion) {
        pdfuploadIsValid = await this.checkQuizValidation('pdfupload');
      } else {
        pdfuploadIsValid = true;
      }
      await this.$validator.validateAll('all').then((res) => {
        allIsValid = res;
      });
      await this.$validator.validateAll('pdfupload').then((res) => {
        pdfuploadIsValid = res;
      });
      if (allIsValid && pdfuploadIsValid) {
        return true;
      }
      return false;
    },

    createContent() {
      this.checkValidation().then((res) => {
        if (res) {
          this.$emit('createContent');
          this.isPending = true;
        }
      });
    },

    async updateContent() {
      if ((this.modalData?.isAddingQuestion === 0 || this.modalData?.isAddingQuestion === false) && this.modalData?.quiz && this.modalData?.quiz?.questions) {
        // eslint-disable-next-line array-callback-return
        await Promise.all(this.modalData?.quiz?.questions.map((question) => {
          console.log('delete..', question.id);
          this.$store.dispatch('modal/DELETE_QUIZ_QUESTION', question.id);
        }));
        await this.$store.dispatch('modal/DELETE_CONTENT_SUB_QUIZ', this.modalData.quiz.id);
      }
      this.checkValidation().then((res) => {
        if (res) {
          this.$emit('updateContent');
          this.isPending = true;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.is-danger {
  border-color: #f27299;
}
.pdfupload-box {
  width: 100%;
  padding: 25px 50px;
  border-radius: 14px;
  box-shadow: 0 1px 6px 0 rgba(114, 125, 146, 0.2);
  border: solid 2px rgba(114, 125, 146, 0.2);
  background-color: rgba(255, 255, 255, 0.09);
  margin-bottom: 30px;
  position: relative;
}

::v-deep textarea.form-control {
  min-height: 44px;
  height: 100%;
  overflow: hidden;
  resize:vertical;
}
</style>
