<script>
import CollapseTransition from '@ivanv/vue-collapse-transition';
import QuizComponent from '@/components/QuizComponent.vue';
import IconContainer from '@/components/elements/Icon.vue';
import IconLamp from '@/components/icons/Lamp.vue';

console.log('Subquizmixing');

export default {
  data() {
    return {
    };
  },
  components: {
    'quiz-component': QuizComponent,
    'collapse-transition': CollapseTransition,
    'icon-container': IconContainer,
    'icon-lamp': IconLamp,
  },
  watch: {

  },
  computed: {
    editMode() {
      return this.$store.state.modal.editMode;
    },
    repetitiveMaxQuestionPerDayTemp() {
      return this.repetitiveMaxQuestionPerDay;
    },
    repetitiveCorrectAnswerCountTemp() {
      return this.repetitiveCorrectAnswerCount;
    },
    canEditOnlyItemTemp() {
      return this.canEditOnlyItem;
    },
    disabledTemp() {
      return this.disabled;
    },
  },
  mounted() {

  },
  methods: {
    async checkQuizValidation(scope) {
      let quizIsValid = false;
      let customCheck = true;
      await this.$parent.quizCleanBeforeValidate();
      this.clearErrorMsg();
      if (this.$parent.questions && this.$parent.questions.length < 1) { // check at least one question exist
        this.$validator.errors.add({
          field: 'question',
          msg: this.$t('quiz_one_question'),
          scope: `${scope}quiz`,
        });
        customCheck = false;
      }

      await this.$validator.validateAll(`${scope}quiz`).then((res) => {
        quizIsValid = res;
      });
      if (quizIsValid && customCheck) {
        return true;
      }
      return false;
    },

    clearErrorMsg() {
      if (this.$validator && this.$validator.errors) {
        this.$validator.errors.clear();
      }
    },
  },
};
</script>
