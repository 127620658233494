<template>
  <div
    class="tab-pane show active"
  >
    <div class="col-12">
      <div
        class="d-flex my-2"
        v-if="!editMode"
      >
        <label
          class="form-label check-button mr-4"
          :class="{'active' : surveyContentType === 'survey'}"
          @click="switchSurveyType('survey')"
        >
          {{ $t('survey') }}
        </label>
        <label
          class="form-label check-button mr-4"
          :class="{'active' : surveyContentType === 'nps'}"
          @click="switchSurveyType('nps')"
        >
          {{ $t('nps') }}
        </label>
      </div>
      <div class="form-group form-group--cards">
        <draggable-comp
          class="survey-listings"
          v-model="questions"
          v-bind="dragOptions"
          handle=".button-handle"
          :disabled="surveyContentType === 'nps'"
          @start="dragStart"
          @end="dragEnd"
        >
          <transition-group name="ilist">
            <div
              class="survey-text-item"
              v-for="(item, qindex) in questions"
              :key="`k${qindex}`"
            >
              <span
                :class="`button-handle ${surveyContentType === 'nps' ? 'disabled':''}`"
              >
                <icon-container
                  name="handle"
                  width="25"
                  view-box="0 0 25 18"
                  :is-icon-class="false"
                >
                  <icon-handle />
                </icon-container>
              </span>
              <div
                class="single-update-block"
                v-if="editMode"
              >
                <transition
                  name="fade"
                  mode="in-out"
                >
                  <button
                    type="button"
                    v-if="isDirty(item)"
                    class="button button--alternative text-size-xs size-sm m-0 p-2 float-right"
                    @click="saveChanges()"
                    :disabled="isPending"
                  >
                    {{ $t('save_changes') }}
                  </button>
                </transition>
                <transition
                  name="fade"
                  mode="in-out"
                >
                  <button
                    type="button"
                    v-if="isUpdated(item)"
                    class="button button--secondary shadow-none text-size-xs size-sm m-0 p-2 float-right"
                  >
                    {{ $t('saved_changes') }}
                  </button>
                </transition>
              </div>
              <div class="row">
                <div class="col-12">
                  <div
                    class="form-group"
                  >
                    <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
                    <label
                      for=""
                      class="form-label"
                    >{{ $t('question') }} {{ qindex + 1 }}</label>
                    <textarea
                      :class="{ 'is-danger': errors.has(`survey.question${qindex}`) }"
                      rows="1"
                      v-resizable
                      @input.once="triggerPreviewNextQuestion(qindex)"
                      :disabled="disabled"
                      class="form-control"
                      :placeholder="$t('question_placeholder')"
                      v-model="item.question"
                      data-vv-scope="survey"
                      :key="qindex"
                      :name="'question' + qindex"
                      :data-vv-as="$t('question_') + (qindex + 1)"
                      v-validate.disable="{
                        required: true,
                        max: 1000,
                        min:5,
                        minSurveyAnswer: item,
                      }"
                    />
                    <span
                      :key="'error'+qindex"
                      v-if="errors.has(`survey.question${qindex}`)"
                      class="help is-danger"
                    >{{ errors.first(`survey.question${qindex}`) }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div
                    class="form-group"
                  >
                    <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
                    <label
                      for=""
                      class="form-label"
                    >{{ $t('description') }}</label>
                    <textarea
                      :class="{ 'is-danger': errors.has(`survey.sub_text${qindex}`) }"
                      rows="1"
                      v-resizable
                      :disabled="disabled"
                      class="form-control"
                      :placeholder="$t('type_your_desciption')"
                      v-model="item.sub_text"
                      data-vv-scope="survey"
                      :name="'sub_text' + qindex"
                      :data-vv-as="$t('description') + (qindex + 1)"
                      v-validate.disable="{
                        max: 400,
                      }"
                    />
                    <span
                      :key="'derror'+qindex"
                      v-if="errors.has(`survey.sub_text${qindex}`)"
                      class="help is-danger"
                    >{{ errors.first(`survey.sub_text${qindex}`) }}</span>
                  </div>
                </div>
              </div>
              <div v-if="item.type === 'single-choice' || item.type === 'multiple-choice'">
                <ol
                  type="A"
                  class="row"
                >
                  <li
                    class="col-12 col-md-6 form-label"
                    v-for="(choice, aindex) in item.choices"
                    :key="aindex"
                  >
                    <div
                      class="form-group"
                      v-if="choice && Object.prototype.hasOwnProperty.call(choice, 'imagePreview')"
                    >
                      <div class="d-flex flex-column">
                        <div class="mb-2">
                          <thumbnail-container
                            :class="{ 'is-danger': errors.has(`imageanswer${qindex}${aindex}`) }"
                            :thumbnail-image="questions[qindex].choices[aindex].imagePreview"
                            :quiz-indexes="{qindex, aindex}"
                            :disabled="disabled"
                            :is-multiple="true"
                            vee-rule="required|size:5000|mimes:image/jpeg,image/png"
                            vee-scoop-name="survey"
                            :vee-name="`imageanswer${qindex}${aindex}`"
                            :vee-as="$t('quiz_type_image')"
                            @update="imageAdd"
                            @updateMultiple="imageAddMultiple"
                            class="card card--upload"
                          />
                          <span
                            v-show="errors.has(`imageanswer${qindex}${aindex}`)"
                            class="help is-danger"
                          >{{ errors.first(`imageanswer${qindex}${aindex}`) }}</span>
                        </div>
                        <div>
                          <input
                            type="text"
                            class="form-control"
                            :disabled="disabled"
                            v-model="choice.choice_text"
                            name="answer"
                            :placeholder="$t('answer_placeholder_text')"
                            :key="`${qindex}${aindex}`"
                            data-vv-scope="survey"
                            :data-vv-name="`answer${qindex}${aindex}`"
                            :data-vv-as="$t('answer')"
                            v-validate="'required|max:100'"
                          >
                          <span
                            v-if="errors.has(`survey.answer${qindex}${aindex}`)"
                            class="help is-danger"
                          >{{ errors.first(`survey.answer${qindex}${aindex}`) }}</span>
                          <div class="d-flex justify-content-between align-items-start mt-2">
                            <button
                              :disabled="disabled"
                              @click="removeAnswerItem(aindex, qindex)"
                              v-if="item.choices.length > 1"
                            >
                              <icon-container
                                :width="15"
                                :height="15"
                                view-box="0 0 25 25"
                                name="trash"
                                color="#727d92"
                              >
                                <icon-trash />
                              </icon-container>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      v-else
                      class="form-group"
                    >
                      <input
                        type="text"
                        class="form-control"
                        :disabled="disabled"
                        v-model="choice.choice_text"
                        name="answer"
                        :placeholder="$t('answer_placeholder')"
                        data-vv-scope="survey"
                        :key="`${qindex}${aindex}`"
                        :data-vv-name="`answer${qindex}${aindex}`"
                        :data-vv-as="$t('answer')"
                        v-validate="'required|max:100'"
                        @input="addAnswerItem(qindex, aindex);triggerPreviewNextQuestion(qindex)"
                      >
                      <span
                        v-if="errors.has(`survey.answer${qindex}${aindex}`)"
                        class="help is-danger"
                      >{{ errors.first(`survey.answer${qindex}${aindex}`) }}</span>
                      <div class="d-flex justify-content-between align-items-start mt-2">
                        <button
                          :disabled="disabled"
                          @click="removeAnswerItem(aindex, qindex)"
                          v-if="item.choices.length > 1"
                        >
                          <icon-container
                            :width="15"
                            :height="15"
                            view-box="0 0 25 25"
                            name="trash"
                            color="#727d92"
                          >
                            <icon-trash />
                          </icon-container>
                        </button>
                      </div>
                    </div>
                  </li>
                  <li
                    class="col-12 col-md-6  form-label"
                    v-if="checkNeedForAddAnswer(item.choices)"
                  >
                    <button
                      type="button"
                      :disabled="disabled"
                      class="button button--alternative button--icon text-size-xs form-group"
                      @click.prevent="addAnswerSmart(item.choices, qindex)"
                    >
                      <icon-container
                        name="circleplusfull"
                        view-box="0 0 512 512"
                        color="#fff"
                        class="icon-svg"
                        :is-icon-class="false"
                      >
                        <icon-circleplusfull />
                      </icon-container>
                      {{ $t('add_answer_button') }}
                    </button>
                  </li>
                </ol>
                <button
                  class="button-remove"
                  :disabled="disabled"
                  @click="removeQuestionItem(qindex)"
                  v-if="item"
                >
                  <icon-container
                    :width="15"
                    :height="15"
                    view-box="0 0 25 25"
                    name="trash"
                    color="#727d92"
                  >
                    <icon-trash />
                  </icon-container>
                </button>
                <div class="d-flex justify-content-between align-items-start">
                  <div class="p-0 form-group form-checkbox u-justify-start d-flex justify-content-start align-items-start">
                    <input
                      type="checkbox"
                      class="mt-1"
                      :disabled="disabled"
                      :name="`limitSingleChoice${qindex}`"
                      :checked="item.type === 'single-choice' ? true : false"
                      @change="makeSingleChoice($event, qindex)"
                    >
                    <label
                      class="form-label"
                      :for="`limitSingleChoice${qindex}`"
                    >
                      {{ $t('limit_user_to_select') }}
                    </label>
                  </div>
                  <div class="p-0 form-group form-checkbox u-justify-start d-flex justify-content-start align-items-start">
                    <input
                      type="checkbox"
                      class="mt-1"
                      :disabled="disabled"
                      :name="`hasOtherChoice${qindex}`"
                      :checked="(item && item.options && item.options.has_other_option) ? true : false"
                      @change="makeOtherChoice($event, qindex)"
                    >
                    <label
                      class="form-label"
                      :for="`hasOtherChoice${qindex}`"
                    >
                      {{ $t('has_other_choice') }}
                    </label>
                  </div>
                </div>
              </div>
              <div v-if="item.type === 'text'">
                <div class="form-group">
                  <button
                    class="button-remove"
                    :disabled="disabled"
                    @click="removeQuestionItem(qindex)"
                    v-if="item && surveyContentType === 'survey'"
                  >
                    <icon-container
                      :width="15"
                      :height="15"
                      view-box="0 0 25 25"
                      name="trash"
                      color="#727d92"
                    >
                      <icon-trash />
                    </icon-container>
                  </button>
                </div>
              </div>
              <div v-if="item.type === 'rating'">
                <div
                  class="row"
                >
                  <div class="col-6 form-group">
                    <label
                      for=""
                      class="form-label d-block"
                      :class="{'disabled': surveyContentType === 'nps'}"
                    >{{ $t('max_value') }}</label>
                    <input
                      :class="{ 'is-danger': errors.has(`survey.step${qindex}`) }"
                      type="number"
                      :disabled="disabled || surveyContentType === 'nps'"
                      class="form-control number-box"
                      v-model.number="item.options.step"
                      min="1"
                      :max="item.options.shape === 'number' ? 10:5"
                      @change="stepUpdate"
                      data-vv-scope="survey"
                      :name="'step' + qindex"
                      :data-vv-as="$t('max_value')"
                      v-validate.disable="{
                        required: true,
                        max: item.options.shape === 'number' ? 10:5,
                        min:1,
                      }"
                    >
                    <span
                      :key="'derror'+qindex"
                      v-if="errors.has(`survey.step${qindex}`)"
                      class="help is-danger"
                    >{{ errors.first(`survey.step${qindex}`) }}</span>
                  </div>
                  <div class="col-6 form-group">
                    <label
                      for=""
                      class="form-label"
                    >{{ $t('rate_shape') }}</label>
                    <div class="d-flex rating-block">
                      <div class="view-mode-type d-flex align-items-center">
                        <label
                          class="m-0"
                          :class="{'selected':item.options.shape === 'star', 'disabled': surveyContentType === 'nps' }"
                          @click="surveyContentType === 'survey' ? item.options.step = Number(5):null"
                        >
                          <input
                            type="radio"
                            v-model="item.options.shape"
                            :disabled="surveyContentType === 'nps'"
                            value="star"
                          >
                          <icon-container
                            view-box="0 0 75 44"
                            :width="75"
                            :height="44"
                            name="card"
                            color="none"
                          >
                            <icon-starchoice />
                          </icon-container>
                        </label>
                        <label
                          class="m-0"
                          :class="{'selected':item.options.shape === 'number', 'disabled': surveyContentType === 'nps'}"
                          @click="surveyContentType === 'survey' ? item.options.step = Number(10): null"
                        >
                          <input
                            type="radio"
                            v-model="item.options.shape"
                            :disabled="surveyContentType === 'nps'"
                            value="number"
                          >
                          <icon-container
                            view-box="0 0 75 44"
                            :width="75"
                            :height="44"
                            name="list"
                            color="none"
                          >
                            <icon-numberchoice />
                          </icon-container>
                        </label>
                        <label
                          class="m-0"
                          :class="{'selected':(item.options.shape === 'thumb' || item.options.shape === 'thumbsup'), 'disabled': surveyContentType === 'nps' }"
                          @click="surveyContentType === 'survey' ? item.options.step = Number(5): null"
                        >
                          <input
                            type="radio"
                            v-model="item.options.shape"
                            :disabled="surveyContentType === 'nps'"
                            value="thumbsup"
                          >
                          <icon-container
                            view-box="0 0 75 44"
                            :width="75"
                            :height="44"
                            name="list"
                            color="none"
                          >
                            <icon-ratechoice />
                          </icon-container>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="item.options.shape === 'number'"
                    class="col-6 form-group"
                  >
                    <label
                      for=""
                      class="form-label d-block"
                    >{{ $t('min_value_text') }}</label>
                    <input
                      :class="{ 'is-danger': errors.has(`survey.minval${qindex}`) }"
                      type="text"
                      :disabled="disabled"
                      class="form-control"
                      v-model="item.options.min_value"
                      data-vv-scope="survey"
                      :name="'minval' + qindex"
                      :data-vv-as="$t('min_value_text')"
                      v-validate.disable="{
                        max: 60,
                      }"
                    >
                  </div>
                  <div
                    v-if="item.options.shape === 'number'"
                    class="col-6 form-group"
                  >
                    <label
                      for=""
                      class="form-label d-block"
                    >{{ $t('max_value_text') }}</label>
                    <input
                      :class="{ 'is-danger': errors.has(`survey.step${qindex}`) }"
                      type="text"
                      :disabled="disabled"
                      class="form-control"
                      v-model="item.options.max_value"
                      data-vv-scope="survey"
                      :name="'maxval' + qindex"
                      :data-vv-as="$t('max_value_text')"
                      v-validate.disable="{
                        max: 60,
                      }"
                    >
                  </div>
                </div>
                <button
                  class="button-remove"
                  :disabled="disabled"
                  @click="removeQuestionItem(qindex)"
                  v-if="item && surveyContentType === 'survey'"
                >
                  <icon-container
                    :width="15"
                    :height="15"
                    view-box="0 0 25 25"
                    name="trash"
                    color="#727d92"
                  >
                    <icon-trash />
                  </icon-container>
                </button>
              </div>
            </div>
          </transition-group>
        </draggable-comp>
      </div>
      <div class="modal-footer">
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-12">
            <div
              class="dropdown d-flex justify-content-center align-items-center"
              v-if="surveyContentType === 'survey'"
              @mouseover="dropdownToggle('Survey')"
              @mouseleave="hideDropdown"
            >
              <span class="card--actions dropdown__toggle survey-button-add">
                <icon-container
                  name="cross"
                  :width="20"
                  :height="20"
                  color="#fff"
                >
                  <icon-cross />
                </icon-container>
              </span>
              <span class="survey-btn-text">{{ $t('add_button') }}</span>
              <div
                class="dropdown__menu p-3"
                ref="dropdownBoxSurvey"
                v-if="dropdownIndex === 'Survey'"
                v-on-clickaway="hideDropdown"
              >
                <span
                  class="dropdown__button button button--alternative button--icon u-full text-size-xs size-sm mb-2 white-text dropoption"
                  @click="addOpenEndedQuestion()"
                >
                  <icon-container
                    name="comment"
                    view-box="0 0 20 17"
                  >
                    <icon-comment />
                  </icon-container>
                  {{ $t('select_comment_question') }}
                </span>
                <span
                  class="dropdown__button button button--alternative button--icon u-full text-size-xs size-sm mb-2 white-text dropoption"
                  @click="addMultiChoiceQuestion()"
                >
                  <icon-container
                    name="choice"
                    view-box="0 0 24 23"
                  >
                    <icon-choice />
                  </icon-container>
                  {{ $t('select_choice_question') }}
                </span>
                <span
                  class="dropdown__button button button--alternative button--icon u-full text-size-xs size-sm mb-2 white-text dropoption"
                  @click="addImageQuestion()"
                >
                  <icon-container
                    name="images"
                    view-box="0 0 28 28"
                  >
                    <icon-images />
                  </icon-container>
                  {{ $t('select_image_question') }}
                </span>
                <span
                  class="dropdown__button button button--alternative button--icon u-full text-size-xs size-sm white-text dropoption"
                  @click="addRatingQuestion()"
                >
                  <icon-container
                    name="rate"
                    view-box="0 0 20 20"
                  >
                    <icon-rate />
                  </icon-container>
                  {{ $t('select_rate_question') }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <span
          v-if="errors.has('survey.question')"
          class="help is-danger"
        >{{ errors.first('survey.question') }}</span>
      </div>
      <div
        class="form-group"
        :class="{ 'is-danger': errors.has('survey.title') }"
      >
        <label
          for="contentTitle"
          class="form-label"
        >{{ $t('title').toUpperCase() }}</label>
        <input
          type="text"
          class="form-control"
          id="contentTitle"
          v-model="title"
          :disabled="disabled && !canEditOnlyItem"
          data-vv-scope="survey"
          data-vv-name="title"
          :data-vv-as="$t('title')"
          v-validate="'required|max:100'"
          @input="handleTitle"
        >
        <span
          v-show="errors.has('survey.title')"
          class="help is-danger"
        >{{ errors.first('survey.title') }}</span>
      </div>
      <div
        v-show="errors.items.length > 0"
        class="form-group"
      >
        <div class="alert alert--card alert--error">
          <p>{{ $t('error_msg_title') }}</p>
          <ul>
            <li
              v-for="(error, index) in errors"
              :key="index"
            >
              {{ error.msg }}
            </li>
          </ul>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="submit"
          class="button button--primary"
          @click="editMode ? isOrderChanged ? saveOrder() : updateContent() : createContent()"
          :disabled="isPending || (disabled && !canEditOnlyItem)"
        >
          <span
            v-if="!isPending"
          >
            {{ editMode ? isOrderChanged ? $t('update_order_button') : $t('update_button') : $t('create_button') }}
          </span>
          <div
            class="lds-ellipsis"
            v-if="isPending"
          >
            <div />
            <div />
            <div />
            <div />
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway';
import { Validator } from 'vee-validate';
import Draggable from 'vuedraggable';
import { i18n } from '@/helpers/i18n';
import IconTrash from '@/components/icons/Trash.vue';
import IconContainer from '@/components/elements/Icon.vue';
import IconCirclePlusFull from '@/components/icons/CirclePlusFull.vue';
import IconCross from '@/components/icons/Cross.vue';
import IconComment from '@/components/icons/Comment.vue';
import IconRate from '@/components/icons/Thumb.vue';
import IconChoice from '@/components/icons/Choice.vue';
import IconRateChoice from '@/components/icons/RateChoice.vue';
import IconStarChoice from '@/components/icons/StarChoice.vue';
import IconNumberChoice from '@/components/icons/NumberChoice.vue';
import IconHandle from '@/components/icons/Handle.vue';
import IconImages from '@/components/icons/Images.vue';
import ThumbnailContainer from '@/components/Thumbnail.vue';

Validator.extend('minSurveyAnswer', {
  getMessage() {
    return i18n.t('quiz_min_awswer');
  },
  validate(value, obj) {
    if (obj && obj.choices && obj.choices.length < 2 && (obj.type === 'multiple-choice' || obj.type === 'single-choice')) {
      return false;
    }
    return true;
  },
});

export default {
  name: 'SurveyContent',

  inject: ['$validator'],

  components: {
    'icon-container': IconContainer,
    'icon-trash': IconTrash,
    'icon-circleplusfull': IconCirclePlusFull,
    'icon-cross': IconCross,
    'icon-rate': IconRate,
    'icon-comment': IconComment,
    'icon-choice': IconChoice,
    'icon-ratechoice': IconRateChoice,
    'icon-numberchoice': IconNumberChoice,
    'icon-starchoice': IconStarChoice,
    'icon-handle': IconHandle,
    'icon-images': IconImages,
    'thumbnail-container': ThumbnailContainer,
    'draggable-comp': Draggable,
  },

  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    surveyContentType: {
      type: String,
      default: 'survey',
    },
    canEditOnlyItem: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      title: '',
      isPending: false,
      questions: [],
      dropdownIndex: null,
      isDropdown: null,
      idHolder: null,
      snotifyQueueList: [],
      queindex: null,
      questionsCloned: [],
      dirtyList: null,
      updatedList: [],
      initialOrder: [],
      lastestOrder: [],
      isOrderChanged: false,
    };
  },
  directives: {
    'on-clickaway': onClickaway,
    resizable: {
      inserted(el) {
        el.addEventListener('input', (e) => {
          e.target.style.height = 'auto';
          e.target.style.height = `${e.target.scrollHeight}px`;
        });
      },
    },
  },

  computed: {
    modalData: {
      get() {
        return this.$store.state.modal.modalData;
      },
      set(newValue) {
        this.$store.state.modal.modalData = newValue;
      },
    },
    formError: {
      get() {
        return this.$store.state.modal.formError;
      },
      set(newValue) {
        this.$store.state.modal.formError = newValue;
      },
    },
    dragOptions() {
      return {
        animation: 150,
        disabled: false,
        ghostClass: 'newghost',
        sort: true,
        forceFallback: !!(/Edge/.test(navigator.userAgent)),
      };
    },
  },

  watch: {
    modalData: {
      async handler(newVal) {
        const $newVal = newVal;
        if ($newVal) {
          if (this.editMode && $newVal.survey) {
            this.questions = $newVal.survey.questions;
            // eslint-disable-next-line no-restricted-syntax
            for await (const q of this.questions) {
              q.choices.map((obj) => { // eslint-disable-next-line no-param-reassign
                if (obj.image) {
                  // eslint-disable-next-line no-param-reassign
                  obj.imagePreview = obj.image;
                }
                return obj;
              });// eslint-disable-line no-return-assign
            }
            this.questionsCloned = await this.dash.cloneDeep(this.questions);
            // eslint-disable-next-line no-restricted-syntax
            for await (const q of this.questions) {
              // eslint-disable-line no-return-assign
              if (q.type === 'multiple-choice' || q.type === 'single-choice') {
                const imagechoice = q.choices.filter(c => (Object.prototype.hasOwnProperty.call(c, 'image') && c.image));
                if (imagechoice && imagechoice.length > 0) {
                  q.choices.push({
                    choice_text: '',
                    image: '',
                    imagePreview: '',
                  });
                } else {
                  q.choices.push({
                    choice_text: '',
                  });
                }
              }
            }
            this.prepareDirty();
          }
        }
      },
      immediate: true,
    },

    formError: {
      handler(newVal) {
        if (newVal) {
          this.isPending = false;
        }
      },
      immediate: true,
    },

    name: {
      handler(newVal) {
        this.title = newVal;
      },
      immediate: true,
    },
    questions: {
      handler(newvalue) {
        this.$emit('setQuestions', newvalue);
        if (this.editMode) {
          this.prepareDirty();
        }
      },
      deep: true,
      immediate: true,
    },
  },

  async created() {
    if (!this.editMode) {
      if (this.surveyContentType === 'survey') {
        this.setInitialSurveyContent();
      } else {
        this.setInitialNpsContent();
      }
    } else {
      const items = this.dash.cloneDeep(this.modalData.survey.questions);
      if (this.modalData.survey.is_nps) {
        this.$emit('setSurveyContentType', 'nps');
      } else {
        this.$emit('setSurveyContentType', 'survey');
      }
      this.initialOrder = [...items.map(i => i.id)];
      // console.log('initialOrder:', this.initialOrder);
    }
  },
  mounted() {
    if (this.editMode) {
      this.setHeights();
    }
  },
  methods: {
    stepUpdate() {
      this.$emit('setQuestions', this.questions);
    },

    setInitialNpsContent() {
      this.questions = [];
      this.questions.push(
        {
          question: '',
          type: 'rating',
          options: {
            is_required: 1,
            shape: 'number',
            step: 10,
            min_value: this.$t('not_likely'),
            max_value: this.$t('most_likely'),
          },
          choices: [],
          sub_text: '',
        },
        {
          question: '',
          type: 'text',
          options: { is_required: 1 },
          sub_text: '',
        },
      );
    },

    setInitialSurveyContent() {
      this.questions = [];
      this.questions.push({
        question: '',
        type: 'multiple-choice',
        options: { is_required: 1, has_other_option: 0 },
        choices: [
          {
            choice_text: '',
          },
          {
            choice_text: '',
          },
        ],
        sub_text: '',
      });
    },

    setHeights() {
      this.$nextTick(() => {
        [...document.querySelectorAll('textarea')].forEach((textarea) => {
          // eslint-disable-next-line no-param-reassign
          textarea.style.height = `${textarea.scrollHeight}px`;
        });
      });
    },

    dragStart() {
      console.log('dragstarted');
    },

    async dragEnd() {
      this.setHeights();
      const copy = await this.dash.cloneDeep(this.questions);
      this.lastestOrder = [...copy.map(i => i.id)];
      this.isOrderChanged = !this.dash.isEqual(this.lastestOrder, this.initialOrder);
    },

    async saveOrder() {
      this.isPending = true;
      const payload = { ordered_question_ids: '', content_id: null };
      payload.content_id = this.modalData.id;
      payload.ordered_question_ids = JSON.stringify(this.lastestOrder);
      await this.$store.dispatch('modal/UPDATE_ORDER_SURVEY', payload).then(async () => {
        this.isPending = false;
        this.isOrderChanged = false;
        this.$snotify.success(this.$t('order_changed'));
      });
    },

    async saveChanges() {
      this.checkValidation().then(async (res) => {
        if (res) {
          this.isPending = true;
          const payload = { surveyQuestions: [], content_id: null };
          payload.content_id = this.modalData.id;
          if (this.dirtyList) {
            this.dirtyList.forEach((element) => {
              payload.surveyQuestions.push(element);
            });
          }
          await this.$store.dispatch('modal/UPDATE_SURVEY_QUESTION', payload).then(async () => {
            if (!this.$store.state.modal.formError) {
              this.questionsCloned = await this.dash.cloneDeep(this.questions);
              await Promise.all(this.questions.map((q) => {
                if (q.type === 'multiple-choice' || q.type === 'single-choice') {
                  q.choices.push({
                    choice_text: '',
                  });
                }
                return q;
              }));
              this.isPending = false;
              this.dirtyList = [];
              setTimeout(() => {
                this.updatedList = payload.surveyQuestions;
              }, 1000);
              setTimeout(() => {
                this.updatedList = [];
              }, 3000);
            }
          });
        }
      });
    },

    isDirty(item) {
      if (this.dirtyList) {
        const found = this.dirtyList.find(element => element.id === item.id);
        if (found) {
          return true;
        }
      }
      return false;
    },

    isUpdated(item) {
      if (this.updatedList) {
        const found = this.updatedList.find(element => element.id === item.id);
        if (found) {
          return true;
        }
      }
      return false;
    },

    async prutify(items) {
      await Promise.all(items.map(async (question) => {
        if ((question.type === 'multiple-choice' || question.type === 'single-choice') && question.choices) {
          // eslint-disable-next-line no-restricted-syntax
          for await (const [, answer] of Object.entries(question.choices)) { // check empty answer box and delete before validation
            const aindex = question.choices.indexOf(answer);
            // console.log('answer.choice_text:', JSON.stringify(answer), answer.choice_text !== undefined, !answer.choice_text, ((answer.image === '' || answer.image === null || answer === undefined) || !Object.prototype.hasOwnProperty.call(answer, 'image')));
            if (!answer.choice_text && ((answer.image === '' || answer.image === null || answer === undefined) || !Object.prototype.hasOwnProperty.call(answer, 'image'))) {
              // console.log('silindi: ', answer.choice_text);
              question.choices.splice(aindex, 1);
            }
          }
        }
        return question;
      }));
      await Promise.all(items.map(async (question, qindex) => {
        if ((question.type === 'multiple-choice' || question.type === 'single-choice') && question.choices && question.choices.length === 0 && !question.question && !question.sub_text) {
          items.splice(qindex, 1);
        }
        return question;
      }));
      return items;
    },

    async prepareDirty() {
      const items = await this.dash.cloneDeep(this.questions);
      const copy = await this.prutify(items);
      // console.log('prepareDirty: currentCopy:', JSON.stringify(copy), 'orginalOne:', JSON.stringify(this.questionsCloned));
      this.dirtyList = await this.dash.differenceWith(copy, this.questionsCloned, this.dash.isEqual);
    },

    handleTitle() {
      this.$emit('setTitle', this.title);
    },

    hideDropdown() {
      this.isDropdown = null;
      this.timer = setTimeout(() => {
        this.dropdownIndex = null;
      }, 750);
    },

    dropdownAction(value) {
      this.isDropdown = value;
    },

    dropdownToggle(type) {
      this.dropdownIndex = type;
      setTimeout(() => {
        const elem = this.$refs.dropdownBoxSurvey;
        if (elem) {
          const isOut = this.$helpers.isOutOfScreen(elem);
          if (isOut.right) {
            elem.classList.add('right');
          } else if (isOut.left) {
            elem.classList.add('left');
          } else if (isOut.bottom) {
            elem.classList.add('bottomjourney');
          } else if (isOut.top) {
            elem.classList.add('top');
          }
        }
      }, 1);
    },

    triggerPreviewNextQuestion(qindex) {
      this.$emit('triggerPreviewNextQuestion', qindex - 1);
    },

    checkItemValidate(qindex) {
      if (this.errors.items.length > 0) {
        this.$validator.validate(`survey.question${qindex}`);
      }
    },

    async checkValidation() {
      let allIsValid = false;
      let surveyIsValid = false;
      let customCheck = true;

      this.questions = await this.prutify(this.questions);

      this.clearErrorMsg();

      if (this.questions.length < 1) { // check at least one question exist
        this.$validator.errors.add({
          field: 'question',
          msg: this.$t('quiz_one_question'),
          scope: 'survey',
        });
        customCheck = false;
      }

      await this.$validator.validateAll('all').then((res) => {
        allIsValid = res;
      });
      await this.$validator.validateAll('survey').then((res) => {
        surveyIsValid = res;
      });
      if (allIsValid && surveyIsValid && customCheck) {
        return true;
      }
      return false;
    },

    clearErrorMsg() {
      this.$validator.errors.clear();
    },

    addAnswerItemForEmpty(qindex = 0) {
      const q = this.questions[qindex];
      if (q) {
        q.choices.push({
          choice_text: '',
        });
      }
      this.checkItemValidate(qindex);
      this.prepareDirty();
    },

    checkNeedForAddAnswer(choices) {
      if (!choices || choices === undefined) return false;
      const needed = choices.filter(c => ((Object.prototype.hasOwnProperty.call(c, 'image') && c.image === '') || (!Object.prototype.hasOwnProperty.call(c, 'image') && c.choice_text === '')));
      // console.log('checkNeedForAddAnswer:', needed, (needed.length === 0));
      return (needed.length === 0);
    },

    addAnswerSmart(choices, qindex) {
      if (choices) {
        const hasImageField = choices.filter(c => Object.prototype.hasOwnProperty.call(c, 'image') && c.image);
        if (hasImageField && hasImageField.length > 0) {
          this.questions[qindex].choices.push({
            choice_text: '',
            image: '',
            imagePreview: '',
          });
        } else {
          this.questions[qindex].choices.push({
            choice_text: '',
          });
        }
      }
    },

    makeSingleChoice(event, qindex) {
      if (event.target.checked) {
        this.questions[qindex].type = 'single-choice';
      } else {
        this.questions[qindex].type = 'multiple-choice';
      }
    },

    makeOtherChoice(event, qindex) {
      if (event.target.checked) {
        this.questions[qindex].options = { has_other_option: 1 };
      } else {
        this.questions[qindex].options = { has_other_option: 0 };
      }
    },

    addMultiChoiceQuestion() {
      this.questions.push({
        question: '',
        type: 'multiple-choice',
        options: { is_required: 1, has_other_option: 0 },
        choices: [
          {
            choice_text: '',
          },
        ],
        sub_text: '',
      });
    },

    addImageQuestion() {
      this.questions.push({
        question: '',
        type: 'multiple-choice',
        options: { is_required: 1, has_other_option: 0 },
        choices: [
          {
            choice_text: '',
            image: '',
            imagePreview: '',
          },
        ],
        sub_text: '',
      });
    },

    addOpenEndedQuestion() {
      this.questions.push({
        question: '',
        type: 'text',
        options: { is_required: 1 },
        sub_text: '',
      });
    },

    addRatingQuestion() {
      this.questions.push({
        question: '',
        type: 'rating',
        options: {
          is_required: 1,
          shape: 'star',
          step: 5,
          min_value: this.$t('not_likely'),
          max_value: this.$t('most_likely'),
        },
        choices: [],
        sub_text: '',
      });
    },

    addAnswerItem(qindex = 0, aindex = 0, choiceType = '') {
      const q = this.questions[qindex];
      if (q && (q.type === 'multiple-choice' || q.type === 'single-choice')) {
        if (aindex === q.choices.length - 1) {
          if (choiceType === 'image') {
            q.choices.push({
              choice_text: '',
              image: '',
              imagePreview: '',
            });
          } else {
            q.choices.push({
              choice_text: '',
            });
          }
        }
      }
      this.checkItemValidate(qindex);
    },

    async removeAnswerItem(aindex, qindex) {
      const removeAnswerId = this.questions[qindex].choices[aindex].id;

      if (removeAnswerId) {
        this.$store.dispatch('modal/DELETE_SURVEY_ANSWER', removeAnswerId).then(() => {
          if (!this.formError) {
            this.questions[qindex].choices.splice(aindex, 1);
          } else {
            this.formError = false;
          }
        });
      } else {
        this.questions[qindex].choices.splice(aindex, 1);
      }
    },

    removeQuestionItem(qindex) {
      const removeQestionId = this.questions[qindex].id;
      this.queindex = qindex;
      if (removeQestionId) {
        if (!this.snotifyQueueList.includes(removeQestionId)) {
          this.idHolder = removeQestionId;
          this.snotifyQueueList.push(removeQestionId);
          this.$snotify.confirm(this.$t('question_delete_msg_body'), this.$t('question_delete_msg_title'), {
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            buttons: [
              {
                text: this.$t('yes'),
                action: (toast) => {
                  this.deleteConfirm(toast, this.idHolder, qindex);
                },
                bold: false,
              },
              {
                text: this.$t('no'),
                action: (toast) => {
                  this.deleteCancel(toast, this.idHolder);
                },
              },
            ],
          }).on('shown', (toast) => {
            this.toastHolder = toast;
            document.addEventListener('keydown', this.eventHandler, false);
          }).on('hidden', (toast) => {
            this.toastHolder = toast;
            document.removeEventListener('keydown', this.eventHandler, false);
          });
        }
      } else {
        this.questions.splice(qindex, 1);
      }
    },

    deleteConfirm(toast, itemId, qindex) {
      this.$snotify.remove(toast.id);
      const snotifyIndex = this.snotifyQueueList.indexOf(itemId);
      if (snotifyIndex > -1) {
        this.snotifyQueueList.splice(snotifyIndex, 1);
      }
      this.$store.dispatch('modal/DELETE_SURVEY_QUESTION', itemId).then(() => {
        if (!this.formError) {
          this.questions.splice(qindex, 1);
        } else {
          this.formError = false;
        }
      });
    },

    deleteCancel(toast, itemId) {
      const snotifyIndex = this.snotifyQueueList.indexOf(itemId);
      if (snotifyIndex > -1) {
        this.snotifyQueueList.splice(snotifyIndex, 1);
      }
      this.$snotify.remove(toast.id);
    },

    imageAdd(data) {
      let preview = null;
      let file = null;
      let index = null;
      [preview, file, index] = data;
      if (index != null || index !== undefined) {
        if (file) {
          this.questions[index.qindex].choices[index.aindex].imagePreview = preview;
          this.questions[index.qindex].choices[index.aindex].image = file;
          this.addAnswerItem(index.qindex, index.aindex, 'image');
        } else {
          this.questions[index.qindex].choices[index.aindex].imagePreview = '';
          this.questions[index.qindex].choices[index.aindex].image = '';
          if (this.questions[index.qindex].choices.length > 1) {
            this.removeAnswerItem(index.aindex, index.qindex);
          }
        }
      }
    },

    async switchSurveyType(type) {
      await this.prepareDirty();
      if (this.dirtyList && this.dirtyList.length) {
        // console.log('dirtyList:', JSON.stringify(this.dirtyList, null, 2));
        this.$snotify.confirm(this.$t('survey_type_change_msg_body'), this.$t('survey_type_change_msg_title'), {
          showProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          buttons: [
            {
              text: this.$t('yes'),
              action: (toast) => {
                this.$emit('setSurveyContentType', type);
                this.dirtyList = [];
                if (type === 'nps') {
                  this.setInitialNpsContent();
                } else {
                  this.setInitialSurveyContent();
                }
                this.$snotify.remove(toast.id);
              },
              bold: false,
            },
            {
              text: this.$t('no'),
              action: (toast) => {
                this.$snotify.remove(toast.id);
              },
            },
          ],
        });
      } else {
        this.$emit('setSurveyContentType', type);
        this.dirtyList = [];
        if (type === 'nps') {
          this.setInitialNpsContent();
        } else {
          this.setInitialSurveyContent();
        }
      }
    },

    imageAddMultiple(data) {
      let preview = null;
      let file = null;
      let index = null;
      [preview, file, index] = data;
      if (preview && file) {
        this.addAnswerItem(index.qindex, index.aindex, 'image');
        this.questions[index.qindex].choices[index.aindex].imagePreview = preview;
        this.questions[index.qindex].choices[index.aindex].image = file;
      }
    },

    eventHandler(event) {
      event.preventDefault();
      const key = event.key || event.keyCode;
      if (key === 'Enter' || key === 13) {
        this.deleteConfirm(this.toastHolder, this.idHolder, this.queindex);
      } else if (key === 'Escape' || key === 'Esc' || key === 27) {
        this.deleteCancel(this.toastHolder, this.idHolder);
      }
    },

    createContent() {
      this.checkValidation().then((res) => {
        if (res) {
          this.$emit('createContent');
          this.isPending = true;
        }
      });
    },

    updateContent() {
      this.checkValidation().then((res) => {
        if (res) {
          this.$root.$emit('updateDirtyList', this.dirtyList);
          this.$emit('updateContent');
          this.isPending = true;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-footer .button {
    width: 100%;
}
.modal-footer .row {
    margin-bottom: 30px;
}
.survey-text-item {
  width: 100%;
  padding: 25px 50px;
  border-radius: 14px;
  box-shadow: 0 1px 6px 0 rgba(114, 125, 146, 0.2);
  border: solid 2px rgba(114, 125, 146, 0.2);
  background-color: rgba(255, 255, 255, 1);
  margin-bottom: 30px;
  position: relative;

  .button-remove {
    opacity: 0;
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
  .button-handle {
    position: absolute;
    top: 10px;
    left: 15px;
    opacity: 0;
    cursor: move;

  }

  &:hover {
    .button-remove {
      opacity: 1;
    }
    .button-handle {
      opacity: 1;
      &.disabled {
        cursor: default;
        opacity: 0;
    }
    }
  }

  ol {
    list-style: upper-alpha inside;
  }

  .form-label {
    @include primary-medium;
    font-size: 12px;
    color: #727d92;
  }
label.disabled {
    opacity: 0.5;
    &:hover {
      cursor: not-allowed!important;
    }
    svg:hover {
      cursor: not-allowed!important;
    }
  }
input:disabled {
  opacity: 0.5;
}
  .checkbox {
    @include primary-medium;
    padding: 5px;
    font-size: 12px;
    letter-spacing: 0.38px;
    color: #727d92;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    input {
      margin-right: 5px;
    }
  }
  &.sortable-chosen.newghost {
    background-color: #2e74f741;
  }
}
.is-danger {
  border-color: #f27299;
}
.survey-button-add {
  background-color: #F27299;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 50%;
  height:48px;
  width: 48px;
  display: flex;
  justify-content:center;
  align-items: center;
  margin: 10px;
}
.survey-btn-text {
  color: #F27299;
  font-weight: bolder;
  margin-left:20px;
}
.white-text {
  color: #fff!important;
}
::v-deep .rating-block {
  height: 44px;
  width: 225px;
  .view-mode-type {
    label.selected svg g {
      path {
        fill: #F27299;
        stroke: #F27299;
      }
      rect {
        stroke: #F27299;
      }
    }
  }
}
.number-box {
  width: 60px;
}
::v-deep .dropoption {
  width: 230px!important;
}
ol {
  margin-bottom: 0;
}
::v-deep textarea.form-control {
  min-height: 44px;
  height: 100%;
  overflow: hidden;
  resize:vertical;
}
::v-deep .ilist-move{
  transition: .5s;
}
.check-button {
  height:30px;
  padding:10px;
  border: 1px solid #b4bac6;
  border-radius:5px;
  display:flex;
  justify-content: center;
  align-items: center;
  font-size: 12px !important;
  line-height: 16px !important;
  font-weight: 500;
  color: #727d92;
  min-width: 100px;
  input {
    display: none;
  }
  &.active {
    border: 1px solid #f27299;
  }
  &:hover {
    cursor: pointer;
  }
}
</style>
